/**
 * Edit Report principle list.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import guidelinePropType from 'js/PropTypes/guideline';
import Principle from 'js/Components/EditReport/Principle';
import principlePropType from 'js/PropTypes/principle';
import reportPagePropType from 'js/PropTypes/reportPage';

/**
 * Edit report principle.
 */
class PrincipleList extends Component {
    static propTypes = {
        guidelines: PropTypes.arrayOf(guidelinePropType),
        page: reportPagePropType,
        principles: PropTypes.arrayOf(principlePropType),
    };

    static defaultProps = {
        guidelines: [],
        page: {},
        principles: [],
    };

    /**
     * Render the individual principles.
     *
     * @returns {React.node}
     */
    renderPrinciples() {
        const { guidelines, page, principles,id } = this.props;

        return principles.map((principle) => {
            const applicableGuidelines = guidelines.filter((guideline) => {
                return guideline.principleId === principle.id;
            });

            return (
                <Principle
                    guidelines={applicableGuidelines}
                    key={principle.name}
                    page={page}
                    principle={principle}
                    id={id}
                />
            );
        });
    }

    /**
     * Render the principle.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <div
                className="edit-report-principle-list"
            >
                {this.renderPrinciples()}
            </div>
        );
    }
}

export default PrincipleList;
