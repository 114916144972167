import {useState,useContext,useCallback, useEffect} from "react";
import Button from 'js/Components/Forms/Raw/Button';
import Modal from 'js/Components/Modal/Raw/Modal';
import ModalBody from 'js/Components/Modal/Raw/ModalBody';
import ModalFooter from 'js/Components/Modal/Raw/ModalFooter';
import ModalHeader from 'js/Components/Modal/Raw/ModalHeader';
import UserContext from "js/Context/UserContext";
import GuidelinesTable from "js/Components/Table/GuidelinesTable"
import { GlobalStoreContext } from "js/Context/UseGlobalStoreContext";

const PickupGuidelinesModal = ({onClose,open,id,setLoading,disableLoading})=>{
    const {reportItem, pickupGuidelines} = useContext(GlobalStoreContext);
    const {personSession} = useContext(UserContext);
    const [selected, setSelected] = useState({});

    const handleSelection = useCallback((option)=>{
        const copySelected = {};
        option.forEach((item)=>{
            if(copySelected.hasOwnProperty(item)){
                delete copySelected[item]
            } else {
                copySelected[item] = item;
            }
            
        });
        setSelected( copySelected);
    },[])

    const handleUpdate = useCallback(()=>{
        setLoading()
        pickupGuidelines(reportItem.id, Object.keys(selected), personSession.session.id,(result)=>{
            if(result){
                disableLoading()
                onClose()
            }
        })
    },[pickupGuidelines, selected, reportItem, personSession,onClose])

    useEffect(()=>{
        const appliedGuideline = reportItem.guidelines;
        const selectedList = {};
        appliedGuideline.forEach((item)=>{
            selectedList[item['guideline_id']] = item['guideline_id']
        });

        setSelected(selectedList);
    },[reportItem]);


    return(            
        <Modal
            className="request-urls-audit-modal"
            onClose={onClose}
            open={open}
        >
            <ModalHeader>
                Apply Guidelines
            </ModalHeader>
            <ModalBody>
                <GuidelinesTable
                    selected={selected}
                    handleSelection={handleSelection}
                />
            </ModalBody>
            <ModalFooter>
                
                <Button
                    onClick={onClose}
                    title="Cancel"
                >
                    Cancel
                </Button>

                <Button
                    primary
                    title="Update Guidelines"
                    onClick={handleUpdate}
                >
                    Update
                </Button>
                 
            </ModalFooter>
        </Modal>)
}
export default PickupGuidelinesModal;