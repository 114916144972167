/**
 * Define the structure of a report violation detail in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

const violationDetailPropType = PropTypes.shape({
    context: PropTypes.string,
    message: PropTypes.string,
    selector: PropTypes.string,
});

export default violationDetailPropType;
