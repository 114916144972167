/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import encodeDataString from 'js/utils/encodeDataString';

class DataStringAnchor extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        download: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    };

    render() {
        const {
            children,
            data,
            download,
            type,
        } = this.props;

        return <a download={download} href={encodeDataString(data, type)}>{children}</a>;
    }
}

export default DataStringAnchor;
