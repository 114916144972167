/**
 * Front end 404 page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import PageWrapper from 'js/Components/Pages/PageWrapper';

/**
 * Front end 404 page.
 */
class NotFound extends Component {
    /**
     * Render 404 page.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <PageWrapper
                description="Page not found"
                title="Not Found"
            >
                <p>Cannot find the page you are requesting.</p>
            </PageWrapper>
        );
    }
}

export default NotFound;
