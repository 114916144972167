import React,{useState,useCallback,useEffect} from "react";
import ImportReportModal from "../Modal/ImportReportModal";
import Button from '../Forms/Raw/Button';
import apiConfig from "../../config/api"; 
import {httpError401} from "js/Components/Api/useHttp";

const ImportReportButton = ({reportId,personSession,report,reloadReport,setLoading,disableLoading})=>{

    const [open,setOpen] = useState(false);
    const [link, setLink] = useState(null);
    const [finalize,setFinalize] = useState(false);
    const [isWorking, setIsWorking] = useState(false);

    const onClose = useCallback(()=>{setOpen(false);reloadReport()},[])
    const onCloseNoLoading = useCallback(()=>{setOpen(false);},[])
    const onClick = useCallback(()=>{setOpen(!open)},[open])

    const onFinalize =useCallback(()=>{
        (async()=>{
            if(isWorking){
                return
            }
            setIsWorking(true)
            setLoading()
            try{
                const result = await fetch(
                    `${apiConfig}report/${reportId}/finalize?sessionId=${personSession.session.id}`,
                    {
                        body: JSON.stringify({
                            isFinalized:!finalize
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        method: 'post',
                    }
                )
                const response = await result.json();

                if(result.status === 401){
                    httpError401();
                }

                if(response.result === "success" ){
                    setFinalize(!finalize);
                }else{
                    throw Error("Internal Error!");
                }
            }catch(e){
                //To Do Handle Erro
            }finally{
                setIsWorking(false)
                disableLoading();
            }

        })()

    },[finalize,isWorking]) 

    useEffect(()=>{
        if(report.summary){
            const lk = `/preview/${reportId}?sessionId=${personSession.session.id}&preview=true`
            setLink(lk);
        }
        setFinalize(report.isFinalized === 3)
    },[report])

    return(
    <div style={{display:"flex", alignItems:"center"}}>
        <Button
            onClick={onClick}
        >

            Import
        </Button>
        {
            report.summary &&
            <div style={{display:"flex", alignItems:"center",margin:"0 0 0 15px"}}>
                <Button
                    onClick={()=>{
                        window.open(`${link}`,"_blank").focus()
                    }}
                >
                    Preview
                </Button>
                <div>
                    <input style={{minWidth:"auto",margin:"0 15px 0"}} type="checkbox" id="report_finalize" name="report_finalize" checked={!!finalize} onChange={onFinalize} />
                    <label for="report_finalize" style={{fontSize:"14px"}}>Finialized (Finalized report will be displayed for client)</label>
                </div>
            </div>
            }
          

    { 
        open&& 
        <ImportReportModal
            onClose={onClose}
            onCloseNoLoading={onCloseNoLoading}
            open={open}
            reportId={reportId}
            personSession={personSession}
            setLoading={setLoading}
            disableLoading={disableLoading}
        />
    }

    </div>);
}

export default ImportReportButton;