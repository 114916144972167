/**
 * Modal.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'js/Components/Forms/Raw/Button';
import childrenPropType from 'js/PropTypes/children';
import noop from 'js/noop';

/**
 * Modal Class
 */
class Modal extends Component {
    static propTypes = {
        children: childrenPropType,
        className: PropTypes.string,
        onClose: PropTypes.func,
        open: PropTypes.bool,
    };

    static defaultProps = {
        children: null,
        className: null,
        onClose: noop,
        open: false,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        // Efficient early binding.
        this.onClose = this.onClose.bind(this);
    }

    /**
     * Close the modal.
     *
     * @param {Event} e React synthetic event.
     *
     * @returns {void}
     */
    onClose(e) {
        const { onClose } = this.props;

        if (onClose) {
            onClose(e);
        }
    }

    /**
     * Render the modal with backdrop.
     *
     * @returns {React.node}
     */
    render() {
        const { children, className, open } = this.props;
        const backdropClasses = classNames({
            'modal-backdrop': true,
            open,
        });
        const modalClasses = classNames({
            'modal': true,
            [className]: className,
        });

        return (
            <div
                className={backdropClasses}
                role="presentation"
            >
                <div className={modalClasses} role="dialog">
                    <div className="modal-close">
                        <Button onClick={this.onClose} title="Close">
                            &times;
                        </Button>
                    </div>
                    {children}
                </div>
            </div>
        );
    }
}

export default Modal;
