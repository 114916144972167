/**
* Organization select component.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import organizationPropType from 'js/PropTypes/organization';
import Select from 'js/Components/Forms/Raw/Select';

/**
 * Organization select component class.
 */
class OrganizationSelect extends Component {
    static propTypes = {
        organizations: PropTypes.arrayOf(organizationPropType),
    };

    static defaultProps = {
        organizations: [],
    };

    /**
     * Map the organizations to the option format.
     *
     * @returns {object}
     */
    getOptions() {
        const { organizations } = this.props;
        const optionList = {};

        organizations.forEach((organization) => {
            optionList[organization.id] = organization.name;
        });

        return optionList;
    }

    /**
     * Render the Organization select.
     *
     * @returns {React.node}
     */
    render() {
        const { ...passThroughProps } = this.props;

        return (
            <Select
                name="organizationId"
                options={this.getOptions()}
                { ...passThroughProps }
            />
        );
    }
}

export default OrganizationSelect;
