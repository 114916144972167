/**
* Role select component.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import rolePropType from 'js/PropTypes/role';
import Select from 'js/Components/Forms/Raw/Select';

/**
 * Role select component class.
 */
class CountrySelect extends Component {
    static propTypes = {
        roles: PropTypes.arrayOf(rolePropType),
    };

    static defaultProps = {
        roles: [],
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
        constructor(props) {
        super(props);

        this.state = {};

        // Efficient early binding.
        this.getRoleOptions = this.getRoleOptions.bind(this);
    }

    /**
     * Convert the list of roles to the expected option object.
     *
     * @returns {object}
     */
    getRoleOptions() {
        const { roles } = this.props;
        const roleOptions = {};

        roles.forEach((role) => {
            roleOptions[role.id] = role.name;
        });

        return roleOptions;
    }

    /**
     * Render the role select.
     *
     * @returns {React.node}
     */
    render() {
        const { roles, ...passThroughProps } = this.props;

        return (
            <Select
                htmlFor="roles"
                label="Roles"
                name="roles"
                options={this.getRoleOptions()}
                {...passThroughProps}
            />
        );
    }
}

export default CountrySelect;
