/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { PureComponent } from 'react';
import SeverityCalculator from '@scalesology/ada-tool/src/severity-calculator';

import DataStringAnchor from 'js/Components/Utils/DataStringAnchor';
import HoverableAnchor from 'js/Components/Utils/HoverableAnchor';
import MarkdownText from 'js/Components/Utils/MarkdownText';
import SectionWithHeading from 'js/Components/Utils/SectionWithHeading';
import SeverityLabel from 'js/Components/Utils/SeverityLabel';
import metricsShape from 'js/PropTypes/metricsShape';
import reportShape from 'js/PropTypes/reportShape';

class DetailedPages extends PureComponent {
    static propTypes = {
        metrics: metricsShape.isRequired,
        report: reportShape.isRequired,
    };

    constructor(props) {
        super(props);

        this.severityCalculator = new SeverityCalculator();
    }

    renderViolations(violations, downloadName, details) {
        //console.log(violations, downloadName, details);
        if (details.length === 0) {
            return violations;
        }

        return (
            <DataStringAnchor
                data={details.map((detail) => ({
                    Selector: detail.selector,
                    Context: detail.context,
                    'Violation Error Message': detail.message,
                }))}
                download={downloadName}
                type="text/csv"
            >
                {violations}
            </DataStringAnchor>
        );
    }

    renderGuidelineRows(pageSlug, guideline, complianceLevel, tests) {
        const {
            slug,
            name: guidelineName,
        } = guideline;

        const totalViolations = tests.reduce((total, { details,violations }) => (total + Math.max(details.length,violations)), 0);
        const { severity } = this.severityCalculator.calculate(totalViolations, complianceLevel);

        return tests.map(({
            name,
            description,
            details,
            violations
        }, index) => {
            const isFirstRow = index === 0;
            const key = `${slug}-${name}`;
            const id = isFirstRow ? `${pageSlug}-${slug}` : null;
            const detailDownloadName = `${pageSlug}-${slug}-${index}.csv`;

            return (
                <tr key={key} className={`severity severity-${severity}`}>
                    {isFirstRow ? (
                        <th id={id} rowSpan={tests.length} scope="row">
                            <span className="guideline-label">
                                {guidelineName}
                            </span>
                            <HoverableAnchor id={id} />
                        </th>
                    ) : null}
                    <td><MarkdownText text={name} /></td>
                    <td><MarkdownText text={description} /></td>
                    <td>{this.renderViolations(Math.max(details.length,violations), detailDownloadName, details)}</td>
                    {isFirstRow ? (
                        <td rowSpan={tests.length}>
                            <SeverityLabel severity={severity} />
                        </td>
                    ) : null}
                </tr>
            );
        });
    }

    renderGuidelines(pageSlug, guidelines, issues) {
        // Concatenate guideline rows
        let tableRows = [];
        guidelines.forEach((guideline) => {
            const { id, complianceLevel } = guideline;
            const applicableIssues = issues.filter((issue) => {
                return issue.guidelineId === id;
            });

            // No issues for this guideline
            if (applicableIssues.length === 0) {
                return;
            }

            tableRows = [
                ...tableRows,
                ...this.renderGuidelineRows(pageSlug, guideline, complianceLevel, applicableIssues),
            ];
        });

        // No issues for this principle
        if (tableRows.length === 0) {
            return (
                <tr className="severity severity-0">
                    <td colSpan="5">
                        Congratulations, no violations occurred in this category.
                    </td>
                </tr>
            );
        }

        return tableRows;
    }

    renderDetailedPage(page) {
        const { metrics: { principles } } = this.props;
        const { slug: pageSlug, name: pageName, violations: issues } = page;
        const id = `${pageSlug}-detailed`;

        const detailedPrinciples = principles.map(({ slug, name, guidelines }) => (
            <SectionWithHeading
                key={slug}
                className="detailed-page-category"
                heading={`${name} Tests`}
                id={`${pageSlug}-${slug}`}
                level={4}
            >
                <div className="table-responsive">
                    <table className="table table-detailed-page">
                        <thead>
                            <tr>
                                <th className="th-guideline" scope="col">Guideline</th>
                                <th className="th-test" scope="col">Test</th>
                                <th className="th-examples" scope="col">Examples</th>
                                <th className="th-violations" scope="col">Violations</th>
                                <th className="th-severity" scope="col">Severity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderGuidelines(pageSlug, guidelines, issues)}
                        </tbody>
                    </table>
                </div>
            </SectionWithHeading>
        ));

        return (
            <SectionWithHeading
                key={pageSlug}
                className="detailed-page"
                heading={pageName}
                id={id}
                level={3}
            >
                {detailedPrinciples}
            </SectionWithHeading>
        );
    }

    render() {
        const { report: { pages } } = this.props;

        return (
            <section className="detailed">
                {pages.map((page) => this.renderDetailedPage(page))}
            </section>
        );
    }
}

export default DetailedPages;
