/**
 * Front end Edit Report page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Heading from 'js/Components/EditReport/Heading';
import matchPropType from 'js/PropTypes/match';
import Overview from 'js/Components/EditReport/Overview';
import PageList from 'js/Components/EditReport/Pages/PageList';
import PageWrapper from 'js/Components/Pages/PageWrapper';
import PagePermissionAlert from 'js/Components/Alert/PagePermissionAlert';
import UserContext from 'js/Context/UserContext';
import ImportReport from "../EditReport/ImportReport";
import PickupGuidelines from "../EditReport/PickupGuidelines";
import LoadingIndicatorTransparent from '../LoadingIndicatorTransparent';
import List from '../Api/List';

/**
 * Front end Edit Report page.
 */
class EditReport extends Component {
    static propTypes = {
        match: matchPropType,
    };

    static defaultProps = {
        match: {
            params: {},
        },
    };

    constructor(props){
        super(props);
        this.state = {
            loading:false
        }
        this.setLoading = this.setLoading.bind(this);
        this.disableLoading = this.disableLoading.bind(this);
    }

    setLoading(){
        this.setState({loading:true});
    }

    disableLoading(){
        this.setState({loading:false});
    }


    /**
     * Render Edit Report page.
     *
     * @returns {React.node}
     */
    render() {
        const { match: { params: { id } } } = this.props;

        return (
            <PageWrapper
                description="Edit ADA Report"
                title="Edit ADA Report"
            >
                <UserContext.Consumer>
                    { ({ hasPermission, personSession }) => {
                        if (!hasPermission('audit.reports')) {
                            return (
                                <PagePermissionAlert />
                            );;
                        }

                        return (
                            <List
                                page="editreport"
                                id={id}
                                sessionId={personSession.session.id}
                            >
                                <Heading />
                                <ImportReport
                                    reportId={id}
                                    personSession={personSession}
                                    setLoading={this.setLoading}
                                    disableLoading={this.disableLoading}
                                />
                                <PickupGuidelines
                                    reportId={id}
                                    personSession={personSession}
                                    setLoading={this.setLoading}
                                    disableLoading={this.disableLoading}
                                />
                                <Overview 
                                    id={id}
                                    sessionId={personSession.session.id}
                                    setLoading={this.setLoading}
                                    disableLoading={this.disableLoading}
                                />
                                <PageList
                                    id={id}
                                    setLoading={this.setLoading}
                                    disableLoading={this.disableLoading}
                                    sessionId={personSession.session.id}
                                />
                                {this.state.loading && <LoadingIndicatorTransparent/>}
                            </List>
                        );
                    }}
                </UserContext.Consumer>

            </PageWrapper>
        );
    }
}

export default EditReport;
