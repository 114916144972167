import { useCallback,useState } from "react"
import useHttp from "./useHttp"
import apiConfig from 'js/config/api';

let working = false;
const useGuidelineList = ()=>{
    const {isLoading, error, sendRequest} = useHttp()
    const [guidelines, setGuidelines] = useState([]);


    const loadGuidelines = useCallback((sessionId, forceload=false)=>{
        if(working === false || forceload)
        {
            working = true;
            sendRequest({
                url: `${apiConfig}guideline/list?sessionId=${sessionId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
            },
            (data)=>{
                setGuidelines(data)
                working = false
            },
            ()=>{
                working = false
            }

        )
        }

    },[isLoading])


    return {
        isLoadingGuidelines: isLoading,
        isErrorGuidelines: error,
        guidelines:guidelines,
        loadGuidelines: loadGuidelines
    }
}

export default useGuidelineList;