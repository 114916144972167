/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import HorizontalLogo from 'img/scalesology-main-horizontal.svg';
import reportLevelListPropType from 'js/PropTypes/reportLevelList';

class Header extends PureComponent {
    static propTypes = {
        reportLevel: PropTypes.number.isRequired,
        levels: reportLevelListPropType,
    };

    static defaultProps = {
        levels: [],
    };

    render() {
        const { levels, reportLevel: diagnosticLevel } = this.props;
        
        let reportLevelName
        if (diagnosticLevel === 1){
            reportLevelName = "WCAG 2.0 Level AA"
        } else if ( diagnosticLevel === 2) {
            reportLevelName = "WCAG 2.1 Level AA"
        } else if ( diagnosticLevel === 3)  {
            reportLevelName = "WCAG 2.1 Level AAA"
        } else if ( diagnosticLevel === 4)  {
            reportLevelName = "WCAG 2.2 Level AA"
        } else if ( diagnosticLevel === 5)  {
            reportLevelName = "WCAG 2.2 Level AAA"
        } else {
            reportLevelName = "Unknown"
        }

        return (
            <div className="header">
                <img alt="" src={HorizontalLogo} />
                <h1>
                    ADA Website Compliance Audit Report
                    <sup>&copy;</sup>
                </h1>
                <p className="subtitle">
                   Criteria Level:  {reportLevelName}
                </p>
            </div>
        );
    }
}

export default Header;
