/**
 * Text Form Group Clear.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'js/Components/Forms/Raw/Button';
import TextFormGroup from 'js/Components/Forms/Raw/TextFormGroup';
import noop from 'js/noop';

/**
 * Text Form Group Clear class.
 */
class TextFormGroupClear extends Component {
    static propTypes = {
        name: PropTypes.string,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        name: null,
        onChange: noop,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
     constructor(props) {
        super(props);

        this.state = {
            text: '',
        };

        // Efficient early binding.
        this.onChangeText = this.onChangeText.bind(this);
        this.onClearText = this.onClearText.bind(this);
    }

    /**
     * Handle event for user typing the text box.
     *
     * @param {string} name
     * @param {Event} event
     *
     * @returns {void}
     */
    onChangeText(name, event) {
        const { onChange } = this.props;

        if (onChange) {
            onChange(name, event);
        }

        this.setState({
            text: event.currentTarget.value,
        });
    }

    /**
     * Event handler for the text box clear button.
     *
     * @param {Event} event
     *
     * @returns {void}
     */
    onClearText(event) {
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(name, event);
        }

        this.setState({
            text: '',
        });
    }

    /**
     * Render the Text Form Group with clear option.
     *
     * @returns {React.node}
     */
    render() {
        const { ...passThroughProps } = this.props;
        const { text } = this.state;

        return (
            <TextFormGroup
                {...passThroughProps}
                append={
                    <Button
                        onClick={this.onClearText}
                        primary
                        title="Clear"
                        className="clear-button"
                    >
                        &times;
                        <span className="a11y-sr-only">clear</span>
                    </Button>
                }
                onChange={this.onChangeText}
                value={text}
            />
        )
    }
}

export default TextFormGroupClear;
