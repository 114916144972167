/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import reportShape from 'js/PropTypes/reportShape';

class MainInfo extends PureComponent {
    static propTypes = {
        auditedOn: PropTypes.string.isRequired,
        guidelines: PropTypes.shape({
            passed: PropTypes.number.isRequired,
            total: PropTypes.number.isRequired,
        }).isRequired,
        website: PropTypes.string.isRequired,
        report: reportShape
    };

    render() {
        const { auditedOn: rawDate, guidelines: { passed, total, all }, isFinalized, website, report } = this.props;
        const auditedOn = new Date(rawDate);
        console.log(all);
        return (
            <section className="info-main">
                <section className="info-site">
                    <p className="subtitle">{website}</p>
                    <p>{`Audit date: ${isFinalized === 3 ? auditedOn.toLocaleDateString() : 'TBD'}`}</p>
                </section>
                {
                    report.is_doc ?
                    <>
                        <section className="info-guidelines-summary">
                            <span className="info-guidelines-passed">{`${report.guidelines.length - total + passed }/${report.guidelines.length}`}</span>
                            Guidelines Passed*
                            {
                                all != total &&
                                <div className='info-guidelines-summary-doc-note'>{all - report.guidelines.length} {all - report.guidelines.length > 1 ? 'guidelines were': 'guideline was'} not evaluated</div>
                            }
                        </section>
                    </>
                    :<section className="info-guidelines-summary">
                        <span className="info-guidelines-passed">{`${passed}/${total}`}</span>
                        Guidelines Passed
                    </section>
                }
            </section>
        );
    }
}

export default MainInfo;
