/**
* State select component.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Select from 'js/Components/Forms/Raw/Select';
import stateCodeConfig from 'js/config/stateCodes';

/**
 * State select component class.
 */
class StateSelect extends Component {
    static propTypes = {
        countryCode: PropTypes.string,
    };

    static defaultProps = {
        countryCode: null,
    };

    /**
     * Get only applicable states for selected country.
     *
     */
    getStates() {
        const { countryCode } = this.props;
        const applicableStates = {};

        if (countryCode === null) {
            return stateCodeConfig;
        }

        for (const state in stateCodeConfig) {
            if (state.indexOf(`${countryCode}-`) === 0) {
                applicableStates[state] = stateCodeConfig[state];
            };
        }

        return applicableStates;
    }

    /**
     * Render the state select.
     *
     * @returns {React.node}
     */
    render() {
        const {countryCode, ...passThroughProps } = this.props;

        return (
            <Select
                name="stateCode"
                options={this.getStates()}
                { ...passThroughProps }
            />
        );
    }
}

export default StateSelect;
