/**
 * Define the structure of an organization in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

const organizationPropType = PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    stateCode: PropTypes.string,
});

export default organizationPropType;
