/**
* Reset Password form.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import Form from 'js/Components/Forms/Raw/Form';
import { minLength, required } from 'js/validations';
import TextFormGroup from 'js/Components/Forms/Raw/TextFormGroup';

/**
 * Reset Password form.
 */
class ResetPasswordUpdateForm extends Component {
    static propTypes = {
        token: PropTypes.string,
    };

    static defaultProps = {
        token: null,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            sent: false,
        };
        this.validations = [
            {
                name: 'password',
                message: 'Please enter your new password.',
                validate: required,
            },
            {
                name: 'password',
                message: 'Please enter at least 8 characters.',
                validate: minLength,
                params: {
                    min: 8,
                },
            },
            {
                name: 'confirm',
                message: 'Please confirm password by entering it a second time.',
                validate: required,
            },
            {
                name: 'confirm',
                message: 'Please enter at least 8 characters.',
                validate: minLength,
                params: {
                    min: 8,
                },
            },
            {
                name: 'confirm',
                message: 'Please verify that you entered the same password twice. Confirm password must match your new password.',
                validate: (values) => {
                    return values.confirm === values.password;
                }
            }
        ];
        this.time = null
        // Efficient early binding
        this.onResetPassword = this.onResetPassword.bind(this);
    }

    componentWillUnmount(){
        if(this.time !== null)
        {
            clearTimeout(this.time)
        }
    }

    /**
     * Handle password reset action.
     *
     * @returns {void}
     */
     onResetPassword(responseBody, status) {
        if (status === 200) {
            this.time = setTimeout(()=>{
                window.location.href ="/sign-in"
            },500);
            this.setState({
                sent: true,
            });
        }
    }

    /**
     * Render the reset password  form.
     *
     * @returns {React.node}
     */
    render() {
        const { token,isWelcome } = this.props;

        return (
            <Form
                afterSubmit={this.onResetPassword}
                buttonLabel={isWelcome?"Create Password":"Update Password"}
                fetchSubmit
                url={`${apiConfig}auth/reset/${token}`}
                validations={this.validations}
            >
                <TextFormGroup
                    aria-label="New Password"
                    name="password"
                    placeholder="New password"
                    type="password"
                />
                <TextFormGroup
                    aria-label="Confirm Password"
                    name="confirm"
                    placeholder="Confirm password"
                    type="password"
                />
            </Form>
        );
    }
}

export default ResetPasswordUpdateForm;
