/**
 * Konami Cheat Code Easter Egg.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import { Component } from 'react';

class Egg extends Component {
    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            codePosition: 0,
        };
        this.code = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];

        // Efficient early binding
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onCodeSuccess = this.onCodeSuccess.bind(this);
    }

    /**
     * Bind the keyboard event listener to the
     * document when the component mounts.
     *
     * @returns {void}
     */
    componentDidMount() {
        document.addEventListener('keyup', this.onKeyPress);
    }

    /**
     * Clean up the keyboard event listener when
     * the component unmounts like a good person.
     *
     * @returns {void}
     */
    componentWillUnmount() {
        document.removeEventListener('keyup', this.onKeyPress);
    }

    /**
     * Check the key sequence.
     *
     * @param {KeyboardEvent} event Keyboard event
     */
    onKeyPress(event) {
        const { codePosition } = this.state;
        let newCodePosition = codePosition;

        if (event.key === this.code[codePosition]) {
            newCodePosition = codePosition + 1;

            if (newCodePosition === 10) {
                this.onCodeSuccess();
                newCodePosition = 0;
            }
        } else {
            newCodePosition = 0;
        }

        if (codePosition !== newCodePosition) {
            this.setState({
                codePosition: newCodePosition,
            });
        }
    }

    /**
     * Handle event for successful code entry.
     */
    onCodeSuccess() {
        alert('Cheat mode activated.');
    }

    /**
     * Render easter egg.
     *
     * @future The react thing to do here would be to return something in the HTML after cheat code entry.
     *
     * @returns {null}
     */
    render() {
        return null;
    }
}

export default Egg;
