/**
 * Front end Manage Organizations page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Breadcrumb from 'js/Components/Breadcrumb/Breadcrumb';
import PagePermissionAlert from 'js/Components/Alert/PagePermissionAlert';
import PageWrapper from 'js/Components/Pages/PageWrapper';
import UserContext from 'js/Context/UserContext';
import OrganizationManageBlock from 'js/Components/Blocks/OrganizationManageBlock';

/**
 * Front end Manage Organizations page.
 */
class ManageOrganizations extends Component {
    /**
     * Render Manage Organizations page.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <PageWrapper
                description="Manage Clients"
                title="Manage Clients"
            >
                <Breadcrumb
                    label="Manage Clients"
                    description="Add, edit, or delete client organizations."
                />
                    <UserContext.Consumer>
                        { ({ hasPermission }) => {
                            if (!hasPermission('manage.organizations')) {
                                return (
                                    <PagePermissionAlert />
                                );
                            }

                            return (
                                <OrganizationManageBlock />
                            );
                        }}
                    </UserContext.Consumer>
            </PageWrapper>
        );
    }
}

export default ManageOrganizations;
