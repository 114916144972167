/**
 * Front end Report page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import AdaReport from 'js/Components/Report/AdaReport';
import matchPropType from 'js/PropTypes/match';
import PagePermissionAlert from 'js/Components/Alert/PagePermissionAlert';
import PageWrapper from 'js/Components/Pages/PageWrapper';
import ReportItem from 'js/Components/Api/ReportItem';
import UserContext from 'js/Context/UserContext';
import List from '../Api/List';

/**
 * Front end Report page.
 */
class Report extends Component {
    static propTypes = {
        match: matchPropType,
    };

    static defaultProps = {
        match: {
            params: {},
        },
    };

    /**
     * Render Report page.
     *
     * @returns {React.node}
     */
    render() {
        const { match: { params: { id } } } = this.props;

        return (
            <PageWrapper
                description="ADA Report"
                title="ADA Report"
            >
                <UserContext.Consumer>
                    { ({ hasPermission, personSession }) => {
                        if (!hasPermission('view.reports')) {
                            return (
                                <PagePermissionAlert />
                            );
                        }

                        return (
                            <List
                                page="report"
                                id={id}
                                sessionId={personSession.session.id}
                            >
                                <AdaReport
                                    sessionId={personSession.session.id}
                                    pdf={false}
                                />
                            </List>
                        );
                    }}
                </UserContext.Consumer>
            </PageWrapper>
        );
    }
}

export default Report;
