/**
 * Page Header.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Brand from 'js/Components/PageHeader/Brand';
import Navigation from 'js/Components/PageHeader/Navigation/Navigation';
import UserDropdown from 'js/Components/PageHeader/UserDropdown';

/**
 * Page Header class.
 */
class Header extends Component {
    /**
     * Render page header.
     *
     * @returns {React.node}
     */
    render() {
        const {personSession} = this.props;
        
        return (
          <header>
            <Brand />
            {personSession?.person?.roles[0].id === 4 ? null :
            <Navigation />
          }
          <UserDropdown />
          </header>
        );
    }
}

export default Header;
