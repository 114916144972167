/**
 * Form Validations.
 * This file includes reusable form validations for the smart forms.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import moment from 'moment';

/**
 * Validate that the field is a valid date.
 *
 * @param {array} fields
 * @param {string} field
 *
 * @returns {boolean}
 */
export function date(fields, field) {
    return moment(field, 'YYYY-MM-DD', true).isValid();
}

/**
 * Validate that the field is a valid email.
 *
 * @param {array} fields
 * @param {string} field
 *
 * @returns {boolean}
 */
export function email(fields, field) {
    let emailField = field;
    if (!field || field === null || field === undefined) {
        emailField = '';
    }

    // eslint-disable-next-line
    return (/(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*|^"([\001-\010\013\014\016-\037!#-\[\]-\177]|\\[\001-\011\013\014\016-\177])*")@((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)$)|\[(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)) {3}\]$/i.test(emailField));
}

/**
 * Validate that the field is a valid website.
 * https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
 *
 * @param {array} fields
 * @param {string} field
 *
 * @returns {boolean}
 */
 export function website(fields, field) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(field);
}

/**
 * Validate that the field meets maximum length.
 *
 * @param {array} fields
 * @param {string} field
 * @param {string} name
 * @param {object} params
 *
 * @returns {boolean}
 */
export function maxLength(fields, field, name, params) {
    return field && field.length <= params.max;
}

/**
 * Validate that the field meets minimum length.
 *
 * @param {array} fields
 * @param {string} field
 * @param {string} name
 * @param {object} params
 *
 * @returns {boolean}
 */
export function minLength(fields, field, name, params) {
    return field && field.length >= params.min;
}

/**
 * Validate that the field is numeric.
 *
 * @param {array} fields
 * @param {string} field
 *
 * @returns {boolean}
 */
export function number(fields, field) {
    return (!Number.isNaN(parseFloat(field)) && Number.isFinite(parseFloat(field)));
}

/**
 * Validate that the field is not empty.
 *
 * @param {array} fields
 * @param {string} field
 *
 * @returns {boolean}
 */
export function required(fields, field) {
    return !!(field && field !== null && field !== undefined && field !== '');
}

/**
 * Validate that the field contains capital letters.
 *
 * @param {array} fields
 * @param {string} field
 *
 * @returns {boolean}
 */
export function uppercaseChars(fields, field) {
    return field && field.toString().toLowerCase() !== field.toString();
}

/**
 * Validate that the field contains lowercase letters.
 *
 * @param {array} fields
 * @param {string} field
 *
 * @returns {boolean}
 */
export function lowercaseChars(fields, field) {
    return field && field.toString().toUpperCase() !== field.toString();
}

/**
 * Validate that the field contains numeric characters.
 *
 * @param {array} fields
 * @param {string} field
 *
 * @returns {boolean}
 */
export function numericChars(fields, field) {
    return /\d/.test(field);
}
