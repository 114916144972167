import severityColors from 'js/config/severityColors';
const xlsxSummaryByMetrics = (metrics)=>{
    let xlsxData =[]
    let header = [
      {
        width: 400,
        value: "Principle",
        fontWeight: 'bold'
      },
      {
        width: 400,
        value: "Guidelines Failed / Total",
        fontWeight: 'bold'
      },
      {
        width: 400,
        value: "Overall Severity Score",
        fontWeight: 'bold'
      }
    ]

    metrics['principles'].map((principle, index) => {
        let row = [
          {
            width: 400,
            value: principle['name'],
          },
          {
            width: 400,
            value: principle['total'] == 0 ? `NE` : `${principle['total'] - principle['passed']}/${principle['total']}`,
          },
          {
            width: 400,
            value: principle['severityLabel'],
          },
        ];

        principle.pages.map((page)=>{
          if(index=== 0){
            header.push({
              width: 300,
              value: page['name'],
              fontWeight: 'bold'
            })
          }
          row.push({
            width: 300,
            value: page['severity'],
            backgroundColor: principle['total'] == 0 ? '#ffffff' : severityColors[page['severity']]
          })

        })

        if(index=== 0){
          xlsxData.push(header)
        }
        xlsxData.push(row)
      })

      return xlsxData;
}
export default xlsxSummaryByMetrics;