/**
 * Edit Report Overview.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import MarkdownTextArea from 'js/Components/Forms/Raw/MarkdownTextArea';
import reportPropType from 'js/PropTypes/report';
import { GlobalStoreContext } from 'js/Context/UseGlobalStoreContext';
import IconButton from './Raw/IconButton';
import RunAuditToolButton from "../Forms/RunAuditToolButton";

/**
 * Edit report overview class.
 */
let timeoutId = null;
class Overview extends Component {
    static propTypes = {
        report: reportPropType,
    };

    static defaultProps = {
        report: {
            summary: null,
        },
    };

    constructor(props){
        super(props)
        this.state = {
            needSave:false,
            initialSummary:"",
            errorMessage:false
        };
        this.editor = React.createRef();
        this.onSaveSummary = this.onSaveSummary.bind(this)
        this.onChangeSummary = this.onChangeSummary.bind(this)
    }

    componentDidMount(){
        this.setState({
            initialSummary:this.context.reportItem.summary
        })
    }

    onSaveSummary(){
        const {reportItem,updateReportItem} = this.context;
        const {id, sessionId,setLoading,disableLoading} = this.props;
        const newSummary = this.editor.current.editorInst.getMarkdown()
        setLoading();
        this.setState({errorMessage:false})
        if(timeoutId !== null)
        {
            clearTimeout(timeoutId)
        }
        updateReportItem(reportItem,{summary:newSummary}, id, sessionId, (isSuccess,data)=>{
            if(!isSuccess)
            {
                this.setState({errorMessage:"Save unsuccessfully! Please retry!"})
                setTimeout(()=>{
                    this.setState({errorMessage:false})
                },5000)
            }
            disableLoading();
        });
    }

    onChangeSummary(...props){
        const content = this.editor.current.editorInst.getMarkdown()
        if(this.state.needSave === true && content === this.state.initialSummary)
        {
            this.setState({
                needSave:false
            });
        }else if(this.state.needSave === false && content !== this.state.initialSummary){
            this.setState({
                needSave:true
            });
        }
    }

    getLevel(reportLevel){
        switch(reportLevel){
            case 1:
                return "WCAG 2.0 AA"
            case 2:
                return "WCAG 2.1 AA"
            case 3:
                return "WCAG 2.1 AAA"
            case 4:
                return "WCAG 2.2 AA"
            case 5:
                return "WCAG 2.2 AAA"
            default:
                return "Unknown"
        }
    }

    /**
     * Render the edit report overview.
     *
     * @returns {React.node}
     */
    render() {
        const {id,setLoading,disableLoading} =this.props
        const { auditedOn, reportLevel, summary, website,createdAt,customerNotes} = this.context.reportItem;

        return (
            <section className="edit-report-overview">
                <h3>Overview</h3>
                <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>  
                    <strong>{website} </strong> 
                    <p style={{fontWeight:"bold"}}>{this.getLevel(reportLevel)}</p>
                </div>
                <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>  
                    <p><strong style={{marginRight:"16px"}}>Requested:</strong>{createdAt.replace(/T.*/,"")}</p> 
                    {auditedOn?<p><strong style={{marginRight:"16px"}}>Audited:</strong>{auditedOn.replace(/T.*/,"")}</p>:""}
                </div>
    
                <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                    <strong>Customer Notes:</strong>
                    <p>{customerNotes}</p>
                </div>
   
                <div style={{marginBottom:"16px"}}>
                    <RunAuditToolButton
                        id={id}
                        setLoading={setLoading}
                        disableLoading={disableLoading}
                    />
                </div>
                <h4>Summary</h4>
               
                <MarkdownTextArea
                    initialValue={summary}
                    editorRef={this.editor}
                    onChangeSummary={this.onChangeSummary}                    
                />
                <div>
                    {this.state.errorMessage !== false &&<div style={{color:"red",padding:"10px 0",fontSize:"16px", fontWeight:"bold"}}>{this.state.errorMessage}</div> }
                </div>
                <div style={{padding: "30px 0",visibility:(this.state.needSave?"visible":"hidden")}}>
                    <IconButton type="save" text="Save"  onClick={this.onSaveSummary}/>
                </div>
            </section>
        );
    }
}

Overview.contextType = GlobalStoreContext;
export default Overview;
