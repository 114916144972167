/**
* Page Permission Alert.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import React, { Component } from 'react';

import Alert from 'js/Components/Alert/Raw/Alert';
import { FaBan } from 'react-icons/fa';

/**
 * Page Permission Alert Class
 */
class PagePermissionAlert extends Component {
    /**
     * Render the page permission alert.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <section className="page-permission-alert">
                <Alert
                    closable={false}
                    icon={<FaBan />}
                    warning
                >
                    You do not have permission to view this page.
                </Alert>
            </section>
        );
    }
}

export default PagePermissionAlert;
