/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React from 'react';

function SeverityLegendTable() {
    return (
        <table className="table table-with-severity table-severity-legend">
            <thead className="a11y-sr-only">
                <tr>
                    <th>Label</th>
                    <th>Severity 0</th>
                    <th>Severity 1</th>
                    <th>Severity 2</th>
                    <th>Severity 3</th>
                    <th>Severity 4</th>
                    <th>Severity 5</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Severity Score:</td>
                    <td className="severity-cell severity-0">0</td>
                    <td className="severity-cell severity-1">1</td>
                    <td className="severity-cell severity-2">2</td>
                    <td className="severity-cell severity-3">3</td>
                    <td className="severity-cell severity-4">4</td>
                    <td className="severity-cell severity-5">5</td>
                </tr>
                <tr>
                    <td>Severity Level:</td>
                    <td>None</td>
                    <td>Low</td>
                    <td />
                    <td>Med</td>
                    <td />
                    <td>High</td>
                </tr>
            </tbody>
        </table>
    );
}

export default SeverityLegendTable;
