/**
 * Page Header User Badge.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import UserContext from 'js/Context/UserContext';

/**
 * Page Header User Badge class.
 */
class UserBadge extends Component {
    /**
     * RenderUser Badge.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <div className="user-badge">
                <UserContext.Consumer>
                    {({personSession}) => (
                        `${personSession.person.firstName.charAt(0)}${personSession.person.lastName.charAt(0)}`
                    )}
                </UserContext.Consumer>
            </div>
        );
    }
}

export default UserBadge;
