/**
 * Raw HTML Utility.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class RawHtml extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        html: PropTypes.string.isRequired,
    };

    static defaultProps = {
        className: 'html-wrapper',
    };

    componentDidMount() {
        this.setOuterHtml();
    }

    componentDidUpdate() {
        this.setOuterHtml();
    }

    setOuterHtml() {
        const { html } = this.props;
        //replace images src to report
     
        this.c.outerHTML =  html.replaceAll(`src="img/`,`src="/report/img/`);

        
    }

    /**
     * Render Raw HTML utility.
     *
     * @returns {React.node}
     */
    render() {
        const { className } = this.props;

        return <div className={className} ref={(c) => { this.c = c; }} />;
    }
}

export default RawHtml;
