/**
 * User Manage block.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import OrganizationList from 'js/Components/Api/OrganizationList';
import PersonList from 'js/Components/Api/PersonList';
import PersonTable from 'js/Components/Table/PersonTable';
import RoleList from 'js/Components/Api/RoleList';
import UserContext from 'js/Context/UserContext';

/**
 * User Manage block class.
 */
class UserManageBlock extends Component {
    /**
     * Render the User Manage block.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <section className="user-manage-block">
                <UserContext.Consumer>
                    {({ personSession, hasRole }) => {
                        const { id: sessionId } = personSession.session;

                        return (
                            <OrganizationList
                                sessionId={sessionId}
                            >
                                <RoleList
                                    sessionId={sessionId}
                                >
                                    <PersonList
                                        sessionId={sessionId}
                                    >
                                        <PersonTable
                                            isGlobalAdmin={hasRole('global.admin')}
                                        />
                                    </PersonList>
                                </RoleList>
                            </OrganizationList>
                        );
                    }}
                </UserContext.Consumer>
            </section>
        );
    }
}

export default UserManageBlock;
