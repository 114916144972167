/**
 * Organization Manage block.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import OrganizationList from 'js/Components/Api/OrganizationList';
import OrganizationTable from 'js/Components/Table/OrganizationTable';
import UserContext from 'js/Context/UserContext';

/**
 * Organization Manage block class.
 */
class OrganizationManageBlock extends Component {
    /**
     * Render the Organization Manage block.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <section className="organization-manage-block">
                <UserContext.Consumer>
                    {({ personSession }) => {
                        const { id: sessionId } = personSession.session;

                        return (
                            <OrganizationList
                                sessionId={sessionId}
                            >
                                <OrganizationTable />
                            </OrganizationList>
                        );
                    }}
                </UserContext.Consumer>
            </section>
        );
    }
}

export default OrganizationManageBlock;
