/**
 * Page Footer Brand section.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import FooterIcon from 'img/footer-icon.png';
import Link from 'js/Components/Link';

/**
 * Page Footer Brand class.
 */
class Brand extends Component {
    /**
     * Render page footer.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <li className="footer-brand">
                <img src={FooterIcon} alt="Scalesology" />
                <Link
                    href="https://www.scalesology.com"
                    label="Scalesology.com"
                />
            </li>
        );
    }
}

export default Brand;
