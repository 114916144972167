/**
 * Text Input Component.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import noop from 'js/noop';

/**
 * HTML text input
 */
class TextInput extends Component {
    static propTypes = {
        inputRef: PropTypes.func,
        label: PropTypes.string,
        name: PropTypes.string,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        inputRef: noop,
        label: '',
        name: '',
        onChange: noop,
    };

    /**
     * Render text box.
     *
     * @returns {React.node}
     */
    render() {
        const { inputRef, label, name, onChange, ...passThroughProps } = this.props;

        return (
            <input
                className="form-control"
                name={name}
                placeholder={label}
                title={label}
                type="text"
                {...passThroughProps}
                onChange={onChange}
                ref={inputRef}
            />
        );
    }
}

export default TextInput;
