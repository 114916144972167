/**
 * Edit organization button.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddEditOrganizationModal from 'js/Components/Modal/AddEditOrganizationModal';
import Button from 'js/Components/Forms/Raw/Button';
import noop from 'js/noop';
import organizationPropType from 'js/PropTypes/organization';
import UserContext from 'js/Context/UserContext';

/**
 * Edit organization button class.
 */
class EditOrganizationButton extends Component {
    static propTypes = {
        onEditOrganization: PropTypes.func,
        organization: organizationPropType,
    };

    static defaultProps = {
        onEditOrganization: noop,
        organization: null,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
     constructor(props) {
        super(props);

        this.state = {
            addModalOpen: false,
        };

        // Efficient early binding.
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onEditOrganization = this.onEditOrganization.bind(this);
        this.onShowModal = this.onShowModal.bind(this);
    }

    /**
     * Event handler for add after modal save.
     *
     * @param {object} editOrganization Edited organization object.
     *
     * @returns {void}
     */
     onEditOrganization(editOrganization) {
        const { onEditOrganization, organization } = this.props;

        if (onEditOrganization) {
            onEditOrganization(organization.id, editOrganization);
        }

        this.onCloseModal();
    }

    /**
     * Event handler for modal close.
     *
     * @returns {void}
     */
     onCloseModal() {
        setTimeout(() => {(this.setState({
            addModalOpen: false,
        }))}, 1500)
    }

    /**
     * Event handler for modal show.
     *
     * @returns {void}
     */
     onShowModal() {
        this.setState({
            addModalOpen: true,
        });
    }

    /**
     * Conditionally render the add/edit organization modal.
     *
     * @returns {React.node|null}
     */
    renderModal(hasPermission,personSession,hasRole) {
        const { organization,organizations } = this.props;
        const { addModalOpen } = this.state;

        if (addModalOpen) {
            return (
                <AddEditOrganizationModal
                    action="edit"
                    onOrganizationSaved={this.onEditOrganization}
                    onClose={this.onCloseModal}
                    open={addModalOpen}
                    organization={organization}
                    personSession={personSession}
                    hasPermission={hasPermission}
                    hasRole={hasRole}
                    organizations={organizations}
                />
            );
        }

        return null;
    }

    /**
     * Render Add user button.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <UserContext.Consumer>
                {({  hasPermission,personSession,hasRole }) => {
                    if (hasPermission('manage.organizations')) {
                        return (
                            <>
                                <Button
                                    onClick={this.onShowModal}
                                >
                                    Edit
                                </Button>
                                {this.renderModal(hasPermission,personSession,hasRole)}
                            </>
                        );
                    }

                    return null;
                }}
            </UserContext.Consumer>
        );
    }
}

export default EditOrganizationButton;
