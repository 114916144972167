/**
 * Edit Report Page List.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import guidelinePropType from 'js/PropTypes/guideline';
import Page from 'js/Components/EditReport/Pages/Page';
import principlePropType from 'js/PropTypes/principle';
import reportPropType from 'js/PropTypes/report';
import { GlobalStoreContext } from 'js/Context/UseGlobalStoreContext';

/**
 * Edit report page list class.
 */
class PageList extends Component {
    static propTypes = {
        guidelines: PropTypes.arrayOf(guidelinePropType),
        principles: PropTypes.arrayOf(principlePropType),
        report: reportPropType,
    };

    static defaultProps = {
        guidelines: [],
        principles: [],
        report: {
            pages: [],
        },
    };

    /**
     * Render the list of applicable pages.
     */
    renderPages() {
        const {id,sessionId,disableLoading,setLoading} = this.props
        const { guidelines, principles, reportItem: { pages, reportLevel },deleteReportPage} = this.context;

        return pages.map((page) => {
            return (
                <Page
                    guidelines={guidelines}
                    key={page.id}
                    page={page}
                    principles={principles}
                    reportLevel={reportLevel}
                    id={id}
                    deleteReportPage={deleteReportPage}
                    sessionId={sessionId}
                    reportItem={this.context.reportItem}
                    setLoading={setLoading}
                    disableLoading={disableLoading}
                />
            );
        });
    }

    /**
     * Render the edit report page list.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <section className="edit-report-page-list">
                <h3>Pages</h3>
                {this.renderPages()}
            </section>
        );
    }
}
PageList.contextType = GlobalStoreContext;
export default PageList;
