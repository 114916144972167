/**
* Page Footer Privacy Item.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import React, { Component } from 'react';

import Button from 'js/Components/Forms/Raw/Button';
import ButtonItem from 'js/Components/PageHeader/Navigation/ButtonItem';
import MarkdownText from 'js/Components/Utils/MarkdownText';
import Modal from 'js/Components/Modal/Raw/Modal';
import ModalBody from 'js/Components/Modal/Raw/ModalBody';
import ModalFooter from 'js/Components/Modal/Raw/ModalFooter';
import ModalHeader from 'js/Components/Modal/Raw/ModalHeader';

// Work around inability to edit webpack config in CRA with raw-loader
/* eslint-disable import/no-webpack-loader-syntax */
import terms from '!raw-loader!markdown/terms.md';

/**
 * Page Footer Terms Item class.
 */
class TermsItem extends Component {
    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
        };

        // Efficient early binding.
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalOpen = this.onModalOpen.bind(this);
    }

    /**
     * Handle modal open event.
     *
     * @returns {void}
     */
    onModalClose() {
        this.setState({
            modalOpen: false,
        });
    }

    /**
     * Handle modal close event.
     *
     * @returns {void}
     */
    onModalOpen() {
        this.setState({
            modalOpen: true,
        });
    }
    /**
     * Render page footer privacy item.
     *
     * @returns {React.node}
     */
    render() {
        const { modalOpen } = this.state;

        return (
            <>
                <ButtonItem
                    label="Terms of Use"
                    onClick={this.onModalOpen}
                />
                <Modal
                    className="modal-wide"
                    open={modalOpen}
                    onClose={this.onModalClose}
                >
                    <ModalHeader>Terms of Use</ModalHeader>
                    <ModalBody>
                        <MarkdownText text={terms} />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={this.onModalClose}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default TermsItem;
