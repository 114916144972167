/**
 * Top 5 current reports on dashboard
 *
 * @author Asher Loyd <asher.loyd@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Item from 'js/Components/PageHeader/Navigation/Raw/Item';
import reportPropType from 'js/PropTypes/report';
import {GlobalStoreContext} from "js/Context/UseGlobalStoreContext";

 /**
  * Page Header Report dropdown.
  */
class Top5Reports extends Component {
    static propTypes = {
        reports: PropTypes.arrayOf(reportPropType),
    };

    static defaultProps = {
        reports: [],
    };

    componentDidMount(){
        this.context.loadReportList(this.props.session);
    }

    /**
    * Render page top 5 drop down
    *
    * @returns {React.node}
    */
    render() {
        const reports = this.context.reportlist;
        const filteredReports = reports.filter(
          (report) => report.isFinalized === 3
        );
        return filteredReports
            .sort((r1, r2) => {
                if (r1.createdAt < r2.createdAt) {
                    return 1;
                }

                if (r1.createdAt > r2.createdAt) {
                    return -1;
                }

                return 0;
            })
            .slice(0, 5)
            .map((report) => {
                const { id, website } = report;

                return (
                    <Item
                        href={`/report/${id}`}
                        key={id}
                        label={website}
                    />

                );
            });
    }
}
Top5Reports.contextType = GlobalStoreContext
export default Top5Reports;
