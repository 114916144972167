import { useCallback,useState } from "react"
import useHttp from "./useHttp"
import apiConfig from 'js/config/api';

let working = false;
const usePrincipleList = ()=>{
    const {isLoading, error, sendRequest} = useHttp()
    const [principles, setPrinciples] = useState([]);


    const loadPrinciples = useCallback((sessionId, forceload=false)=>{
        if(working === false || forceload)
        {
            working = true;
            sendRequest({
                url: `${apiConfig}principle/list?sessionId=${sessionId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
            },
            (data)=>{
                setPrinciples(data)
                working = false
            },
            ()=>{
                working = false
            }

        )
        }

    },[isLoading])


    return {
        isLoadingPrinciples: isLoading,
        isErrorPrinciples: error,
        principles:principles,
        loadPrinciples: loadPrinciples
    }
}

export default usePrincipleList;