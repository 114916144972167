/**
* Reset Password form.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import { email, required } from 'js/validations';
import Form from 'js/Components/Forms/Raw/Form';
import TextFormGroup from 'js/Components/Forms/Raw/TextFormGroup';

/**
 * Reset Password form.
 */
class ResetPasswordForm extends Component {
    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            sent: false,
        };
        this.validations = [
            {
                name: 'email',
                message: 'Please enter a valid email address.',
                validate: required,
            },
            {
                name: 'email',
                message: 'Please enter a valid email address.',
                validate: email,
            },
        ];

        // Efficient early binding
        this.onResetRequest = this.onResetRequest.bind(this);
    }

    /**
     * Handle password reset request.
     *
     * @returns {void}
     */
    onResetRequest(responseBody, status) {
        if (status === 200) {

            this.setState({
                sent: true,
            });
        }
    }

    /**
     * Render the reset password form.
     *
     * @returns {React.node}
     */
    render() {
        const { sent } = this.state;

        if (sent) {
            return (
                <div className="reset-password-form">
                    <p>Please check your email.</p>
                    <p>We sent you an email with instructions for resetting your password.</p>
                </div>
            );
        }

        return (
            <Form
                afterSubmit={this.onResetRequest}
                buttonLabel="Reset Password"
                fetchSubmit
                url={`${apiConfig}auth/reset/request`}
                validations={this.validations}
            >
                <TextFormGroup
                    aria-label="Email address"
                    placeholder="Email address"
                    name="email"
                />
            </Form>
        );
    }
}

export default ResetPasswordForm;
