/**
 * DropDown Base
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import childrenPropType from 'js/PropTypes/children';

/**
 * DropDown Base Class
 */
class DropDown extends Component {
    static propTypes = {
        ariaLabel: PropTypes.string,
        className: PropTypes.string,
        children: childrenPropType,
        label: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
        ]),
        tabIndex: PropTypes.number,
        title: PropTypes.string,
    };

    static defaultProps = {
        ariaLabel: 'Expand',
        className: null,
        children: null,
        label: 'Click to open',
        tabIndex: -1,
        title: null,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
        this.wrapper = null;

        // Efficient early binding.
        this.onClickOutside = this.onClickOutside.bind(this);
        this.onCloseMenu = this.onCloseMenu.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.setWrapper = this.setWrapper.bind(this);
    }

    /**
     * Add event handler for mousedown outside element.
     */
    componentDidMount() {
        document.addEventListener('mousedown', this.onClickOutside);
    }

    /**
     * Remove event handler for mousedown outside element.
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickOutside);
    }

    /**
     * Handle click event outside of element.
     */
    onClickOutside(e) {
        if (this.wrapper && !this.wrapper.contains(e.target)) {
            this.setState({
                open: false,
            });
        }
    }

    /**
     * Allow close from children.
     *
     * @param {Event} e React Synthetic Event
     *
     * @returns {void}
     */
    onCloseMenu(e) {
        e.preventDefault();

        this.setState({
            open: false,
        });
    }

    /**
     * Handle click event inside of element.
     *
     * @param {Event} e React Synthetic Event.
     *
     * @returns {void}
     */
    onToggle(e) {
        e.stopPropagation();
        const { open } = this.state;

        this.setState({
            open: !open,
        });
    }

    /**
     * Set the wrapper ref.
     */
    setWrapper(node) {
        this.wrapper = node;
    }

    /**
     * Render the dropdown menu.
     *
     * @returns {React.node}
     */
    render() {
        const { ariaLabel, children, className, label, tabIndex, title, ...passThroughProps } = this.props;
        const { open } = this.state;
        const dropdownClasses = classNames({
            'dropdown': true,
            open,
            [className]: className,
        });
        const ariaExpanded = open.toString();
        const ariaHidden = (!open).toString();
        const childrenWithClose = React.Children.map(children, (child) => {
            if (child === null || child === undefined) {
                return null;
            }

            if (typeof child === 'string' || child.type === 'ul') {
                return child;
            }

            return React.cloneElement(child, {
                onCloseMenu: this.onCloseMenu,
                ...passThroughProps,
            });
        });

        return (
            <div className={dropdownClasses} ref={this.setWrapper}>
                <div
                    aria-label={ariaLabel}
                    aria-expanded={ariaExpanded}
                    className="dropdown-control"
                    onClick={this.onToggle}
                    onKeyPress={this.onToggle}
                    role="button"
                    tabIndex={tabIndex}
                    title={title}
                >
                    {label}
                </div>
                <div className="dropdown-pane" aria-hidden={ariaHidden}>
                    {childrenWithClose}
                </div>
            </div>
        );
    }
}

export default DropDown;
