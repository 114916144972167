/**
 * Manage Client Item.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Item from 'js/Components/PageHeader/Navigation/Raw/Item';
import UserContext from 'js/Context/UserContext';

/**
 * Manage Client Item class.
 */
class ManageClientItem extends Component {
    /**
     * Render manage client item.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <UserContext.Consumer>
                {({ hasPermission }) => {
                    if (hasPermission('manage.organizations')) {
                        return (
                            <Item
                                href="/manage-organizations"
                                label="Manage Clients"
                            />
                        );
                    }

                    return null;
                }}
            </UserContext.Consumer>
        );
    }
}

export default ManageClientItem;
