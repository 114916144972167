/**
 * Add organization button.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddEditOrganizationModal from 'js/Components/Modal/AddEditOrganizationModal';
import Button from 'js/Components/Forms/Raw/Button';
import noop from 'js/noop';
import UserContext from 'js/Context/UserContext';

/**
 * Add organization button class.
 */
class AddOrganizationButton extends Component {
    static propTypes = {
        onAddOrganization: PropTypes.func,
    };

    static defaultProps = {
        onAddOrganization: noop,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
     constructor(props) {
        super(props);

        this.state = {
            addModalOpen: false,
        };

        // Efficient early binding.
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onAddOrganization = this.onAddOrganization.bind(this);
        this.onShowModal = this.onShowModal.bind(this);
    }

    /**
     * Event handler for add after modal save.
     *
     * @returns {void}
     */
     onAddOrganization(newOrganization) {
        const { onAddOrganization } = this.props;

        if (onAddOrganization) {
            onAddOrganization(newOrganization);
        }

        this.onCloseModal();
    }

    /**
     * Event handler for modal close.
     *
     * @returns {void}
     */
     onCloseModal() {
        this.setState({
            addModalOpen: false,
        });
    }

    /**
     * Event handler for modal show.
     *
     * @returns {void}
     */
     onShowModal() {
        this.setState({
            addModalOpen: true,
        });
    }

    /**
     * Conditionally render the add/edit organization modal.
     *
     * @returns {React.node|null}
     */
    renderModal(hasPermission,personSession,hasRole) {
        const { addModalOpen } = this.state;

        if (addModalOpen) {
            return (
            <AddEditOrganizationModal
                action="add"
                onOrganizationSaved={this.onAddOrganization}
                onClose={this.onCloseModal}
                open={addModalOpen}
                personSession={personSession}
                hasPermission={hasPermission}
                hasRole={hasRole}
                organizations={this.props.organizations}
            />
            );
        }

        return null;
    }

    /**
     * Render Add user button.
     *
     * @returns {React.node|null}
     */
    render() {
        return (
            <UserContext.Consumer>
                {({ hasPermission,personSession,hasRole}) => {
                    if (hasPermission('manage.organizations')) {
                        return (
                            <>
                                <Button
                                    onClick={this.onShowModal}
                                    primary
                                >
                                    Add Organization
                                </Button>
                                {this.renderModal(hasPermission,personSession,hasRole)}
                            </>
                        );
                    }

                    return null;
                }}
            </UserContext.Consumer>
        );
    }
}

export default AddOrganizationButton;
