/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { PureComponent } from 'react';

import Chart from 'js/Components/Report/Chart';
import metricsShape from 'js/PropTypes/metricsShape';
import severityColors from 'js/config/severityColors';
import severityLevels from 'js/config/severityLevels';

class PrincipleGraphs extends PureComponent {
    static propTypes = {
        metrics: metricsShape.isRequired,
    }

    getChartConfig(severityCounts) {
        return {
            type: 'doughnut',
            data: {
                labels: severityLevels,
                datasets: [{
                    data: severityCounts.map(({ number }) => number),
                    backgroundColor: severityColors,
                }],
            },
            options: {
                title: { display: false },
                legend: { display: false },
                cutoutPercentage: 50,
            },
        };
    }

    renderSeverityLabels(severityCounts) {
        const labels = [];
        severityCounts.forEach(({ number, label }, severity) => {
            const labelDescription =  label.toLocaleLowerCase() == 'ne'? 'NE guidelines':`${label.toLocaleLowerCase()} severity`;

            // Only show severities with violations
            if (severity > 0 && number > 0) {
                labels.push(<span key={label}>{`${number} ${labelDescription}`}</span>);
            }
        });

        return labels;
    }

    renderPrincipleChart(principle) {
        const {
            slug,
            name,
            total,
            violations,
            guidelines,
        } = principle;
        const id = `chart-${slug}`;

        // Tally the number of guidelines that fall under each severity label
        const severityCounts = severityLevels.map((label) => ({ number: 0, label }));
        guidelines.forEach(({ severity, applied }) => {
            if(applied){
                severityCounts[severity].number += 1;
            } else {
                severityCounts[6].number += 1;
            }
            
        });

        return (
            <figure key={slug} className="chart chart-perceivable">
                <Chart config={this.getChartConfig(severityCounts)} id={id} />
                <figcaption>
                    <span className="figure-caption">{name}</span>
                    <span>{`${total} guidelines`}</span>
                    {this.renderSeverityLabels(severityCounts)}
                    <span>{`${violations} violations`}</span>
                </figcaption>
            </figure>
        );
    }

    render() {
        const { metrics: { principles } } = this.props;
        const figures = principles.map((principle) => this.renderPrincipleChart(principle));

        return (
            <section className="overview-principle-graphs">
                <figure className="charts-principles">
                    <div className="charts-principles-container">
                        {figures}
                    </div>
                    <figcaption>
                        Figure 3
                    </figcaption>
                </figure>
            </section>
        );
    }
}

export default PrincipleGraphs;
