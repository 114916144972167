/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import SeverityCalculator from '@scalesology/ada-tool/src/severity-calculator';

import severityLevels from 'js/config/severityLevels';

function calculateMetricsFromReport(principles, guidelines, { reportLevel, pages, is_doc, guidelines: reportGuidelines }) {
    const totalGuidelines = { passed: 0, total: 0, all:0 };
    const principleSeverityInfo = [];
    const severityInfo = severityLevels.map((name) => ({ name, violations: 0 }));
    const severityCalculator = new SeverityCalculator();
    const reportAppliedGuidelinesList = {};

    reportGuidelines.forEach(({guideline_id})=>{
        reportAppliedGuidelinesList[guideline_id] = guideline_id;
    })

    principles.forEach(({ id: principleId, name, slug: principleSlug }) => {
        const principleInfo = {
            slug: principleSlug,
            name,
            passed: 0,
            total: 0,
            all:0,
            violations: 0,
            severity: 0,
            severityLabel: severityLevels[0],
            guidelines: [],
            pages: [],
        };

        guidelines.forEach((guideline) => {
            const {
                id: guidelineId,
                diagnosticLevel,
                slug: guidelineSlug,
                name: guidelineName,
                complianceLevel,
            } = guideline;

            // Skip guidelines unrelated to this principle.
            if (guideline.principleId !== principleId) {
                return;
            }

            // Skip guidelines outside the purview of the report
            if(reportLevel == 4 && diagnosticLevel == 3){
                return;
            }
            else if (reportLevel < diagnosticLevel ) {
                return;
            }

            const isApplied =  is_doc ? reportAppliedGuidelinesList.hasOwnProperty(guidelineId) :true
            const guidelineInfo = {
                complianceLevel,
                id: guidelineId,
                slug: guidelineSlug,
                name: guidelineName,
                violations: 0,
                severity: 0,
                severityLabel: severityLevels[0],
                pages: [],
                applied: isApplied
            };
            let guidelineHasFailure = false;
            if(isApplied){
                totalGuidelines.total += 1;
                principleInfo.total += 1;
            }
            totalGuidelines.all += 1;
            principleInfo.all += 1;

            guidelineInfo.pages = pages.map(({ id: pageId, slug, name, violations: issues }, pageIndex) => {
                let pageInfo = {
                    slug,
                    name,
                    violations: 0,
                    severity: 0,
                    severityLabel: severityLevels[0],
                };

                // Also track pages by principle basis
                if (principleInfo.pages[pageIndex] === undefined) {
                    principleInfo.pages.push({ ...pageInfo });
                }

                // No issues present for this guideline
                const isIssuePresent = issues.find(x => x.guidelineId === guidelineId) !== undefined;
                if (!isIssuePresent) {
                    return pageInfo;
                }

                guidelineHasFailure = true;

                const applicableIssues = issues.filter((issue) => {
                    return issue.guidelineId === guidelineId;
                });

                // Add violation counts
                applicableIssues.forEach(({ details,violations }) => {
                    const violationCount = Math.max(details.length,violations);

                    pageInfo.violations += violationCount;
                    guidelineInfo.violations += violationCount;
                    principleInfo.violations += violationCount;
                    principleInfo.pages[pageIndex].violations += violationCount;
                });

                pageInfo = {
                    ...pageInfo,
                    tests: applicableIssues,
                    ...severityCalculator.calculate(
                        pageInfo.violations,
                        complianceLevel,
                    ),
                };

                // Set actual severity level based on the severity calculator.
                pageInfo.severityLabel = severityLevels[pageInfo.severity];

                // Add violations to the aggregation by severity level
                severityInfo[pageInfo.severity].violations += pageInfo.violations;

                // Record the highest severity for a guideline
                if (pageInfo.severity > guidelineInfo.severity) {
                    guidelineInfo.severity = pageInfo.severity;
                    guidelineInfo.severityLabel = pageInfo.severityLabel;
                }

                // Record the highest severity for a principle
                if (pageInfo.severity > principleInfo.severity) {
                    principleInfo.severity = pageInfo.severity;
                    principleInfo.severityLabel = pageInfo.severityLabel;
                }

                // Record the highest severity for a principle page
                if (pageInfo.severity > principleInfo.pages[pageIndex].severity) {
                    principleInfo.pages[pageIndex].severity = pageInfo.severity;
                    principleInfo.pages[pageIndex].severityLabel = pageInfo.severityLabel;
                }

                return pageInfo;
            });

            principleInfo.guidelines.push(guidelineInfo);

            if (!guidelineHasFailure && isApplied) {
                totalGuidelines.passed += 1;
                principleInfo.passed += 1;
            }
        });

        principleSeverityInfo.push(principleInfo);
    });

    return { totalGuidelines, principles: principleSeverityInfo, severity: severityInfo };
}

export default calculateMetricsFromReport;
