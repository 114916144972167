import {useState,useEffect, useCallback} from "react"
import { sessionKey } from "js/config/api";

const useHttp = () =>{
    const [isLoading, setIsLoading] = useState(null);
    const [error,setError] = useState(false);

    const sendRequest =  useCallback(async(requestConfig,applyData,errorFunc,updateStatus=true) => {
        if(updateStatus)
        {
            setError(false);
            setIsLoading(true);
        }

        let response
        try{
            response =await fetch(
                requestConfig.url,
                {
                    method: requestConfig.method ? requestConfig.method: "GET",
                    headers:requestConfig.headers ? requestConfig.headers : {},
                    body:requestConfig.body ? JSON.stringify(requestConfig.body): null
                }
            )

            if(response.status ===  401)
            {
                httpError401();
            }
            else if(response.status !== 200){
                throw new Error("Request failed!");
            }
     
            const data = await response.json();
     
            applyData(data);

        }catch(e){
            if(updateStatus)
            {
                //console.log(requestConfig,response,e);
                setError(true)//e.message || 'Somthing went wrong!')
            }
            errorFunc(e)
        }
        if(updateStatus)
        {
            setIsLoading(false)
        }
    },[])

    return {
        isLoading,
        error,
        sendRequest
    }
}

export const httpError401 = ()=>{
    if(localStorage.getItem(sessionKey) !== null)
    {
        localStorage.removeItem(sessionKey);
        window.location.href = "sign-in"
    }
}

export default useHttp;
