/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class HoverableAnchor extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
    };

    render() {
        const { id } = this.props;

        return (
            <a aria-label="Anchor" className="hoverable-anchor" href={`#${id}`}>
                <span className="fas fa-link" />
            </a>
        );
    }
}

export default HoverableAnchor;
