/**
 * Dashboard Request Report Block.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';

import Button from 'js/Components/Forms/Raw/Button';
import noop from 'js/noop';
import reportLevelListPropType from 'js/PropTypes/reportLevelList';
import RequestReportModal from 'js/Components/Modal/RequestReportModal';

/**
 * Dashboard Request Report Block class.
 */
class RequestReportBlock extends Component {
    static propTypes = {
        levels: reportLevelListPropType,
        onRequestAudit: PropTypes.func,
    };

    static defaultProps = {
        levels: {},
        onRequestAudit: noop,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
     constructor(props) {
        super(props);

        this.state = {
            requestModalOpen: false,
        };

        // Efficient early binding.
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onRequestAudit = this.onRequestAudit.bind(this);
        this.openRequestModal = this.openRequestModal.bind(this);
    }

    /**
     * Event handler for request after modal submit.
     *
     * @returns {void}
     */
     onRequestAudit(auditRequest) {
        const { onRequestAudit } = this.props;

        if (onRequestAudit) {
            onRequestAudit(auditRequest);
        }

        this.onCloseModal();
    }

    /**
     * Event handler for modal close.
     *
     * @param {Event} e React synthetic event.
     *
     * @returns {void}
     */
     onCloseModal(e,isRefresh) {
        e.stopPropagation();

        this.setState({
            requestModalOpen: false,
        });
        if(isRefresh)
        {
            this.props.onReportRefresh();
        }

    }

    /**
     * Event handler for modal show.
     *
     * @returns {void}
     */
     openRequestModal() {
        this.setState({
            requestModalOpen: true,
        });
    }

    /**
     * Conditionally render modal with form to request a new audit.
     *
     * @returns {React.node|null}
     */
    renderModal() {
        const { levels } = this.props;
        const { requestModalOpen } = this.state;

        if (requestModalOpen) {
            return (
                <RequestReportModal
                    onClose={this.onCloseModal}
                    open={requestModalOpen}
                    levels={levels}
                />
            );
        }

        return null;
    }

    /**
     * Render page footer.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <div
                className="report-block request-report-block"
                onClick={this.openRequestModal}
                onKeyPress={this.openRequestModal}
                role="button"
                tabIndex={0}
            >
                <div className="icon">
                    <IoIosAddCircleOutline size="64px" />
                </div>
                <Button
                    link
                    onClick={this.openRequestModal}
                >
                    Request a new audit
                </Button>
                {this.renderModal()}
            </div>
        );
    }
}

export default RequestReportBlock;
