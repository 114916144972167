/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

import reportPagePropType from 'js/PropTypes/reportPage';

const reportShape = PropTypes.shape({
    auditedOn: PropTypes.string.isRequired,
    pages: PropTypes.arrayOf(reportPagePropType).isRequired,
    level: PropTypes.number.isRequired,
    summary: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
});

export default reportShape;
