/**
 * Define the structure of a report level in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

const reportLevelPropType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
});

export default reportLevelPropType;
