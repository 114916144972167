/**
 * Client Certificate Item.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from "react";

import Item from "js/Components/PageHeader/Navigation/Raw/Item";
import UserContext from "js/Context/UserContext";

/**
 * Client Certificate Item class.
 */
class ClientCertificateItem2 extends Component {
  getLink(){
    const { version } = this.props;
    switch (version) { 
      case 5:
        return "/img/wcag2.2AAAScalesology.png";
      case 4:
        return "/img/wcag2.2AAScalesology.png";
      case 3:
        return "/img/wcag2.1AAAScalesology.png";

      case 2:
        return "/img/wcag2.1AAScalesology.png";


      default:
        return "/img/wcag2.0AAScalesology.png"
    }

  }

  getLabel(){
      const { version } = this.props;
      switch (version) { 
        case 5:
          return "Download WCAG 2.2 AAA Website Certified Logo";
        case 4:
          return "Download WCAG 2.2 AA Website Certified Logo";
        case 3:
          return "Download WCAG 2.1 AAA Website Certified Logo";

        case 2:
          return "Download WCAG 2.1 AA Website Certified Logo";


        default:
          return "Download WCAG 2.0 AA Website Certified Logoo";
      }
  }


  /**
   * Render client certificate item.
   *
   * @returns {React.node}
   */
  render() {
    return (
      <UserContext.Consumer>
        {({ hasPermission }) => {
          if (hasPermission("manage.organizations")) {
            return (
              <Item
                external
                href={this.getLink()}
                label={this.getLabel()}
                download
              />
            );
          }

          return null;
        }}
      </UserContext.Consumer>
    );
  }
}

export default ClientCertificateItem2;
