/**
 * Unauthenticated Route.
 *
 * A route that checks if a user is not authenticated
 * and redirects to specific url after login.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
 import React from 'react';
 import { Route} from 'react-router';

 
 
 const BothauthenticatedRoute = ({ component: C, props: cProps, ...passThroughProps }) => {

     return (
         <Route
             {...passThroughProps}
             render={props => <C {...props} {...cProps} />}
         />
     );
 };
 
 export default BothauthenticatedRoute;