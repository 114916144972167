/**
 * Importable user context.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import { createContext } from 'react';

import defaultPersonSession from 'js/config/defaultPersonSession';

const UserContext = createContext(defaultPersonSession);

export default UserContext;