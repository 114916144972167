/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { onlyText } from 'react-children-utilities';
import slugify from 'slugify';

import Heading from 'js/Components/Utils/Heading';

// ToDo: Fix some random error in the console
class SectionWithHeading extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        heading: PropTypes.node.isRequired,
        level: PropTypes.node.isRequired,

        htmlRef: PropTypes.func,
        id: PropTypes.string,
    };

    static defaultProps = {
        htmlRef: null,
        id: null,
    };

    getSectionId() {
        const { id, heading } = this.props;

        return id !== null ? id : slugify(onlyText(heading), { lower: true });
    }

    render() {
        const {
            children,
            heading,
            htmlRef,
            level,
            ...props
        } = this.props;
        const id = this.getSectionId();

        return (
            <section {...props} id={id} ref={htmlRef}>
                <Heading level={level} sectionId={id}>
                    {heading}
                </Heading>
                {children}
            </section>
        );
    }
}

export default SectionWithHeading;
