/**
 * Edit Report Guideline box.
 *
 */
import React, { Component } from 'react';
import { GlobalStoreContext } from 'js/Context/UseGlobalStoreContext';
import calculateMetricsFromReport from 'js/calculation/calculateMetricsFromReport';
import xlsxDetailsByMetrics from 'js/calculation/xlsxDetailsByMetrics';
import xlsxSummaryByMetrics from 'js/calculation/xlsxSummaryByMetrics';
import writeXlsxFile from 'write-excel-file'

class XlsxReport extends Component {

    constructor(props){
        super(props)
        this.state = {
        }
    }

    componentDidMount(){
        const { match: { params: { id, type } } } = this.props;
        console.log(type);
        this.context.xlsx(id, async (isWork,data)=>{
            if(!isWork){
                window.close();
                return;
            }

            const metrics = calculateMetricsFromReport(data.principle, data.guideline, data.report);
            let xlsxData = null;
            let filename = null
            if(type == 'details'){
                xlsxData = xlsxDetailsByMetrics(metrics)
                filename ='details.xlsx'
            }else if(type == 'summary'){
                xlsxData = xlsxSummaryByMetrics(metrics)
                filename ='summary.xlsx'
            }
            else{
                window.close();
                return;
            }

            let resultBlob = await writeXlsxFile(xlsxData, {
                filePath: filename
              })
              const url = URL.createObjectURL(resultBlob);
              const a = document.createElement('a');
              a.href = url;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.close();
              return;
        })
    }

    render() {
        return (<div></div>);
    }

}
XlsxReport.contextType = GlobalStoreContext;
export default XlsxReport;