import React,{useContext, useCallback} from "react"
import PickupReportGuidelinesButton from "../Forms/PickupReportGuidelinesButton";
import { GlobalStoreContext } from 'js/Context/UseGlobalStoreContext';

const PickupGuidelines = ({reportId,personSession,setLoading,disableLoading}) => {
    const {loadReportItem} = useContext(GlobalStoreContext);

    const reloadReport = useCallback(()=>{
        loadReportItem(personSession.session.id,false,reportId)
    },[reportId,personSession])

    return(
    <section className="edit-report-guidelines">
        <PickupReportGuidelinesButton
            reportId={reportId}
            personSession={personSession}
            reloadReport={reloadReport}
            setLoading={setLoading}
            disableLoading={disableLoading}
        />
    </section>
    );
}


export default PickupGuidelines;