/**
 * HTML Link.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * Link Class
 */
class Link extends Component {
    static propTypes = {
        external: PropTypes.bool,
        href: PropTypes.string,
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
    };

    static defaultProps = {
        external: false,
        href: null,
        label: null,
    };

    /**
     * Render the link.
     *
     * @returns {React.node}
     */
    render() {
        const { external, href, label, ...passThrough } = this.props;

        // false positive
        /* eslint-disable react/jsx-no-target-blank */
        return (
            <a
                title={label}
                {...passThrough}
                href={href}
                target={external ? '_blank' : null}
                rel={external ? 'noreferrer noopener' : null}
            >
                {label}
            </a>
        );
    }
}

export default Link;
