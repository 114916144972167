/**
 * Manage User Item.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Item from 'js/Components/PageHeader/Navigation/Raw/Item';
import UserContext from 'js/Context/UserContext';

/**
 * Manage User Item class.
 */
class ManageUserItem extends Component {
    /**
     * Render manage user item.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <UserContext.Consumer>
                {({ hasPermission }) => {
                    if (hasPermission('manage.people')) {
                        return (
                            <Item
                                href="/manage-users"
                                label="Manage Users"
                            />
                        );
                    }

                    return null;
                }}
            </UserContext.Consumer>
        );
    }
}

export default ManageUserItem;
