/**
 * Define the structure of a report guideline in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

const guidelinePropType = PropTypes.shape({
    complianceLevel: PropTypes.string,
    diagnosticLevel: PropTypes.number,
    displayName: PropTypes.string,
    principle: PropTypes.string,
    shortName: PropTypes.string,
});

export default guidelinePropType;
