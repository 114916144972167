import { useCallback,useState } from "react"
import useHttp from "./useHttp"
import apiConfig from 'js/config/api';

let working = false;
const useReportViolation = ()=>{
    const {isLoading, error, sendRequest} = useHttp()

    const addGlobalViolation= useCallback((violationId, sessionId, callback)=>{
        if(working === false)
        {
            working = true;
            sendRequest({
                url: `${apiConfig}globalviolation?sessionId=${sessionId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'post',
                body:{
                    violation: violationId
                }
            },
            (data)=>{
                callback(true,data)
                working = false
            },
            (e)=>{
                working = false
                callback(false, e)
            }

        )
        }

    },[isLoading])

    const syncGlobalViolation = useCallback((violationId,sessionId,callback)=>{

        if(working === false )
        {
            working = true;

            sendRequest({
                url: `${apiConfig}globalviolation/${violationId}?sessionId=${sessionId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'post',
                body:{}
            },
            (result)=>{
                working = false
                callback(true,result);
            },
            (e)=>{
                working = false
                callback(false, e)
            },
            false
        )
        }
    },[])


    const deleteGlobalViolation = useCallback((violationId,sessionId,callback)=>{
        if(working === false)
        {
            working = true;

            sendRequest({
                url: `${apiConfig}globalviolation/${violationId}?sessionId=${sessionId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'delete',
            },
            (data)=>{
                callback(true,data);
                working = false
            },
            (e)=>{
                working = false
                callback(false, e)
            },
            false
        )}

    },[])


    return {
        isLoadingReportViolation:isLoading,
        isErrorReportViolation: error,
        deleteGlobalViolation,
        syncGlobalViolation,
        addGlobalViolation,
    }
}

export default useReportViolation;