/**
 * Page Wrapper.
 *
 * Page Wrapper adds navigation inside the react router so
 * that the current page can be highlighted automatically.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import childrenPropType from 'js/PropTypes/children';
import Footer from 'js/Components/PageFooter/Footer';
import Egg from 'js/Components/Utils/Egg';
import Header from 'js/Components/PageHeader/Header';

/**
 * Page wrapper for navigation highlighting.
 */
class PageWrapper extends Component {
    static propTypes = {
        children: childrenPropType,
    };

    static defaultProps = {
        children: null,
    };

    /**
     * Render page wrapper.
     * Inject page elements that are repetitive like navigation/footer.
     *
     * @returns {React.node}
     */
    render() {
        const { children, personSession } = this.props;
        return (
          <>
            <Header personSession={personSession} />
            <main className="page-wrapper">{children}</main>
            <Footer />
            <Egg />
          </>
        );
    }
}

export default PageWrapper;
