/**
 * Front end Company Settings page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Breadcrumb from 'js/Components/Breadcrumb/Breadcrumb';
import CompanyInfoForm from 'js/Components/Forms/CompanyInfoForm';
import PageWrapper from 'js/Components/Pages/PageWrapper';
import SettingsBlock from 'js/Components/Blocks/SettingsBlock';
import OrganizationItem from '../Api/OrganizationItem';
import PagePermissionAlert from 'js/Components/Alert/PagePermissionAlert';
import UserContext from 'js/Context/UserContext';

/**
 * Front end Company Settings page.
 */
class CompanySettings extends Component {
    /**
     * Render Company Settings page.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <PageWrapper
                description="Settings"
                title="Settings"
            >
                <Breadcrumb
                    label="Company Settings"
                    description="View and update your company details."
                />
                <SettingsBlock
                    label="Basic Info"
                >
                    <UserContext.Consumer>
                        { ({ hasPermission, personSession }) => {
                            if (!hasPermission('manage.organization')) {
                                return (
                                    <PagePermissionAlert />
                                );
                            }

                            return (
                                <OrganizationItem
                                    id={personSession.person.organizationId}
                                    sessionId={personSession.session.id}
                                >
                                    <CompanyInfoForm />
                                </OrganizationItem>
                            );
                        }}
                    </UserContext.Consumer>
                </SettingsBlock>
            </PageWrapper>
        );
    }
}

export default CompanySettings;
