/**
 * Person Table.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddPersonButton from 'js/Components/Forms/AddPersonButton';
import DeletePersonButton from 'js/Components/Forms/DeletePersonButton';
import EditPersonButton from 'js/Components/Forms/EditPersonButton';
import FilterTable from 'js/Components/Table/FilterTable';
import organizationPropType from 'js/PropTypes/organization';
import personPropType from 'js/PropTypes/person';
import rolePropType from 'js/PropTypes/role';
import Tag from 'js/Components/Tag/Tag';
import noop from 'js/noop';

/**
 * Filter and Sortable Person Table.
 */
class PersonTable extends Component {
    static propTypes = {
        isGlobalAdmin: PropTypes.bool,
        onAddPerson: PropTypes.func,
        onDeletePerson: PropTypes.func,
        onUpdatePerson: PropTypes.func,
        organizations: PropTypes.arrayOf(organizationPropType),
        people: PropTypes.arrayOf(personPropType),
        roles: PropTypes.arrayOf(rolePropType),
    };

    static defaultProps = {
        isGlobalAdmin: false,
        onAddPerson: noop,
        onDeletePerson: noop,
        onUpdatePerson: noop,
        organizations: [],
        people: [],
        roles: [],
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {};

        // Efficient early binding
        this.filterPeople = this.filterPeople.bind(this);
        this.renderActions = this.renderActions.bind(this);
        this.renderName = this.renderName.bind(this);
        this.renderRoles = this.renderRoles.bind(this);
        this.renderForceChange = this.renderForceChange.bind(this);
        this.roleSort = this.roleSort.bind(this);
    }

    /**
     * Person filter function.
     *
     * @param {string} filter
     * @param {object} person
     *
     * @returns {boolean}
     */
    filterPeople(filter, person) {
        const insensitiveFilter = filter.toLowerCase();

        if (person && person.firstName && person.organizationName) {
            return (
                person.firstName.toLowerCase().indexOf(insensitiveFilter) !== -1
                || person.lastName.toLowerCase().indexOf(insensitiveFilter) !== -1
                || person.organizationName.toLowerCase().indexOf(insensitiveFilter) !== -1
            );
        }

        // Default to hiding falsy people.
        return false;
    }

    /**
     * Render person table row actions.
     *
     * @returns {React.node}
     */
    renderActions(action, person) {
        const { onDeletePerson, onUpdatePerson, organizations, roles } = this.props;

        return (
            <div className="person-actions">
                <EditPersonButton
                    onEditPerson={onUpdatePerson}
                    organizations={organizations}
                    person={person}
                    roles={roles}
                />
                <DeletePersonButton
                    onDeletePerson={onDeletePerson}
                    person={person}
                />
            </div>
        );
    }

    /**
     * Render person name cell.
     *
     * @returns {React.node}
     */
    renderName(name, person) {
        const { firstName, lastName } = person;

        return `${firstName} ${lastName}`;
    }

    /**
     * Render person roles cell.
     *
     * @returns {React.node}
     */
     renderRoles(userRoles) {
        const { roles } = this.props;

        if (!userRoles.length > 0) {
            return null;
        }
        

        return userRoles.map((userRole) => {
            const activeRole = roles.find((role) => {
                return role.id === userRole.id;
            });

            if (activeRole) {
                const roleName = activeRole.name;

                return (
                    <Tag
                        key={userRole.id}
                    >
                        {roleName}
                    </Tag>
                );
            }

            return null;
        });
    }

    /**
     * Render checkbox 
     */
    renderForceChange(value){
        return(<div style={{textAlign:"center"}}><input style={{minWidth:"auto"}} type="checkbox" checked={value} disabled/></div>)
    }

    /**
     * Sort transformation for roles.
     * Just sort by the first role if more than one.
     *
     * @returns {string}
     */
    roleSort(roles) {
        if (!roles.length > 0) {
            return roles[0];
        }

        return roles;
    }

    /**
     * Render person table.
     *
     * @returns {React.node}
     */
    render() {
        let { isGlobalAdmin, onAddPerson, organizations, people, roles } = this.props;

        // Ugly hack to add actions to the person list
        const peopleWithAction = people.map((person) => {
            const updatedPerson = person;

            // This is just a placeholder to render cell correctly.
            updatedPerson.actions = 'edit delete';
            updatedPerson.organizationName = organizations.find(x => x.id === person.organizationId).name;
            updatedPerson.organizationType = organizations.find(x => x.id === person.organizationId).type;

            updatedPerson.organizationType =
              updatedPerson.organizationType.charAt(0).toUpperCase() +
              updatedPerson.organizationType.slice(1);

            return updatedPerson;
        });

        const sortedHeaders = [
            'firstName',
            'organizationName',
            'organizationType',
            'roles',
            "forceChange",
            'actions',
        ];


        return (
            <FilterTable
                className="person-table"
                data={peopleWithAction}
                dataTransformations={{
                    actions: this.renderActions,
                    roles: this.renderRoles,
                    firstName: this.renderName,
                    forceChange:this.renderForceChange
                }}
                filterFunction={this.filterPeople}
                headers={{
                    firstName: 'Name',
                    organizationName: 'Company',
                    organizationType: 'Company Type',
                    roles: 'Role',
                    forceChange: "Force Change Password",
                    actions: 'Actions',
                }}
                headerSort={sortedHeaders}
                sortTransformations={{
                    roles: this.roleSort,
                }}
                striped
            >
                <AddPersonButton
                    onAddPerson={onAddPerson}
                    organizations={organizations}
                    roles={roles}
                />
            </FilterTable>
        );
    }
}

export default PersonTable;
