import { useContext } from "react";
import { GlobalStoreContext } from "js/Context/UseGlobalStoreContext";

const DropDownFilter = (props) => {
    const {reportlist:reports} = useContext(GlobalStoreContext);
    const {search} = props;
    const ReportOrganizations = reports !== undefined && reports !== null ? reports.map(report => (report.organization.name)) : null
    const uniqueOrganizations = ReportOrganizations !== undefined && ReportOrganizations !== null ? ReportOrganizations.filter((v, i, a) => a.indexOf(v) === i) : null;
    
    
    return (
      <div className="report-block-wrapper" style={{maxWidth:"960px",margin:"0 auto 20px",justifyContent:"flex-start"}}>
        <label className="report-block-filter-1">Select Organization:</label>
        <select
          onChange={(e) => search(e.target.value)}
          className="report-block-filter"
        >
          <option>View All</option>
          {uniqueOrganizations !== null
            ? uniqueOrganizations.map((organization) => (
                <option key={organization} value={organization}>
                  {organization}
                </option>
              ))
            : null}
        </select>
      </div>
    );
}
 
export default DropDownFilter;