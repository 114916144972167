/**
 * Add person button.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddEditPersonModal from 'js/Components/Modal/AddEditPersonModal';
import Button from 'js/Components/Forms/Raw/Button';
import noop from 'js/noop';
import organizationPropType from 'js/PropTypes/organization';
import rolePropType from 'js/PropTypes/role';
import UserContext from 'js/Context/UserContext';

/**
 * Add person button class.
 */
class AddPersonButton extends Component {
    static propTypes = {
        onAddPerson: PropTypes.func,
        organizations: PropTypes.arrayOf(organizationPropType),
        roles: PropTypes.arrayOf(rolePropType),
    };

    static defaultProps = {
        onAddPerson: noop,
        organizations: [],
        roles: [],
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
     constructor(props) {
        super(props);

        this.state = {
            addModalOpen: false,
        };

        // Efficient early binding.
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onAddPerson = this.onAddPerson.bind(this);
        this.onShowModal = this.onShowModal.bind(this);
    }

    /**
     * Event handler for add after modal save.
     *
     * @returns {void}
     */
     onAddPerson(newPerson) {
        const { onAddPerson } = this.props;

        if (onAddPerson) {
            onAddPerson(newPerson);
        }

        this.onCloseModal();
    }

    /**
     * Event handler for modal close.
     *
     * @returns {void}
     */
     onCloseModal() {
        this.setState({
            addModalOpen: false,
        });
    }

    /**
     * Event handler for modal show.
     *
     * @returns {void}
     */
     onShowModal() {
        this.setState({
            addModalOpen: true,
        });
    }

    /**
     * Conditionally render the add/edit person modal.
     *
     * @returns {React.node|null}
     */
    renderModal() {
        const { organizations, roles } = this.props;
        const { addModalOpen } = this.state;

        if (addModalOpen) {
            return (
                <AddEditPersonModal
                    action="add"
                    onPersonSaved={this.onAddPerson}
                    onClose={this.onCloseModal}
                    open={addModalOpen}
                    organizations={organizations}
                    roles={roles}
                />
            );
        }

        return null;
    }

    /**
     * Render Add user button.
     *
     * @returns {React.node|null}
     */
    render() {
        return (
            <UserContext.Consumer>
                {({ hasPermission }) => {
                    if (hasPermission('manage.people')) {
                        return (
                            <>
                                <Button
                                    onClick={this.onShowModal}
                                    primary
                                >
                                    Add User
                                </Button>
                                {this.renderModal()}
                            </>
                        );
                    }

                    return null;
                }}
            </UserContext.Consumer>
        );
    }
}

export default AddPersonButton;
