/**
 * Page Footer.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from "react";

import Brand from "js/Components/PageFooter/Brand";
// import Item from "js/Components/PageHeader/Navigation/Raw/Item";
import PrivacyItem from "js/Components/PageFooter/PrivacyItem";
import TermsItem from "js/Components/PageFooter/TermsItem";
import ButtonItem from "../PageHeader/Navigation/ButtonItem";
import BackToTop from "./BackToTop";
/**
 * Page Footer class.
 */

function onClick(e) {
  window.location.href = `mailto:ada-support@scalesology.com`;
  e.preventDefault();
}
class Footer extends Component {
  /**
   * Render page footer.
   *
   * @returns {React.node}
   */
  render() {
    return (
      <footer>
        <nav aria-label="Footer Navigation">
          <ul className="footer-nav-list">
            <Brand />
            <PrivacyItem />
            <TermsItem />
            <ButtonItem label="Support" onClick={onClick} />
            {/* <BackToTop /> */}
          </ul>
        </nav>
      </footer>
    );
  }
}

export default Footer;
