/**
 * Page Header Navigation item.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Link from 'js/Components/Link';

/**
 * Page Header Navigation item class.
 */
class Item extends Component {
    static propTypes = {
        href: PropTypes.string,
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
    };

    static defaultProps = {
        href: null,
        label: null,
    };

    /**
     * Render page header navigation item.
     *
     * @returns {React.node}
     */
    render() {
        const { href, label, download, ...passThrough } = this.props;

        return (
            <li>
                <Link
                    href={href}
                    label={label}
                    {...passThrough}
                    download = {download ? download : false}
                />
            </li>
        );
    }
}

export default Item;
