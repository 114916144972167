/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

const nameAndViolations = {
    slug: PropTypes.string,
    name: PropTypes.string.isRequired,
    violations: PropTypes.number.isRequired,
    severity: PropTypes.number.isRequired,
    severityLabel: PropTypes.string.isRequired,
};
const passedAndTotal = {
    passed: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

const metricsShape = PropTypes.shape({
    totalGuidelines: PropTypes.shape(passedAndTotal),
    principles: PropTypes.arrayOf(PropTypes.shape({
        ...nameAndViolations,
        ...passedAndTotal,
        pages: PropTypes.arrayOf(
            PropTypes.shape(nameAndViolations),
        ),
        guidelines: PropTypes.arrayOf(
            PropTypes.shape({
                ...nameAndViolations,
                pages: PropTypes.arrayOf(
                    PropTypes.shape(nameAndViolations),
                ),
            }),
        ).isRequired,
    })).isRequired,
    severity: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        violations: PropTypes.number.isRequired,
    })),
});

export default metricsShape;
