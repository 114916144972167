/**
 * Sign Up page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Link from 'js/Components/Link';
import SignInLogo from 'img/sign-in-logo.png'
import SignUpForm from 'js/Components/Forms/SignUpForm';

/**
 * Sign Up page.
 */
class SignUp extends Component {
    /**
     * Render the sign up page.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <div className="signin-container">
                <img src={SignInLogo} alt="Scalesology Logo" />
                <h1>Sign up for Scalesology</h1>
                <SignUpForm />
                <div className="password-reset password-reset-back">
                    <Link
                        className="password-reset-link"
                        label="Back to sign in"
                        href="/sign-in"
                    />
                </div>
            </div>
        );
    }
}

export default SignUp;
