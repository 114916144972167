/**
 * Scroll back to top when the route changes.
 * This is to mimic normal browser behavior in a
 * single page application.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router';

import childrenPropType from 'js/PropTypes/children';

/**
 * Component to scroll back to top when route changes.
 */
class ScrollToTop extends Component {
    static propTypes = {
        children: childrenPropType,
        location: PropTypes.object,
    };

    static defaultProps = {
        children: null,
        location: {},
    };

    /**
     * Scroll back to top when router location changes.
     *
     * @returns {void}
     */
    componentDidUpdate(prevProps) {
        const { location } = this.props;

        if (location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    /**
     * This is a wrapper, so return children.
     *
     * @returns {React.node}
     */
    render() {
        const { children } = this.props;

        return children;
    }
}

export default withRouter(ScrollToTop);
