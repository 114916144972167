/**
 * Edit Report guideline details.
 *
 * @author Ryan Johnston <jason.lu@scalesology.com>
 */

import { useMemo,useCallback } from "react";
import { FaMinusCircle } from "react-icons/fa";

/**
 * Edit report guideline details.
 */

const Details = ({details,setDetails})=>{

    const handleChange = useCallback((e)=>{

        let name = e.target.name;
        let index = e.target.dataset.index;
        let value = e.target.value;
        if(`${index}` === `${details.length}`){
            let newDetail = {
                context:"",
                message:"",
                selector:""
            }
            newDetail[name] = value
            setDetails([
                ...details,
                newDetail
            ])
        }
        else{
            let newDetail = [...details];
            newDetail[index][name] = value

            setDetails(newDetail)
        }
    },[details,setDetails])

    const handleDelete = useCallback((e)=>{
        let index = e.target.dataset.index;
        const newDetails = details.filter((_,i)=>{
            return i != index
        })
        setDetails(newDetails)
    },[details])

    const detailitems = useMemo(()=>{ 
        let list = [];
        let next = 0;
        details.map((item,index)=>{

            list.push(
            <tr key={index}>
                <td><input data-index={index} name="context" type="text" placeholder="Context" value={item['context']} onChange={handleChange} /></td>
                <td><input data-index={index} name="message"  type="text" placeholder="Message" value={item['message']} onChange={handleChange}  /></td>
                <td><input data-index={index} name="selector" type="text" placeholder="Selector" value={item['selector']} onChange={handleChange}  /></td>
                <td><button data-index={index} onClick={handleDelete}><FaMinusCircle style={{color:"#e51c24" }} size={16} /></button></td>
            </tr>) 
            next++;     
        });
        list.push(
            <tr key={next}>
                <td><input data-index={next} name="context" type="text" placeholder="Context" value=""  onChange={handleChange}  /></td>
                <td><input data-index={next} name="message" type="text" placeholder="Message"  value="" onChange={handleChange} /></td>
                <td><input data-index={next} name="selector" type="text" placeholder="Selector" value="" onChange={handleChange} /></td>
            </tr>)
        return(
        <table>
            <thead>
                <tr>
                    <th>Context</th>
                    <th>Message</th>
                    <th>Selector</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                {list}
            </tbody>
        </table>)
    
    },[details])

    return(<div className="edit-report-guideline-details">
        {detailitems}
    </div>)
}

export default Details;
