/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import BaseChart from 'chart.js';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class Chart extends PureComponent {
    static propTypes = {
        config: PropTypes.shape({
            data: PropTypes.objectOf(PropTypes.any).isRequired,
            type: PropTypes.string.isRequired,
            options: PropTypes.objectOf(PropTypes.any),
        }).isRequired,
        id: PropTypes.string.isRequired,
    }

    componentDidMount() {
        const { id, config } = this.props;
        const context = document.getElementById(id).getContext('2d');

        // Render the chart and assign the class variable (to keep ESLint from complaining)
        this.chart = new BaseChart(context, config);
    }

    render() {
        const { id } = this.props;

        return (
            <canvas id={id} />
        );
    }
}

export default Chart;
