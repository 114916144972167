/**
 * Client Certificate Item.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Item from 'js/Components/PageHeader/Navigation/Raw/Item';
import UserContext from 'js/Context/UserContext';

/**
 * Client Certificate Item class.
 */
class ClientCertificateItem extends Component {
    /**
     * Render client certificate item.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <UserContext.Consumer>
                {({ hasPermission }) => {
                    if (hasPermission('manage.organizations')) {
                        return (
                          <Item
                            external
                            href="/img/wcag2.0AA-scalesologycert.png"
                            label="Download WCAG 2.0 AA Website Certified Logo"
                            download
                          />
                        );
                    }

                    return null;
                }}
            </UserContext.Consumer>
        );
    }
}

export default ClientCertificateItem;
