/**
 * Sign Up block.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Link from 'js/Components/Link';

/**
 * Sign Up block class.
 */
class SignUpBlock extends Component {
    /**
     * Render the Sign Up block.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <div className="sign-up-block">
                New user?
                <Link
                    href="/sign-up"
                    label="Create an account."
                />
            </div>
        );
    }
}

export default SignUpBlock;
