/**
 * Reusable Markdown TextArea.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import { Editor } from '@toast-ui/react-editor';
import React, { Component } from 'react';

/**
 * Markdown TextArea class.
 */
class MarkdownTextArea extends Component {
    /**
     * Render the Markdown TextArea.
     *
     * @returns {React.node}
     */
    render() {
        const {initialValue,onChangeSummary} =  this.props;
        return (
            <Editor
                initialValue={initialValue===null?"":initialValue}
                ref={this.props.editorRef}
                previewStyle="vertical"
                height="600px"
                initialEditType="markdown"
                useCommandShortcut={true}
                hideModeSwitch={true}
                onChange={onChangeSummary}
                toolbarItems={[
                    ['heading', 'bold', 'italic', 'strike'],
                    ['hr', 'quote'],
                    ['ul', 'ol', 'task', 'indent', 'outdent'],
                    ['table', 'link'],
                    ['code', 'codeblock']
                ]}
            />
        );
    }
}

export default MarkdownTextArea;
