import { useCallback,useState } from "react"
import useHttp from "./useHttp"
import apiConfig from 'js/config/api';

let working = false;
const useReportList = ()=>{
    const {isLoading, error, sendRequest} = useHttp()
    const [reportlist, setReportList] = useState([]);


    const loadReportList = useCallback((sessionId, forceload=false)=>{
        if(working === false || forceload)
        {
            working = true;
            sendRequest({
                url: `${apiConfig}report/list?sessionId=${sessionId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
            },
            (data)=>{
                setReportList(data)
                working = false
            },
            ()=>{
                working = false
            }

        )
        }

    },[isLoading])


    return {
        isLoadingReportList: isLoading,
        isErrorReportList: error,
        reportlist:reportlist,
        loadReportList: loadReportList
    }
}

export default useReportList;