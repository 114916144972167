/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { PureComponent } from 'react';

import Chart from 'js/Components/Report/Chart';
import metricsShape from 'js/PropTypes/metricsShape';
import severityColors from 'js/config/severityColors';
import severityLevels from 'js/config/severityLevels';

class OverviewGraphs extends PureComponent {
    static propTypes = {
        metrics: metricsShape.isRequired,
    };

    calculateSeveritySummary() {
        const { metrics } = this.props;
        const data = severityLevels.map(() => 0);

        metrics.principles.forEach(({ guidelines }) => {
            guidelines.forEach(({ severity, applied }) => {
                if(applied){
                    data[severity] += 1;
                } else {
                    data[6] += 1;
                }
               
            });
        });

        return {
            type: 'doughnut',
            data: {
                labels: severityLevels,
                datasets: [{
                    data,
                    backgroundColor: severityColors,
                }],
            },
            options: {
                title: {
                    display: true,
                    fontSize: 16,
                    fontStyle: 'normal',
                    text: 'Guideline Severity Summary',
                },
                legend: {
                    position: 'bottom',
                },
                maintainAspectRatio: false,
                cutoutPercentage: 75,
            },
        };
    }

    calculateNumberOfViolations() {
        const { metrics: { severity } } = this.props;
        const datasets = severity.slice(1, 6).map(({ name, violations }, index) => {
            // Fun little workaround for Chart.js and labels and legends
            const data = severity.slice(1, 6).map(() => 0);
            data[index] = violations;

            return {
                label: name,
                data,
                backgroundColor: severityColors[index + 1],
            };
        });

        return {
            type: 'horizontalBar',
            data: {
                labels: severityLevels.slice(1),
                datasets,
            },
            options: {
                title: {
                    display: true,
                    fontSize: 16,
                    fontStyle: 'normal',
                    text: 'Number of Violations',
                },
                legend: { position: 'bottom' },
                scales: {
                    xAxes: [{ stacked: true }],
                    yAxes: [{ stacked: true }],
                },
            },
        };
    }

    render() {
        return (
            <section className="overview-website-graphs">
                <figure className="chart chart-severity-summary">
                    <div className="chart-container">
                        <Chart config={this.calculateSeveritySummary()} id="chart-severity-summary" />
                    </div>
                    <figcaption>Figure 1</figcaption>
                </figure>
                <figure className="chart chart-number-of-violations">
                    <Chart config={this.calculateNumberOfViolations()} id="chart-number-of-violations" />
                    <figcaption>Figure 2</figcaption>
                </figure>
            </section>
        );
    }
}

export default OverviewGraphs;
