/**
 * Delete person button.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'js/Components/Forms/Raw/Button';
import ConfirmModal from 'js/Components/Modal/Raw/ConfirmModal';
import noop from 'js/noop';
import personPropType from 'js/PropTypes/person';
import UserContext from 'js/Context/UserContext';

/**
 * Delete person button class.
 */
class DeletePersonButton extends Component {
    static propTypes = {
        onDeletePerson: PropTypes.func,
        person: personPropType,
    };

    static defaultProps = {
        onDeletePerson: noop,
        person: {},
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
     constructor(props) {
        super(props);

        this.state = {
            confirmOpen: false,
        };

        // Efficient early binding.
        this.onCloseConfirm = this.onCloseConfirm.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.onShowConfirm = this.onShowConfirm.bind(this);
    }

    /**
     * Event handler for confirmation modal close.
     *
     * @returns {void}
     */
     onCloseConfirm() {
        this.setState({
            confirmOpen: false,
        });
    }

    /**
     * Event handler for delete after confirmation modal.
     *
     * @returns {void}
     */
    onConfirmDelete() {
        const { onDeletePerson, person } = this.props;

        if (onDeletePerson) {
            onDeletePerson(person.id);
        }

        this.onCloseConfirm();
    }

    /**
     * Event handler for confirmation modal show.
     *
     * @returns {void}
     */
    onShowConfirm() {
        this.setState({
            confirmOpen: true,
        });
    }

    /**
     * Render Delete person button.
     *
     * @returns {React.node}
     */
    render() {
        const { person } = this.props;
        const { confirmOpen } = this.state;
        const personName = person.firstName || person.lastName ? `${person.firstName} ${person.lastName}` : 'this user';

        return (
            <>
                <UserContext.Consumer>
                    {({ hasPermission, personSession }) => {
                        const isSignInUser = personSession.person.id === person.id;

                        if (hasPermission('manage.people')) {
                            return (
                                <Button
                                    disabled={isSignInUser}
                                    onClick={this.onShowConfirm}
                                    title={isSignInUser ? 'You can not delete your user.' : null}
                                >
                                    Delete
                                </Button>
                            );
                        }

                        return null;
                    }}
                </UserContext.Consumer>
                <ConfirmModal
                    message={`Are you sure you want to delete ${personName}?`}
                    open={confirmOpen}
                    onClose={this.onCloseConfirm}
                    onConfirm={this.onConfirmDelete}
                />
            </>
        );
    }
}

export default DeletePersonButton;
