/**
 * Password Reset Page Component.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Link from 'js/Components/Link';
import SignInLogo from 'img/sign-in-logo.png'
import ResetPasswordForm from 'js/Components/Forms/ResetPasswordForm';

/**
 * Password Reset Page Component.
 */
class ResetPassword extends Component {
    /**
    * Render password reset page.
    *
    * @returns {React.node}
    */
    render() {
        return (
            <div className="signin-container">
                <img src={SignInLogo} alt="Scalesology Logo" />
                <h1>Password Reset</h1>
                <ResetPasswordForm />
                <div className="password-reset password-reset-back">
                    <Link
                        className="password-reset-link"
                        label="Back to sign in"
                        href="/sign-in"
                    />
                </div>
            </div>
        );
    }
}

export default ResetPassword;
