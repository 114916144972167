/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import HeadingAnchor from 'js/Components/Utils/HeadingAnchor';

class Heading extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        level: PropTypes.number.isRequired,
        id: PropTypes.string,
        sectionId: PropTypes.string,
    };

    static defaultProps = {
        id: null,
        sectionId: true,
    };

    render() {
        const {
            children,
            id,
            level,
            sectionId,
        } = this.props;

        return (
            <HeadingAnchor hrefId={sectionId} id={id} level={level}>
                {children}
            </HeadingAnchor>
        );
    }
}

function getHeading(level) {
    return function Wrapper(props) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Heading {...props} level={level} />;
    };
}

const H1 = getHeading(1);
const H2 = getHeading(2);
const H3 = getHeading(3);
const H4 = getHeading(4);
const H5 = getHeading(5);
const H6 = getHeading(6);

export {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    Heading as default,
};
