/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import HoverableAnchor from 'js/Components/Utils/HoverableAnchor';

class HeadingAnchor extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        hrefId: PropTypes.string.isRequired,
        level: PropTypes.number.isRequired,

        id: PropTypes.string,
    };

    static defaultProps = {
        id: null,
    };

    render() {
        const {
            children,
            hrefId,
            id,
            level,
        } = this.props;
        const HeadingClass = `h${level}`;

        return (
            <HeadingClass id={id}>
                {children}
                <HoverableAnchor id={hrefId || id} />
            </HeadingClass>
        );
    }
}

export default HeadingAnchor;
