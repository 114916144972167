/**
 * Organization Table.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddOrganizationButton from 'js/Components/Forms/AddOrganizationButton';
import DeleteOrganizationButton from 'js/Components/Forms/DeleteOrganizationButton';
import EditOrganizationButton from 'js/Components/Forms/EditOrganizationButton';
import FilterTable from 'js/Components/Table/FilterTable';
import organizationPropType from 'js/PropTypes/organization';
import Tag from 'js/Components/Tag/Tag';
import noop from 'js/noop';

/**
 * Filter and Sortable Organization Table.
 */
class OrganizationTable extends Component {
    static propTypes = {
        onAddOrganization: PropTypes.func,
        onDeleteOrganization: PropTypes.func,
        onUpdateOrganization: PropTypes.func,
        organizations: PropTypes.arrayOf(organizationPropType),
    };

    static defaultProps = {
        onAddOrganization: noop,
        onDeleteOrganization: noop,
        onUpdateOrganization: noop,
        organizations: [],
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {};

        // Efficient early binding
        this.filterOrganizations = this.filterOrganizations.bind(this);
        this.renderActions = this.renderActions.bind(this);
    }

    /**
     * Organization filter function.
     *
     * @param {string} filter
     * @param {object} organization
     *
     * @returns {boolean}
     */
    filterOrganizations(filter, organization) {
        const insensitiveFilter = filter.toLowerCase();

        if (organization && organization.name) {
            return (
                organization.name.toLowerCase().indexOf(insensitiveFilter) !== -1
            );
        }

        // Default to hiding falsy people.
        return false;
    }

    /**
     * Render organization table row actions.
     *
     * @returns {React.node}
     */
    renderActions(action, organization) {
        const { onDeleteOrganization, onUpdateOrganization,organizations } = this.props;

        return (
            <div className="organization-actions">
                <EditOrganizationButton
                    onEditOrganization={onUpdateOrganization}
                    organization={organization}
                    organizations={organizations}
                />
                <DeleteOrganizationButton
                    onDeleteOrganization={onDeleteOrganization}
                    organization={organization}
                />
            </div>
        );
    }

    /**
     * Render organization roles cell.
     *
     * @returns {React.node}
     */
     renderRoles(userRoles) {
        const { roles } = this.props;

        if (!userRoles.length > 0) {
            return null;
        }

        return userRoles.map((userRole) => {
            const activeRole = roles.find((role) => {
                return role.id === userRole.id;
            });

            if (activeRole) {
                const roleName = activeRole.name;

                return (
                    <Tag
                        key={userRole.id}
                    >
                        {roleName}
                    </Tag>
                );
            }

            return null;
        });
    }

    /**
     * Render organization table.
     *
     * @returns {React.node}
     */
    render() {
        let { onAddOrganization, organizations } = this.props;


        // Ugly hack to add actions to the organization list
        const organizationsWithAction = organizations.map((organization) => {
            const updatedOrganization = organization;

            // This is just a placeholder to render cell correctly.
            updatedOrganization.actions = 'edit delete';
            updatedOrganization.type =
              updatedOrganization.type.charAt(0).toUpperCase() +
              updatedOrganization.type.slice(1);

            return updatedOrganization;
        });

        return (
            <FilterTable
                className="organization-table"
                data={organizationsWithAction}
                dataTransformations={{
                    actions: this.renderActions,
                }}
                filterFunction={this.filterOrganizations}
                headers={{
                    // id: 'ID',
                    name: 'Name',
                    type: 'Type',
                    actions: 'Actions',
                }}
                headerSort={[
                    // 'id',
                    'name',
                    'type',
                    'actions',
                ]}
                striped
            >
                <AddOrganizationButton
                    onAddOrganization={onAddOrganization}
                    organizations={organizations}
                />
            </FilterTable>
        );
    }
}

export default OrganizationTable;
