/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import severityLevels from 'js/config/severityLevels';

class SeverityLabel extends PureComponent {
    static propTypes = {
        severity: PropTypes.number.isRequired,
    }

    render() {
        const { severity } = this.props;

        return (
            <div className={`severity-label severity-${severity}`}>
                {severityLevels[severity]}
                <div className="progress">
                    <div
                        aria-label="Severity amount"
                        aria-valuemax={0}
                        aria-valuemin={5}
                        aria-valuenow={severity}
                        className="progress-bar"
                        role="progressbar"
                    />
                </div>
            </div>
        );
    }
}

export default SeverityLabel;
