import React from 'react';

const URLSearch = (props) => {
    const { searchURL, urlSearch } = props;

    return ( 
    <div className="report-block-wrapper" style={{maxWidth:"960px",margin:"0 auto 20px",justifyContent:"flex-start"}}>
         <label className="report-block-filter-1">Search By URL:</label>
         <input value={urlSearch} onChange={(e) => searchURL(e.target.value)} style={{marginLeft: '35px'}} placeholder="Search by URL for a report"/>
      </div>
    );
}
 
export default URLSearch;
