/**
 * Define the structure of a report in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

const reportPropType = PropTypes.shape({
    auditedOn: PropTypes.date,
    createdAt: PropTypes.date,
    guidelineCount: PropTypes.number, // needed from API
    guidelineTotal: PropTypes.number, // needed from API
    id: PropTypes.number,
    image: PropTypes.string,
    isFinalized: PropTypes.bool,
    organization: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    pageCount: PropTypes.number, // needed from API
    reportLevel: PropTypes.number,
    summary: PropTypes.string,
    updatedAt: PropTypes.date,
    website: PropTypes.string,
});

export default reportPropType;
