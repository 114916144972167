import React,{useState} from "react";
import ImportReportForm from "../Forms/ImportReportForm";
import Modal from 'js/Components/Modal/Raw/Modal';
import ModalBody from 'js/Components/Modal/Raw/ModalBody';
import ModalFooter from 'js/Components/Modal/Raw/ModalFooter';
import ModalHeader from 'js/Components/Modal/Raw/ModalHeader';
import Button from '../Forms/Raw/Button';

const ImportReportModal = ({onClose,open,reportId,personSession,setLoading,disableLoading,onCloseNoLoading})=>{

    
    const [success,setSuccess] = useState(false);

    return(
        <Modal
            className="request-import-report"
            onClose={onCloseNoLoading}
            open={open}
        >
            <ModalHeader>
                Import JSON report
            </ModalHeader>
            <ModalBody>
                <ImportReportForm
                    reportId={reportId}
                    personSession={personSession}
                    onClose={onClose}
                    setLoading={setLoading}
                    disableLoading={disableLoading}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={onCloseNoLoading}
                    title="Cancel"
                >
                {success?"OK":"Cancel"}
                </Button>
                {!success &&
                <Button
                    form="request-import-report"
                    primary
                    title="Request Audit"
                    type="submit"
                >
                    Import
                </Button>
                }
        </ModalFooter>
    </Modal>
    );
}

export default ImportReportModal;