/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { PureComponent } from 'react';

import DataStringAnchor from 'js/Components/Utils/DataStringAnchor';
import reportShape from 'js/PropTypes/reportShape';
import apiConfig from 'js/config/api';
class DetailedDataDownload extends PureComponent {
    static propTypes = {
        report: reportShape.isRequired,
    };

    formatReportForJsonDownload() {
        const { report: { auditDate, pages } } = this.props;

        // Compile detailed data into a more user-friendly format
        // Note that this provides detailed instances only if it is available
        const formattedPages = pages.map((page) => {
            const { url, name: pageName, violations: issues } = page;
            const violations = [];

            Object.keys(issues).forEach((principle) => {
                Object.keys(issues[principle]).forEach((guideline) => {
                    const {
                        complianceLevel,
                        tests: rawTests,
                    } = issues[principle][guideline];

                    rawTests.forEach(({ name: testName, violations: total, details }) => {
                        const test = { test: testName, total };

                        if (details !== undefined) {
                            test.instances = details;
                        }

                        violations.push({
                            principle,
                            guideline,
                            complianceLevel,
                            ...test,
                        });
                    });
                });
            });

            return { url, name: pageName, violations };
        });

        return { auditDate, pages: formattedPages };
    }

    formatReportForCsvDownload() {
        const { report: { pages } } = this.props;
        let violations = [];
        console.log(this.props.report);
        // Compile detailed data into a more user-friendly format
        // Note that this provides detailed instances only if it is available
        pages.forEach((page) => {
            const { violations: issues } = page;

            issues.forEach((issue) => {
                const { details } = issue;

                if (details !== undefined) {
                    violations = [
                        ...violations,
                        ...details.map((detail) => ({
                            Selector: detail.selector,
                            Context: detail.context,
                            'Violation Error Message': detail.message,
                        })),
                    ];
                }
            });
        });
        return violations;
    }

    renderDownloadLink() {
        const data = this.formatReportForCsvDownload();
        if(data.length === 0){
            //data.push({"No violations were found":""});
            return false;
        }
        const {uid} = this.props.report;
        return (
            <a
                download
                target="_blank"
                href={`${apiConfig}violation/csv/${uid}`}
            >
                here
            </a>
        );
    }

    render() {

        const downloadLinks = this.renderDownloadLink();
        if(downloadLinks === false)
        {
            return(<p></p>);
        }
        return (
            <p>
                For a full download of the detailed data, click
                {' '}
                {downloadLinks}
                .
            </p>
        );
    }
}

export default DetailedDataDownload;
