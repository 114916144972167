/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { PureComponent } from 'react';

import LanguageText from 'js/Components/Utils/LanguageText';
import metricsShape from 'js/PropTypes/metricsShape';
import OverviewGraphs from 'js/Components/Report/WebsiteOverview/OverviewGraphs';
import PrincipleGraphs from 'js/Components/Report/WebsiteOverview/PrincipleGraphs';
import reportShape from 'js/PropTypes/reportShape';
import SectionWithHeading from 'js/Components/Utils/SectionWithHeading';

class WebsiteOverview extends PureComponent {
    static propTypes = {
        metrics: metricsShape.isRequired,
        report: reportShape.isRequired,
    };

    render() {
        const { metrics, report } = this.props;

        return (
            <SectionWithHeading className="overview-website" heading="Website Overview" level={2}>
                <OverviewGraphs metrics={metrics} report={report} />
                <LanguageText languageKey="websiteOverviewGraphs" />
                <SectionWithHeading heading="WCAG Principles" level={3}>
                    <PrincipleGraphs metrics={metrics} />
                    <LanguageText languageKey="principlesGraphs" />
                </SectionWithHeading>
            </SectionWithHeading>
        );
    }
}

export default WebsiteOverview;
