/**
 * Loading Indicator.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FaSpinner } from 'react-icons/fa';

/**
 * Reusable component for loading indicator.
 */
class LoadingIndicator extends Component {
    static propTypes = {
        icon: PropTypes.node,
        label: PropTypes.string,
    };

    static defaultProps = {
        icon: (<FaSpinner size={48} />),
        label: null,
    };

    /**
     * Display the loading indicator.
     *
     * @returns {React.node}
     */
    render() {
        const { icon, label } = this.props;

        return (
            <div className="loading-indicator">
                {icon}
                <p className="loading-label">
                    {label}
                </p>
            </div>
        );
    }
}

export default LoadingIndicator;
