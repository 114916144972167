/**
* List API Call For Each Page
*
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import childrenPropType from 'js/PropTypes/children';
import LoadingIndicator from 'js/Components/LoadingIndicator';
import NetworkAlert from 'js/Components/Alert/NetworkAlert';
import { GlobalStoreContext } from 'js/Context/UseGlobalStoreContext';

/**
 * Report Level List API Calling Class
 */
class List extends Component {
    static propTypes = {
        children: childrenPropType,
        sessionId: PropTypes.string,
        page:PropTypes.string,
    };

    static defaultProps = {
        children: null,
        sessionId: null,
        page:null
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);
    }

    /**
     * Pick up API calls for each page
     */
    componentDidMount(){
        const {page,sessionId} = this.props;
        switch(page){
            case "dashboard":
                this.context.loadReportList(sessionId)
                this.context.loadReportLevel(sessionId);
                break;
            case "report":
                const {id} = this.props;
                this.context.loadReportItem(sessionId,false,id,false);
                this.context.loadGuidelines(sessionId)
                this.context.loadPrinciples(sessionId)
                break;
            case "pdfreport":
                this.context.loadReportItem(sessionId,false,this.props.id,this.props.pdf);
                this.context.loadGuidelines(sessionId)
                this.context.loadPrinciples(sessionId)
                break;
            case "editreport":
                this.context.loadReportItem(sessionId,false,this.props.id);
                this.context.loadGuidelines(sessionId)
                this.context.loadPrinciples(sessionId)
                break;
            default:
                break;
        }
    }


    getLoadingLabel(){
        const {page} = this.props;
        switch(page){
            case "dashboard":
                if(this.context.isLoadingReportLevel !== false){
                    return "Loading Report Levels"
                }
                if(this.context.isLoadingReportList !== false){
                    return "Loading Reports"
                }
                break;
            case "report":
            case "pdfreport":
            case "editreport":
                if(this.context.isLoadingPrinciples !== false){
                    return "Loading Principles"
                }
                if(this.context.isLoadingGuidelines !== false){
                    return "Loading Guidelines"
                }
                if(this.context.isLoadingReportItem !== false || Object.keys(this.context.reportItem).length === 0){
                    return "Loading Reports"
                }
   
                break;
            default:
                break;
        }

        return false
    }

    getErrorLabel(){
        const {page} = this.props;
        switch(page){
            case "dashboard":
                if(this.context.isErrorReportLevel !== false){
                    return "Report Levels"
                }
                if(this.context.isErrorReportList !== false){
                    return "Reports"
                }
                break;
            case "report":
            case "pdfreport":
            case "editreport":
                if(this.context.isErrorPrinciples !== false){
                    return "Principles"
                }
                if(this.context.isErrorGuidelines !== false){
                    return "Guidelines"
                }
                if(this.context.isErrorReportItem !== false){
                    return "Report"
                }
                break;
            default:
                break;
        }

        return false
    }


    /**
     * Render the report list API call children.
     *
     * @returns {React.node|object}
     */
    render() {
        const { children, ...passThroughProps } = this.props;
        const error = this.getErrorLabel()
        //console.log(error);
        if (error !== false) {
            return (
                <NetworkAlert
                    label={error}
                />
            );
        }

        const loading = this.getLoadingLabel()

        if (loading !== false) {
            return (
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                 left:0
                }}
              >
                <LoadingIndicator label={loading}/>
              </div>
            );
        }

        return React.Children.map(children, (child) => {
            if (child === null || child === undefined) {
                return null;
            }

            if (typeof child === 'string') {
                return child;
            }

            return React.cloneElement(child, {
                ...passThroughProps,
            });
        });
    }
}

List.contextType = GlobalStoreContext;
export default List;
