/**
 * Page Header Navigation section.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import CompanyDropdown from 'js/Components/PageHeader/Navigation/CompanyDropdown';
import ReportDropdown from 'js/Components/PageHeader/Navigation/ReportDropdown';

/**
 * Page Header Navigation class.
 */
class Navigation extends Component {
    /**
     * Render page header.
     *
     * @returns {React.node}
     */
    render() {
        const {personSession} = this.props;
        return (
            <nav aria-label="Main Navigation">
                <ul className="header-nav-list">
                    
                    <CompanyDropdown />
                    <ReportDropdown />
                </ul>
            </nav>
        );
    }
}

export default Navigation;
