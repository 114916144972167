/**
 * Back to top button.
 *
 * @author Patrick O'Hern <patrick.ohern@scalesology.com>
 */
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FaAngleDoubleUp } from "react-icons/fa";

class BackToTop extends Component {
  static propTypes = {
    msDelay: PropTypes.number,
    scrollStep: PropTypes.number,
  };

  static defaultProps = {
    msDelay: 17,
    scrollStep: 50,
  };

  /**
   * constructor
   *
   * @param {object} props Properties
   *
   * @returns {void}
   */
  constructor(props) {
    super(props);

    this.state = {
      intervalId: 0,
      scrollPercentage: 0,
    };

    // Efficient early binding
    this.onScroll = this.onScroll.bind(this);
    this.scrollStep = this.scrollStep.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  /**
   * Track scroll position.
   */
  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  /**
   * Remove event to prevent memory leaks.
   */
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  /**
   * Update scroll position on scroll change.
   */
  onScroll() {
    const h = document.documentElement;
    const b = document.body;
    const st = "scrollTop";
    const sh = "scrollHeight";
    const windowHeight = (h[sh] || b[sh]) - h.clientHeight;
    const scrollPosition = h[st] || b[st];
    const scrollPercentage = scrollPosition / windowHeight;

    this.setState({
      scrollPercentage,
    });
  }

  /**
   * @returns {void}
   */
  scrollStep() {
    const { scrollStep } = this.props;
    const { intervalId } = this.state;

    if (window.pageYOffset === 0) {
      clearInterval(intervalId);
    }

    window.scroll(0, window.pageYOffset - scrollStep);
  }

  /**
   * Scroll back to the top of the page. Event handle for button click.
   *
   * @returns {void}
   */
  scrollToTop() {
    const { msDelay } = this.props;
    const intervalId = setInterval(this.scrollStep, msDelay);

    this.setState({
      intervalId,
    });
  }

  /**
   * Render the back to top button.
   *
   * @returns {React.node}
   */
  render() {
    const { scrollPercentage } = this.state;
    const backToTopClasses = classNames({
      "back-to-top": true,
      "back-to-top-show": scrollPercentage > 0.4,
    });

    return (
      <button
        title="Back to top"
        className={backToTopClasses}
        onClick={this.scrollToTop}
      >
        <FaAngleDoubleUp aria-hidden="true" size="32" />
        <span className="a11y-sr-only">scroll to top</span>
      </button>
    );
  }
}

export default BackToTop;
