/**
 * Sign In form.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import Form from 'js/Components/Forms/Raw/Form';
import Link from 'js/Components/Link';
import noop from 'js/noop';
import TextFormGroup from 'js/Components/Forms/Raw/TextFormGroup';
import { email, required } from 'js/validations';

/**
 * Sign In form.
 */
class SignInForm extends Component {
    static propTypes = {
        refreshUser: PropTypes.func,
    };

    static defaultProps = {
        refreshUser: noop,
    };

    constructor(props) {
        super(props);

        this.validations = [
            {
                name: 'email',
                message: 'Please enter a valid email address.',
                validate: required,
            },
            {
                name: 'email',
                message: 'Please enter a valid email address.',
                validate: email,
            },
            {
                name: 'password',
                message: 'Please enter your password. Password is required.',
                validate: required,
            },
        ];

        // Efficient early binding
        this.onSignIn = this.onSignIn.bind(this);
    }

    /**
     * Handle user login.
     *
     * @returns {void}
     */
    onSignIn(responseBody, status) {
        if (status === 200) {
            const { refreshUser } = this.props;

            if (refreshUser) {
                refreshUser(responseBody);
            }
        }
    }

    /**
     * Render the sign in form.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <Form
                afterSubmit={this.onSignIn}
                buttonLabel="Sign in"
                fetchSubmit
                url={`${apiConfig}auth/login`}
                validations={this.validations}
            >
                <TextFormGroup
                    aria-label="Email address"
                    name="email"
                    placeholder="Email address"
                />
                <TextFormGroup
                    aria-label="Password"
                    name="password"
                    placeholder="Password"
                    type="password"
                />
                <div className="password-reset">
                    <Link
                        className="password-reset-link"
                        label="Reset Password"
                        href="/password-reset"
                    />
                </div>
            </Form>
        );
    }
}

export default SignInForm;
