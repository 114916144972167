/**
* Person List API Call.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import childrenPropType from 'js/PropTypes/children';
import LoadingIndicator from 'js/Components/LoadingIndicator';
import NetworkAlert from 'js/Components/Alert/NetworkAlert';
import {httpError401} from "js/Components/Api/useHttp";

/**
 * Person List API Calling Class
 */
class PersonList extends Component {
    static propTypes = {
        children: childrenPropType,
        sessionId: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        sessionId: null,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            loading: true,
            people: [],
        };

        // Efficient early binding.
        this.onAddPerson = this.onAddPerson.bind(this);
        this.onDeletePerson = this.onDeletePerson.bind(this);
        this.onUpdatePerson = this.onUpdatePerson.bind(this);
    }

    /**
     * Load persons after the component mounts.
     * This is to avoid warning about calling setState before mounting.
     *
     * @returns {void}
     */
    componentDidMount() {
        // Load persons from API
        this.loadPersons();
    }

    /**
     * Refresh person list.
     *
     * @returns {void}
     */
    onPersonRefresh() {
        this.setState({
            error: false,
            loading: true,
            people: [],
        });
        this.loadPersons();
    }

    /**
     * Method to add new user to the collection.
     *
     * @param {object} newPerson
     *
     * @returns {void}
     */
    onAddPerson(newPerson) {
        const { people } = this.state;

        people.push(newPerson);

        this.setState({
            people,
        });
    }

    /**
     * Update the specified person in the list of people.
     *
     * @param {number|string} id
     * @param {object} updatedPerson
     *
     * @returns {void}
     */
    onUpdatePerson(id, updatedPerson) {
        let { people: updatedPeople } = this.state;

        // Remove the edited user.
        updatedPeople = updatedPeople.filter((user) => {
            return user.id !== id;
        });

        // Push the new version of the object
        updatedPeople.push(updatedPerson);

        this.setState({
            people: updatedPeople,
        });
    }

    /**
     * Delete the person via API and state.
     *
     * @param {number|string} id
     *
     * @returns {void}
     */
    onDeletePerson(id) {
        const { sessionId } = this.props;

        fetch(
            `${apiConfig}person/${id}?sessionId=${sessionId}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'delete',
            })
            .then((response) => {
                const { status } = response;

                if(status === 401)
                {
                    httpError401();
                }
                else if (status !== 200) {
                    return this.setState({
                        error: true,
                        loading: false,
                    });
                }

                response
                    .json()
                    .then(() => {
                        let { people: updatedPeople } = this.state;

                        // Remove the deleted person.
                        updatedPeople = updatedPeople.filter((person) => {
                            return person.id !== id;
                        });

                        this.setState({
                            people: updatedPeople,
                        });
                    });
            });
    }

    /**
     * Load all Persons.
     *
     * @returns {void}
     */
    loadPersons() {
        const { sessionId } = this.props;

        fetch(
            `${apiConfig}person/list?sessionId=${sessionId}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
            })
            .then((response) => {
                const { status } = response;

                if(status === 401)
                {
                    httpError401();
                }
                else if (status !== 200) {
                    return this.setState({
                        error: true,
                        loading: false,
                    });
                }

                response
                    .json()
                    .then((body) => {
                        const newState = {};

                        newState.people = body;
                        newState.error = false;
                        newState.loading = false;
                        return this.setState(newState);
                    });
            });
    }

    /**
     * Render the person list API call children.
     *
     * @returns {React.node|object}
     */
    render() {
        const { children, ...passThroughProps } = this.props;
        const { error, loading, people } = this.state;

        if (error) {
            return (
                <NetworkAlert
                    label="person list"
                />
            );
        }

        if (loading) {
            return (
                <LoadingIndicator
                    label="Loading users..."
                />
            );
        }

        return React.Children.map(children, (child) => {
            if (child === null || child === undefined) {
                return null;
            }

            if (typeof child === 'string') {
                return child;
            }

            return React.cloneElement(child, {
                onAddPerson: this.onAddPerson,
                onDeletePerson: this.onDeletePerson,
                onUpdatePerson: this.onUpdatePerson,
                people,
                ...passThroughProps,
            });
        });
    }
}

export default PersonList;
