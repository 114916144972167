/**
 * Define the structure for the match property in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

const matchPropType = PropTypes.shape({
    params: PropTypes.object,
    url: PropTypes.string,
});

export default matchPropType;
