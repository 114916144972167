/**
* Add User form.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import Form from 'js/Components/Forms/Raw/Form';
import TextFormGroup from 'js/Components/Forms/Raw/TextFormGroup';
import reportLevelListPropType from 'js/PropTypes/reportLevelList';
import ReportLevelSelect from 'js/Components/Forms/ReportLevelSelect';
import { required, website } from 'js/validations';

import noop from 'js/noop';
import PropTypes from 'prop-types';
import Select from 'js/Components/Forms/Raw/Select';
import LoadingIndicator from 'js/Components/LoadingIndicator';
import NetworkAlert from 'js/Components/Alert/NetworkAlert';
import PhoneTextGroup from './Raw/PhoneTextGroup';
import {httpError401} from "js/Components/Api/useHttp";


/**
 * Add User form.
 */



class RequestReportForm extends Component {
    static propTypes = {
        levels: reportLevelListPropType,
        onSuccess: PropTypes.func
    };

    static defaultProps = {
        levels: {},
        onSuccess:noop
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
     constructor(props) {
        super(props);

        this.state = {
            sent: false,
            clients:[],
            error:false,
            loading:true
        };

        this.validations = [
            {
                name: 'website',
                message: 'Please enter a website. Website is required.',
                validate: required,
            },
            {
                name: 'website',
                message: 'Please enter a valid website.',
                validate: website,
            },
            {
                name: 'customerNotes',
                message: 'Please enter information about the pages to be audited.',
                validate: required,
            },
            {
                name: 'phone',
                message: 'Please enter your phone number.',
                validate:  (fields, field) => {
                    //at least ten numbers
                    if(!field){
                        return false;
                    }
                    return field.replace(/[^\d]/g, '').length >= 11 
                }
            },
        ];

        // Efficient early binding
    }

    componentDidMount(){
        const { personSession,hasPermission } = this.props;
        if(!hasPermission("manage.organization"))
        {
            return this.setState({
                error: false,
                loading: false,
            });
   
        }

        const {id} = personSession.session
        fetch( `${apiConfig}organization/listmanaged?sessionId=${id}`,            
        {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'get',
        }).then((response)=>{
            const { status } = response;

            if(status === 401)
            {
                httpError401();
            }
            else if (status !== 200) {
                return this.setState({
                    error: true,
                    loading: false,
                });
            }
            response
            .json()
            .then((body) => {
                const newState = {};
                newState.clients = body;
                newState.error = false;
                newState.loading = false;
                return this.setState(newState);
            });
        })
    }

    getClientList(){
        const list = {};
        this.state.clients.map((cl)=>{
            list[cl.id] = cl.name
        })
        return list;
    }

    /**
     * Render the Add User form.
     *
     * @returns {React.node}
     */
    render() {
        const { levels, onSuccess,personSession,hasPermission,onFailValidation } = this.props;
        const { error, loading} = this.state;
        
        if (error) {
            return (
                <NetworkAlert
                    label="person list"
                />
            );
        }

        if (loading) {
            return (
                <LoadingIndicator
                    label="Loading users..."
                />
            );
        }
        return (
          <Form
            buttonLabel={null}
            id="request-report-form"
            url={`${apiConfig}report?sessionId=${personSession.session.id}`}
            validations={this.validations}
            afterSubmit={onSuccess}
            onFailValidation={onFailValidation}
          >
            {hasPermission("manage.organization") && (
              <Select
                name="client"
                options={this.getClientList()}
                htmlFor="Client"
                label="Client"
              />
            )}
            <TextFormGroup htmlFor="website" label="Website" name="website" />
            <TextFormGroup
              htmlFor="notes"
              label="Notes"
              name="customerNotes"
              placeholder="Which pages, notes, how many pages, etc."
              title="Which pages, notes, how many pages, etc."
            />
            <PhoneTextGroup
                htmlFor="phone"
                label="Phone"
                name="phone"
            />
        
            {/* <TextFormGroup
                htmlFor="phone"
                label="Phone"
                name="phone"
                placeholder="Only used for questions related to your request."
                title="Only used for questions related to your request."
            /> */}
            <ReportLevelSelect label="Select Report" levels={levels} />
            <Select
                name="is_doc"
                options={{[false]:'False', [true]:'True'}}
                htmlFor="Document Audit"
                label="Document Audit"
              />
          </Form>
        );
    }
}

export default RequestReportForm;
