/**
* Sign In form.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import Form from 'js/Components/Forms/Raw/Form';
import { email, minLength, required } from 'js/validations';
import StateSelect from 'js/Components/Forms/StateSelect';
import TextFormGroup from 'js/Components/Forms/Raw/TextFormGroup';
import CountrySelect from 'js/Components/Forms/CountrySelect';

/**
 * Sign Up form.
 */
class SignUpForm extends Component {
    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
     constructor(props) {
        super(props);

        this.state = {
            countryCode: 'US',
            sent: false,
        };
        this.validations = [
            {
                name: 'firstName',
                message: 'Please enter your first name. A first name is required.',
                validate: required,
            },
            {
                name: 'lastName',
                message: 'Please enter your last name. A last name is required.',
                validate: required,
            },
            {
                name: 'email',
                message: 'Please enter your email address. Email is required.',
                validate: required,
            },
            {
                name: 'email',
                message: 'Please enter a valid email address. Email is required.',
                validate: email,
            },
            {
                name: 'password',
                message: 'Please enter your new password.',
                validate: required,
            },
            {
                name: 'password',
                message: 'Please enter at least 8 characters.',
                validate: minLength,
                params: {
                    min: 8,
                },
            },
            {
                name: 'confirm',
                message: 'Please confirm password by entering it a second time.',
                validate: required,
            },
            {
                name: 'confirm',
                message: 'Please enter at least 8 characters.',
                validate: minLength,
                params: {
                    min: 8,
                },
            },
            {
                name: 'confirm',
                message: 'Please verify that you entered the same password twice. Confirm password must match your new password.',
                validate: (values) => {
                    return values.confirm === values.password;
                }
            },
            {
                name: 'name',
                message: 'Please enter your company name. A company name is required.',
                validate: required,
            },
            {
                name: 'countryCode',
                message: 'Please enter country for address. Company address is required.',
                validate: required,
            },
            {
                name: 'streetAddress',
                message: 'Please enter your company address. Company address is required.',
                validate: required,
            },
            {
                name: 'city',
                message: 'Please enter city for address. Company address is required.',
                validate: required,

            },
            {
                name: 'stateCode',
                message: 'Please enter state for address. Company address is required.',
                validate: required,
            },
            {
                name: 'zipCode',
                message: 'Please enter a postal code for address. Company address is required.',
                validate: required,
            },
        ];

        // Efficient early binding.
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onSignUpSubmit = this.onSignUpSubmit.bind(this);
    }

    /**
     * Handle country change event.
     *
     * @returns {void}
     */
     onCountryChange(field, countryCode) {
        if (field === 'countryCode') {
            this.setState({
                countryCode,
            });
        }
    }

    /**
     * Handle form submit event.
     *
     * @returns {void}
     */
    onSignUpSubmit(formValues) {
        const { city, countryCode, email, firstName, lastName, name, password, stateCode, streetAddress, zipCode } = formValues;

        const payload = {
            email,
            firstName,
            lastName,
            organization: {},
            password,
        };

        payload.organization = {
            city,
            countryCode,
            name,
            stateCode,
            streetAddress,
            zipCode,
        };

        fetch(
            `${apiConfig}auth/register`,
            {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
            })
            .then(() => {
                this.setState({
                    sent: true,
                });
            })
            .catch((error) => {
                if (console) {
                    console.log(error); // eslint-disable-line no-console
                }
            });
    }

    /**
     * Render the sign up form.
     *
     * @returns {React.node}
     */
    render() {
        const { countryCode, sent } = this.state;

        if (sent) {
            return (
                <div className="sign-up-form">
                    <p>Account created.</p>
                </div>
            );
        }

        return (
            <Form
                buttonLabel="Create Account"
                className="sign-up-form"
                defaultValues={{
                    countryCode,
                }}
                fetchSubmit={false}
                onChildChange={this.onCountryChange}
                onSubmit={this.onSignUpSubmit}
                url={`${apiConfig}auth/register`}
                validations={this.validations}
            >
                <TextFormGroup
                    aria-label="First Name"
                    name="firstName"
                    placeholder="First Name"
                />
                <TextFormGroup
                    aria-label="Last Name"
                    name="lastName"
                    placeholder="Last Name"
                />
                <TextFormGroup
                    aria-label="Email address"
                    name="email"
                    placeholder="Email address"
                />
                <TextFormGroup
                    aria-label="Password"
                    name="password"
                    placeholder="Password"
                    type="password"
                />
                <TextFormGroup
                    aria-label="Confirm Password"
                    name="confirm"
                    placeholder="Confirm Password"
                    type="password"
                />
                <TextFormGroup
                    aria-label="Company"
                    name="name"
                    placeholder="Company"
                />
                <CountrySelect
                    aria-label="Country"
                    defaultValue={countryCode}
                    name="countryCode"
                />
                <TextFormGroup
                    aria-label="Street Address"
                    name="streetAddress"
                    placeholder="Street Address"
                />
                <TextFormGroup
                    aria-label="City"
                    name="city"
                    placeholder="City"
                />
                <StateSelect
                    aria-label="State/Province"
                    countryCode={countryCode}
                    name="stateCode"
                />
                <TextFormGroup
                    aria-label="Zip/Postal Code"
                    name="zipCode"
                    placeholder="Zip/Postal Code"
                />
            </Form>
        );
    }
}

export default SignUpForm;
