/**
 * Password Reset Token Page Component.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Link from 'js/Components/Link';
import matchPropType from 'js/PropTypes/match';
import SignInLogo from 'img/sign-in-logo.png'
import ResetPasswordUpdateForm from 'js/Components/Forms/ResetPasswordUpdateForm';

/**
 * Password Reset Token Page Component.
 */
class ResetPasswordToken extends Component {
    static propTypes = {
        match: matchPropType,
    };

    static defaultProps = {
        match: {
            params: {},
        },
    };

    isWelcome(){
        return /m\=welcome/i.test(window.location.href);
    }


    /**
    * Render password reset token page.
    *
    * @returns {React.node}
    */
    render() {
        const { match: { params: { token } } } = this.props;
        const isWelcome = this.isWelcome();

        return (
            <div className="signin-container">
                <img src={SignInLogo} alt="Scalesology Logo" />
                <h1>{isWelcome?"Welcome, please create new password ":"Password Reset"}</h1>
                <ResetPasswordUpdateForm
                    token={token}
                    isWelcome={isWelcome}
                />
                <div className="password-reset password-reset-back">
                    <Link
                        className="password-reset-link"
                        label="Back to sign in"
                        href="/sign-in"
                    />
                </div>
            </div>
        );
    }
}

export default ResetPasswordToken;
