/**
 * Table Header Cell Component.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import childrenPropType from 'js/PropTypes/children';

/**
 * Table Header Cell Class
 */
class HeaderCell extends Component {
    static propTypes = {
        children: childrenPropType,
        className: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        className: null,
    };

    /**
     * Render the table cell.
     *
     * @returns {React.node}
     */
    render() {
        const { children, className } = this.props;

        return (
            <th className={className}>
                {children}
            </th>
        );
    }
}

export default HeaderCell;
