/**
 * Router. Sets up history and scrolls back to top on page change.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';
import { Router } from 'react-router';
import { createBrowserHistory  } from 'history';

import RouteSwitch from 'js/Components/Routes/RouteSwitch';
import ScrollToTop from 'js/Components/ScrollToTop';

// Set browser history.
// We are using browser history rather than hash navigation
// so that in page hash works as expected.
const history = createBrowserHistory();

/**
 * ADA Report Router class
 */
class AdaReportRouter extends Component {
    /**
     * Render application routes.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <Router history={history}>
                <ScrollToTop>
                    <RouteSwitch />
                </ScrollToTop>
            </Router>
        );
    }
}

export default AdaReportRouter;
