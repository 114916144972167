/**
 * Filter Table.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FaFilter } from 'react-icons/fa';

import childrenPropType from 'js/PropTypes/children';
import SortTable from 'js/Components/Table/SortTable';
import TextFormGroupClear from 'js/Components/Forms/Raw/TextFormGroupClear';

/**
 * Filter Table.
 */
class FilterTable extends Component {
    static propTypes = {
        children: childrenPropType,
        data: PropTypes.arrayOf(PropTypes.object),
        filterFunction: PropTypes.func,
    };

    static defaultProps = {
        children: null,
        data: [],
        filterFunction: () => { return true; },
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            filteredData: props.data || [],
        };

        // Efficient early binding
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
    }

    /**
     * Update filtered data when new data is received.
     *
     * @param {object} newProps
     */
    static getDerivedStateFromProps(props, state) {
        const { data, filterFunction } = props;
        const { filter } = state;

        return {
            filteredData: data.filter(filterFunction.bind(this, filter)),
        };
    }

    /**
     * Handle event for filter update.
     *
     * @param {string} name Typically the form filed name. Not used.
     * @param {object} e Synthetic React event.
     *
     * @returns {void}
     */
    onUpdateFilter(name, e) {
        const newFilter = e.currentTarget.value;

        this.setState({
            filter: newFilter,
        });
    }

    /**
     * Sortable Table.
     *
     * @returns {React.node}
     */
    render() {
        const { children, ...passThroughProps } = this.props;
        const { filteredData } = this.state;

        return (
            <div className="filter-table">
                <div className="filter-table-controls">
                    <TextFormGroupClear
                        icon={
                            <FaFilter
                                aria-hidden="true"
                            />
                        }
                        onChange={this.onUpdateFilter}
                        placeholder="Filter..."
                    />
                    {children}
                </div>
                <SortTable
                    {...passThroughProps}
                    data={filteredData}
                />
            </div>
        );
    }
}

export default FilterTable;
