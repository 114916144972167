/**
 * Add/edit Organization Modal Component.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddEditOrganizationForm from 'js/Components/Forms/AddEditOrganizationForm';
import Button from 'js/Components/Forms/Raw/Button';
import Modal from 'js/Components/Modal/Raw/Modal';
import ModalBody from 'js/Components/Modal/Raw/ModalBody';
import ModalFooter from 'js/Components/Modal/Raw/ModalFooter';
import ModalHeader from 'js/Components/Modal/Raw/ModalHeader';
import noop from 'js/noop';
import organizationPropType from 'js/PropTypes/organization';
import utils from 'js/utils';

/**
 * Add/edit Organization Modal class.
 */
class AddEditOrganizationModal extends Component {
    static propTypes = {
        action: PropTypes.oneOf(['add', 'edit']),
        onClose: PropTypes.func,
        onOrganizationSaved: PropTypes.func,
        open: PropTypes.bool,
        organization: organizationPropType,
    };

    static defaultProps = {
        action: 'add',
        onClose: noop,
        onOrganizationSaved: noop,
        open: false,
        organization: {},
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            click:false
        };

        // Efficient early binding.
        this.onOrganizationSaved = this.onOrganizationSaved.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFailValidation = this.onFailValidation.bind(this);
    }

    /**
     * Handle event after save.
     *
     * @returns {void}
     */
     onOrganizationSaved(response, request) {
        const { onClose, onOrganizationSaved, organization } = this.props;
        this.setState({click:false});

        // this.setState({ message: `${organization.name} updated.` });
        if (onClose) {
            onClose();
        }

   
        if (onOrganizationSaved) {
            const updatedOrganization = Object.assign({}, organization, request);
           
            // Extract id from the response on create organization.
            if (typeof response === 'string') {
                const newOrganizationId = response.match(/\d+$/)[0];

                updatedOrganization.id = Number.parseInt(newOrganizationId, 10);
            }

            if(!("type" in updatedOrganization)){
                updatedOrganization["type"] = "client"
            }

            onOrganizationSaved(updatedOrganization);
        }
    }

    onSubmit(e){

        if(this.state.click === true){
            e.preventDefault(); 
            return;
        }
        this.setState({click:true})
    }

    onFailValidation(){
        this.setState({click:false})
    }
    /**
     * Render add/edit organization modal.
     *
     * @returns {React.node}
     */
    render() {
        const { action, onClose, open, organization,personSession,hasPermission, organizations,hasRole} = this.props;

        return (
            <Modal
                className="add-edit-organization-modal"
                onClose={onClose}
                open={open}
            >
                <ModalHeader>
                    {utils.ucFirst(action)} Organization
                </ModalHeader>
                <ModalBody>
                    {this.state.message ? this.state.message : null}
                    <AddEditOrganizationForm
                        action={action}
                        onOrganizationSaved={this.onOrganizationSaved}
                        organization={organization}
                        personSession={personSession}
                        hasPermission={hasPermission}
                        organizations={organizations}
                        hasRole={hasRole}
                        onFailValidation={this.onFailValidation}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={onClose}
                        title="Cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        form="add-edit-organization-form"
                        primary
                        title="Save Organization"
                        type="submit"
                        onClick={this.onSubmit}
                    >
                        Save Organization
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default AddEditOrganizationModal;
