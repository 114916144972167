/**
 * Collapse boxes for layout.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

import Button from 'js/Components/Forms/Raw/Button';
import childrenPropType from 'js/PropTypes/children';
import noop from 'js/noop';

/**
 * Collapse box class.
 */
class CollapseBox extends Component {
    static propTypes = {
        boxRef: PropTypes.func,
        children: childrenPropType,
        className: PropTypes.string,
        collapsed: PropTypes.bool,
        defaultCollapsed: PropTypes.bool,
        label: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
        ]),
        onToggleCollapse: PropTypes.func,
    };

    static defaultProps = {
        boxRef: noop,
        children: null,
        className: null,
        collapsed: null, // Null signifies that it's handled internally.
        defaultCollapsed: false,
        label: null,
        onToggleCollapse: noop,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        // Note: the collapsed property will override state completely.
        this.state = {
            collapsed: props.defaultCollapsed,
        };

        // Efficient early binding.
        this.onToggleCollapse = this.onToggleCollapse.bind(this);
    }

    /**
     * Capture toggle event and change collapsed state.
     *
     * @returns {void}
     */
    onToggleCollapse() {
        const { collapsed: collapsedProp, onToggleCollapse } = this.props;
        const { collapsed } = this.state;
        const isCollapsed = collapsedProp || collapsed;
        const newCollapse = !isCollapsed;

        // If collapsed is not handled externally,
        // then handle it as state.
        if (collapsedProp === null) {
            this.setState({
                collapsed: newCollapse,
            });
        }

        if (onToggleCollapse) {
            onToggleCollapse(newCollapse);
        }
    }

    /**
     * Render collapse box body.
     *
     * @returns {React.node|null}
     */
    renderBody() {
        const { collapsed: collapsedProp, children } = this.props;
        const { collapsed } = this.state;
        const bodyClasses = classNames({
            'collapse-box-body': true,
            collapsed: collapsedProp || collapsed,
        });

        return (
            <div className={bodyClasses}>
                {children}
            </div>
        );
    }

    /**
     * Render collapse icon.
     *
     * @returns {React.node}
     */
    renderCollapseIcon() {
        const { collapsed: collapsedProp } = this.props;
        const { collapsed } = this.state;
        const isCollapsed = collapsedProp || collapsed;

        return (
            <Button
                className="collapse-box-icon"
                onClick={this.onToggleCollapse}
                title={collapsed ? 'Expand' : 'Collapse'}
            >
                {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
            </Button>
        );
    }

    /**
     * Render collapse box header.
     *
     * @returns {React.node}
     */
    renderHeader() {
        const { collapsed: collapsedProp, label } = this.props;
        const { collapsed } = this.state;
        const headerClasses = classNames({
            'collapse-box-header': true,
            collapsed: collapsedProp || collapsed,
        });

        return (
            <div className={headerClasses}>
                {label}
                {this.renderCollapseIcon()}
            </div>
        );
    }

    /**
     * Render collapse box.
     *
     * @returns {React.node}
     */
    render() {
        const { boxRef, className, id } = this.props;
        const boxWrapperClasses = classNames({
            'base-box-wrapper': true,
            'collapse-box-wrapper': true,
            [className]: className,
        });

        return (
            <div ref={boxRef} className={boxWrapperClasses} id={id}>
                <div className="base-box collapse-box">
                    {this.renderHeader()}
                    {this.renderBody()}
                </div>
            </div>
        );
    }
}

export default CollapseBox;
