/**
 * Sortable Table.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IoCaretUpOutline, IoCaretDownOutline } from 'react-icons/io5';

import Table from 'js/Components/Table/Raw/Table';
import utils from 'js/utils';

/* eslint-disable react/forbid-prop-types */
/**
 * Sortable Table.
 */
class SortTable extends Component {
    static propTypes = {
        className: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.object),
        dataTransformations: PropTypes.object,
        footers: PropTypes.object,
        headers: PropTypes.object,
        headerSort: PropTypes.array,
        sortTransformations: PropTypes.object,
        striped: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        data: [],
        dataTransformations: {},
        footers: {},
        headers: {},
        headerSort: [],
        sortTransformations: {},
        striped: false,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        let sortField = '';

        // Assign first field as sort field (if applicable)
        if (props.data && props.headerSort[0]) {
            sortField = props.headerSort[0]; // eslint-disable-line
        }

        this.state = {
            sortField,
            sort: 'asc',
        };

        // Efficient early binding
        this.onClickHeader = this.onClickHeader.bind(this);
    }

    /**
     * Change sort by field based on user click.
     *
     * @param {Event} event Synthetic React event.
     *
     * @returns {void}
     */
    onClickHeader(event) {
        const { sort, sortField } = this.state;
        const toggle = event.currentTarget;
        const newSortField = toggle.getAttribute('data-field');
        let newSort = 'asc';

        if (sortField === newSortField && sort === 'asc') {
            newSort = 'desc';
        }

        this.setState({
            sortField: newSortField,
            sort: newSort,
        });
    }

    /**
     * Append sort controls to headers.
     */
    appendHeaderSortControls() {
        const { headers } = this.props;
        const headerList = {};
        const { sort, sortField } = this.state;
        let Icon = IoCaretDownOutline;

        if (sort === 'asc') {
            Icon = IoCaretUpOutline;
        }

        Object.keys(headers).forEach((header) => {
            headerList[header] = (
                <span
                    className="sort-toggle"
                    data-field={header}
                    onClick={this.onClickHeader}
                    onKeyPress={this.onClickHeader}
                    role="button"
                    tabIndex={0}
                >
                    {headers[header]}
                    {sortField === header ? <Icon /> : null}
                </span>
            );
        });

        return headerList;
    }

    /**
     * Sort the data per the sort key.
     */
    sortData() {
        const { data, dataTransformations, sortTransformations } = this.props;
        const { sort, sortField } = this.state;

        return data.sort((a, b) => {
            if (!sortField) {
                return 0;
            }

            let fieldA = a[sortField];
            let fieldB = b[sortField];

            if (sortField in dataTransformations) {
                fieldA = dataTransformations[sortField](a[sortField], a);
            }

            if (sortField in dataTransformations) {
                fieldB = dataTransformations[sortField](b[sortField], b);
            }

            if (sortField in sortTransformations) {
                fieldA = sortTransformations[sortField](a[sortField], a);
            }

            if (sortField in sortTransformations) {
                fieldB = sortTransformations[sortField](b[sortField], b);
            }

            // Case insensitive sort
            if (typeof fieldA === 'string') {
                fieldA = fieldA.toLowerCase();
            }
            if (typeof fieldB === 'string') {
                fieldB = fieldB.toLowerCase();
            }

            if (fieldA === null || fieldB === null) {
                return 0;
            }

            if (fieldA === undefined || fieldB === undefined) {
                return 0;
            }

            let sortValue = utils.naturalSort(fieldA, fieldB, false);

            if (sort === 'desc') {
                sortValue *= -1;
            }

            return sortValue;
        });
    }

    /**
     * Sortable Table.
     *
     * @returns {React.node}
     */
    render() {
        const { className, dataTransformations, footers, headerSort, striped } = this.props;
        const tableClasses = classNames({
            'sort-table': true,
            [className]: className,
        });

        return (
            <Table
                className={tableClasses}
                data={this.sortData()}
                dataTransformations={dataTransformations}
                footers={footers}
                headers={this.appendHeaderSortControls()}
                headerSort={headerSort}
                striped={striped}
            />
        );
    }
}

export default SortTable;
