import { useCallback,useState } from "react"
import useHttp from "./useHttp"
import apiConfig from 'js/config/api';


const useGuideline = ()=>{
    const {isLoading, error, sendRequest} = useHttp()

    const createGuideline = useCallback((data,sessionId,callback)=>{

        sendRequest({
            url: `${apiConfig}violation?sessionId=${sessionId}`,
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'post',
            body:data
        },
        (data)=>{
            callback(true,data)
        },
        (e)=>{
            callback(false,e)
        })
    },[])


    const updateGuideline = useCallback((data,id,sessionId,callback)=>{
        sendRequest({
            url: `${apiConfig}violation/${id}?sessionId=${sessionId}`,
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PATCH',
            body:data
        },
        (data)=>{
            callback(true,data)
        },
        (e)=>{
            callback(false,e)
        })
    },[])


    const deleteGuideline = useCallback((data,id,sessionId,callback)=>{
        sendRequest({
            url: `${apiConfig}violation/${id}?sessionId=${sessionId}`,
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body:data
        },
        (data)=>{
            callback(true,data)
        },
        (e)=>{
            callback(false,e)
        })
    },[])

    return {
        isLoading,
        error,
        createGuideline,
        updateGuideline,
        deleteGuideline
    }
}

export default useGuideline;