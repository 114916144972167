/**
* User Info form.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import { FaRegCheckCircle } from 'react-icons/fa'
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Alert from 'js/Components/Alert/Raw/Alert';
import { email, required } from 'js/validations';
import Form from 'js/Components/Forms/Raw/Form';
import noop from 'js/noop';
import personPropType from 'js/PropTypes/person';
import TextFormGroup from 'js/Components/Forms/Raw/TextFormGroup';

/**
 * User Info form.
 */
class UserInfoForm extends Component {
    static propTypes = {
        person: personPropType,
        onProfileUpdate: PropTypes.func,
    };

    static defaultProps = {
        person: {},
        onProfileUpdate: noop,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            successMessage: false,
        };
        this.timer = null;
        this.validations = [
            {
                name: 'firstName',
                message: 'Please enter your first name. A first name is required.',
                validate: required,
            },
            {
                name: 'lastName',
                message: 'Please enter your last name. A last name is required.',
                validate: required,
            },
            {
                name: 'email',
                message: 'Please enter your email address. An email address is required.',
                validate: required,
            },
            {
                name: 'email',
                message: 'Please enter a valid email address. An email address is required.',
                validate: email,
            },
        ];

        // Efficient early binding.
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onCloseSuccess = this.onCloseSuccess.bind(this);
    }

    /**
     * Clear timer before unmounting to
     * prevent errant state update.
     *
     * @returns {void}
     */
    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    /**
     * Event handler for success message close.
     *
     * @returns {void}
     */
    onCloseSuccess() {
        this.setState({
            successMessage: false,
        });
    }

    /**
     * Handle event for updating person information.
     *
     * @param {object} fields
     *
     * @returns {void}
     */
    onFormSubmit(fields) {
        const { onProfileUpdate } = this.props;

        // Assumes success.
        this.setState({
            successMessage: true,
        });
        // Auto clear success after 10 seconds.
        this.timer = setTimeout(this.onCloseSuccess, 10000);

        if (onProfileUpdate) {
            onProfileUpdate(fields);
        }
    }

    /**
     * Render the profile save success message.
     *
     * @returns {React.node|null}
     */
    renderMessage() {
        const { successMessage } = this.state;

        if (successMessage) {
            return (
                <Alert
                    closeable={false}
                    icon={<FaRegCheckCircle />}
                    success
                >
                    Your profile has been saved successfully.
                </Alert>
            );
        }

        return null;
    }

    /**
     * Render the User Info form.
     *
     * @returns {React.node}
     */
    render() {
        const { person } = this.props;

        return (
            <Form
                buttonLabel="Update Profile"
                className="user-info-form"
                defaultValues={person}
                onSubmit={this.onFormSubmit}
                validations={this.validations}
            >
                <TextFormGroup
                    defaultValue={person ? person.firstName : null}
                    htmlFor="firstName"
                    label="First Name"
                    name="firstName"
                />
                <TextFormGroup
                    defaultValue={person ? person.lastName : null}
                    htmlFor="lastName"
                    label="Last Name"
                    name="lastName"
                />
                <TextFormGroup
                    defaultValue={person ? person.email : null}
                    htmlFor="email"
                    label="Email Address"
                    name="email"
                />
                <TextFormGroup
                    defaultValue={person ? person.phone : null}
                    htmlFor="phone"
                    label="Phone Number"
                    name="phone"
                />
                {this.renderMessage()}
            </Form>
        );
    }
}

export default UserInfoForm;
