/**
 * Table Component.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BodyCell from 'js/Components/Table/Raw/BodyCell';
import HeaderCell from 'js/Components/Table/Raw/HeaderCell';
import Row from 'js/Components/Table/Raw/Row';
import TableBody from 'js/Components/Table/Raw/TableBody';
import TableFooter from 'js/Components/Table/Raw/TableFooter';
import TableHeader from 'js/Components/Table/Raw/TableHeader';

/* eslint-disable react/forbid-prop-types */
/**
 * Table Class
 */
class Table extends Component {
    static propTypes = {
        className: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.object),
        dataTransformations: PropTypes.object,
        footers: PropTypes.object,
        headers: PropTypes.object,
        headerSort: PropTypes.array,
        striped: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        data: [],
        dataTransformations: {},
        footers: {},
        headers: {},
        headerSort: [],
        striped: false,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.extractHeaders(props.data, props.headers, props.headerSort);
        this.extractBody(props.data, props.dataTransformations);
    }

    /**
     * Update internal variables for state update.
     *
     * @param {Object} nextProps New React Properties
     */
    componentWillReceiveProps(nextProps) {
        this.extractHeaders(nextProps.data, nextProps.headers, nextProps.headerSort);
        this.extractBody(nextProps.data, nextProps.dataTransformations);
    }

    /**
     * Extract headers from an array of objects.
     *
     * @param {Array} data Tabular data in the form of an array of objects. Typical API response.
     * @param {Array} headers
     * @param {Array} headerSort
     *
     * @returns {Array}
     */
    extractHeaders(data, headers, headerSort) {
        this.headers = headerSort.slice();

        if (headerSort.length === 0) {
            data.forEach((row) => {
                Object.keys(row).forEach((key) => {
                    if (this.headers.indexOf(key) === -1) {
                        if (key in headers && headers[key] === false) {
                            return; // Skip header
                        }

                        this.headers.push(key);
                    }
                });
            });
        }

        return this.headers;
    }

    /**
     * Extract body from an array of objects.
     *
     * @param {Array} data Tabular data in the form of an array of objects. Typical API response.
     * @param {Object} dataTransformations
     *
     * @returns {Array}
     */
    extractBody(data, dataTransformations) {
        this.body = [];

        /* eslint-disable react/no-array-index-key */
        data.forEach((row) => {
            const newRow = [];

            this.headers.forEach((header) => {
                if (header in row) {
                    const rowValue = row[header];

                    if (header in dataTransformations) {
                        newRow.push(dataTransformations[header](rowValue, row));
                    } else {
                        newRow.push(rowValue);
                    }
                } else {
                    newRow.push('');
                }
            });

            this.body.push(newRow);
        });
    }

    /**
     * Render table body.
     *
     * @returns {Array}
     */
    renderBody() {
        /* eslint-disable react/no-array-index-key */
        return this.body.map((row, i) => {
            const rowCells = row.map((cell, ii) => {
                return (
                    <BodyCell
                        key={ii}
                    >
                        {cell}
                    </BodyCell>
                );
            });

            return (
                <Row
                    key={i}
                >
                    {rowCells}
                </Row>
            );
        });
    }

    /**
     * Render table footer.
     *
     * @returns {Array}
     */
    renderFooter() {
        const { footers } = this.props;

        if (footers && Object.keys(footers).length !== 0) {
            const rowChildren = this.headers.map((cell, i) => {
                let cellValue = null;

                if (cell in footers) {
                    cellValue = footers[cell];
                }

                return (
                    <BodyCell
                        key={i}
                    >
                        {cellValue}
                    </BodyCell>
                );
            });

            return (
                <Row>
                    {rowChildren}
                </Row>
            );
        }

        return null;
    }

    /**
     * Render table header.
     *
     * @returns {Array}
     */
    renderHeader() {
        const { headers } = this.props;
        const rowCells = this.headers.map((cell, i) => {
            let cellValue = cell;

            if (cell in headers) {
                cellValue = headers[cell];
            }

            return (
                <HeaderCell
                    key={i}
                >
                    {cellValue}
                </HeaderCell>
            );
        });

        return (
            <Row>
                {rowCells}
            </Row>
        );
    }

    /**
     * Render the table.
     *
     * @returns {React.node}
     */
    render() {
        const { className, striped } = this.props;
        const tableClasses = classNames({
            table: true,
            'table-admin': true,
            striped,
            [className]: className,
        });

        return (
            <table className={tableClasses}>
                <TableHeader>
                    {this.renderHeader()}
                </TableHeader>
                <TableBody>
                    {this.renderBody()}
                </TableBody>
                <TableFooter>
                    {this.renderFooter()}
                </TableFooter>
            </table>
        );
    }
}

export default Table;
