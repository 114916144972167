/**
 * Define the structure of a report level list in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

const reportLevelListPropType = PropTypes.shape({
    1: PropTypes.string,
});

export default reportLevelListPropType;
