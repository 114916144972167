/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'js/Components/Report/Container';
import reportShape from 'js/PropTypes/reportShape';
import SideNavigation from 'js/Components/Report/SideNavigation';
import { GlobalStoreContext } from 'js/Context/UseGlobalStoreContext';

class AdaReport extends React.PureComponent {
    static propTypes = {
        report: reportShape.isRequired,
        sessionId: PropTypes.string,
    };

    static defaultProps = {
        sessionId: null,
        report:{}
    };

    componentDidMount() {
        const { location: { hash } } = window;

        this.scrollToHash(hash);
        this.observeNavigation();
    }

    observeNavigation() {
        // ToDo: Manage state in React instead
        // ToDo: Only make the most relevant sections active (highest ratio per tree)
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const id = entry.target.getAttribute('id');
                const navElement = document.querySelector(`.section-nav li a[href="#${id}"]`);

                if (navElement === null) {
                    return;
                }

                if (entry.intersectionRatio > 0) {
                    navElement.parentElement.classList.add('active');
                } else {
                    navElement.parentElement.classList.remove('active');
                }
            });
        });

        // Track all headers that have an `id` applied
        // ToDo: Make just section once appendix wraps with sections
        document.querySelectorAll('section[id], h1[id], h2[id], h3[id], h4[id], h5[id], h6[id]').forEach((section) => {
            observer.observe(section);
        });
    }

    scrollToHash(hash) {
        if (hash !== '') {
            const id = hash.split('#')[1];
            const element = document.querySelector(`[id="${id}"]`);

            if (element) {
                // Not ideal, but sometimes this will not scroll properly to elements otherwise
                setTimeout(() => {
                    element.scrollIntoView();
                }, 500);
            }
        } else {
            window.scrollTo(0, 0);
        }
    }

    render() {
        const { sessionId,pdf,uid } = this.props;
        const {reportItem:report,principles,guidelines, reportLevel:levels}  = this.context
        //console.log(pdf)

        return (
            <div className="report-content">
                
                <SideNavigation 
                    report={report}
                    pdf={pdf}
                    principles={principles}
                    uid={report.uid}
                />
                <Container report={report}
                    principles={principles}
                    guidelines={guidelines}
                    levels={levels}
                    pdf={pdf}
                />

            </div>
        );
    }
}

AdaReport.contextType = GlobalStoreContext;
export default AdaReport;
