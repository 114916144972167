/**
 * Edit Report Guideline box.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import {useEffect,useState,useMemo,useCallback,useRef,useContext} from "react";
import Select from "js/Components/Forms/Raw/Select";
import TextFormGroup from "js/Components/Forms/Raw/TextFormGroup";
import { Editor } from '@toast-ui/react-editor'
import { v4 as uuidv4 } from 'uuid'
import useGuideline from "js/Components/Api/useGuideline";
import UserContext from "js/Context/UserContext";
import Details from "./Details";
import IconButton from "../Raw/IconButton";
import LoadingIndicatorTransparent from "js/Components/LoadingIndicatorTransparent";
import { GlobalStoreContext } from 'js/Context/UseGlobalStoreContext';


/**
 * Edit report guideline box class.
 */
const imagePath = "/report/img/";
let errorTimeoutId = null;


const Box = ({ guidelines, violation,id, pageId, onDeleteViolation, index})=>{
    const [saveModel, setSaveModel] = useState(false);
    const [error, setError] = useState(false);
    const [loading,setLoading] = useState(false);

    const [applicableGuideline,setApplicableGuideline] = useState(null);
    const [violationId, setViolationId] = useState(null);
    const [selectGuideline, setSelectGuideline] = useState(null);
    const [name,setName] = useState(null);
    const [details,setDetails] = useState([]);
    const [numberOfViolation, setNumberOfViolation] = useState(null);

    const [newImages, setNewImages] = useState({});
    const [initialImages,setInitialImages] = useState({});
    const editorRef = useRef();
    const {createGuideline, updateGuideline,deleteGuideline} = useGuideline();
    const {personSession} = useContext(UserContext);
    const globalContext = useContext(GlobalStoreContext);

    const onChange = useCallback((name,e)=>{
        let value = e.target.value
        if(!saveModel){setSaveModel(true)}
        switch(name){
            case "guidelineId":
                setSelectGuideline(value)
                return;
            case "name":
                setName(value);
                return; 
            case "violation":
                setNumberOfViolation(value)
                return;
            default:
                return;
        }
    },[])

    const addImageHook = (file,callback)=>{
        const reader = new FileReader();
        reader.addEventListener("load", (e)=> {
            //let path
            let ext = file.name.substring(file.name.lastIndexOf("."));

            let path = `${imagePath}${id}/${uuidv4()}${ext}`
            newImages[path] = e.target.result
            setNewImages(newImages)
            callback(path)
          });
        reader.readAsDataURL(file);
    }

    const GuidelineDropdown = useMemo(()=>{
        const list = {};
        guidelines.map((item)=>{
            list[item['id']] = item['name']
        })

        return(<Select
                    name="guidelineId"
                    options={list}
                    htmlFor="Guideline"
                    label="Guideline"
                    firstEmpty={selectGuideline !== null? null: true}
                    value={selectGuideline}
                    onChange={onChange}
            />)
    },[guidelines, selectGuideline])


    const setErrorFunc = useCallback((message)=>{
        setError(message);
        if(errorTimeoutId !== null){
            clearTimeout(errorTimeoutId)
        }
        errorTimeoutId = setTimeout(()=>{
            setError(false)
        },2000)

    },[])


    const getImages = useCallback((markdown)=>{
        let reg = /\!\[[^\]]+\]\(([^\)]+)\)/g
        let result = [...markdown.matchAll(reg)]
        return result.map((item)=>item[1])
    },[])


    const onDelete = ()=>{
   
        if(violationId !== null && violationId !== undefined){
            setLoading(true);
            deleteGuideline({deleteImgs:initialImages},violationId, personSession.session.id,(isSuccess,data)=>{
                if(!isSuccess){
                    setErrorFunc("Error! Please try again!")
                    console.log("Error", data)
                }
                setLoading(false);
            })
        }
        onDeleteViolation(index)
    }

    const onSave = ()=>{
        setLoading(true);
        let uploadImgs = {};
        let deleteImgs = {...initialImages};
        let newInitial = {};

        let description = editorRef.current.editorInst.getMarkdown()
        let images =  getImages(description)
        images.map((path)=>{
            if(newImages.hasOwnProperty(path)){
                uploadImgs[path] =  newImages[path]
            }

            if(initialImages.hasOwnProperty(path)){
                delete deleteImgs[path]
            }
            newInitial[path] = path
        })

        //Update Exist Guideline
        if(violationId !== null && violationId !== undefined ){
            updateGuideline( {
                uploadImgs:uploadImgs,
                deleteImgs:deleteImgs,
                guideLineId: selectGuideline,
                violations: numberOfViolation,
                name: name,
                description:description,
                details:details
            },violationId, personSession.session.id,(isSuccess,data)=>{
                if(isSuccess){
                    Object.keys(uploadImgs).map((path)=>{
                        delete newImages[path]
                    })
                    setNewImages(newImages)
                    setInitialImages(newInitial);
                    setSaveModel(false)
                }else{
                    setErrorFunc("Error! Please try again!")
                    console.log("Error", data)
                }
                setLoading(false);
            })
        }
        // Create new
        else{
            if(selectGuideline === null){
                setErrorFunc("Please select a guideline!")
            }else if(name === null){
                setErrorFunc("Please add title!")
            }
            else if(!numberOfViolation){
                setErrorFunc("Please add valid number of violation!")
            }
            else{
                setError(false)
                createGuideline({
                    guidelineId:selectGuideline,
                    name: name,
                    description:description,
                    uploadImgs:uploadImgs,
                    details:details,
                    reportPageId:pageId,
                    violations: numberOfViolation,
                },personSession.session.id,(isSuccess,data)=>{
                    if(isSuccess,data){
                
                        Object.keys(uploadImgs).map((path)=>{
                            delete newImages[path]
                        })
                        setNewImages(newImages)
                        setViolationId(data.id)
                        setSaveModel(false)
                    }
                    else{
                        setErrorFunc("Error! Please try again!")
                        console.log("Error", data)
                    }
                    setLoading(false);
                })
            }
        }
     
    }

    const onAddGlobalViolation = ()=>{
        globalContext.addGlobalViolation(violation.id, personSession.session.id, (isWork,data)=>{
            if(isWork){

                globalContext.updatePages(data.pages);
            }
        })
    }

    const onSyncGlobalViolation = ()=>{
        globalContext.syncGlobalViolation(violation.id, personSession.session.id, (isWork,data)=>{
            if(isWork){
                console.log(data.pages);
                globalContext.updatePages(data.pages);
            }
        })
    }

    const onDeleteGlobalViolation = ()=>{
        globalContext.deleteGlobalViolation(violation.id, personSession.session.id, (isWork,data)=>{
            if(isWork){
                globalContext.updatePages(data.pages);
            }
        })
    }

    useEffect(()=>{
        const applicableGuideline = guidelines.find((guideline) => {
            return guideline.id === violation.guidelineId;
        });
        if(applicableGuideline !== undefined)
        {
            setApplicableGuideline(applicableGuideline);
            setSelectGuideline(applicableGuideline.id)
            setViolationId(violation.id)
            setName(violation.name);
            let initial = {}
            let images = getImages(violation.description)
            images.map((path)=>{
                initial[path] = path
            })
            setInitialImages(initial);
            setDetails(violation.details);
            setNumberOfViolation(violation.violations)
           
        }
        
  
    },[guidelines,violation])

    return (
        <div className="edit-report-gl-container">
            <div className={"edit-report-gl-row"}>
                <div className={"edit-report-gl-guideline"}>{GuidelineDropdown}</div>
                <div className={"edit-report-gl-violation"}>
                    <TextFormGroup
                        defaultValue={numberOfViolation}
                        htmlFor="Violation"
                        label="Violation"
                        name="violation"
                        onChange={onChange}
                    />
                </div>

            </div>
            <div>
                <TextFormGroup
                    defaultValue={name}
                    htmlFor="Name"
                    label="Name"
                    name="name"
                    onChange={onChange}
                />

            </div>
            <div key={violation.description}>
                <Editor 
                    usageStatistics={false}
                    initialValue={violation !== null ?violation.description:""}
                    previewStyle="vertical"
                    height="400px"
                    initialEditType="markdown"
                    autofocus={false}
                    hideModeSwitch={true}
                    useCommandShortcut={true}
                    ref={editorRef}
                    onChange={()=>{if(!saveModel){setSaveModel(true)}}}
                    newImages={newImages}
                    hooks={{addImageBlobHook:addImageHook}}
                    customHTMLRenderer={{
                        image:(node,context)=>{
                            const { destination } = node;
                            const { getChildrenText, skipChildren } = context;
                            skipChildren();
              
                            return {
                              type: "openTag",
                              tagName: "img",
                              selfClose: true,
                              attributes: {
                                src:newImages.hasOwnProperty(destination)?newImages[destination]:destination,
                                alt: getChildrenText(node),
                              },
                            };
                        }
                    }}
                />
            </div>
            <div>
                <Details
                    details={details}
                    setDetails={(details)=>{if(!saveModel){setSaveModel(true);} setDetails(details);}}
                />
            </div>
            <div className="edit-report-gl-row">
                <IconButton type="delete" text="Delete Violation" onClick={onDelete}/>
                {saveModel &&
                <IconButton type="save" text="Save Violation" onClick={onSave}/>
                }
            </div>

            {
                (violation.copied == null || violation.copied  == -1) &&
                <div className="edit-report-gl-row" style={{marginTop:'10px'}}>
                    {
                        violation.copied == null 
                        && <IconButton type="save" text="Add this violation for all pages" onClick={onAddGlobalViolation}/>
                    }
                    {
                        violation.copied == -1 
                        && <IconButton type="save" text="Sync this global violation for all pages" onClick={onSyncGlobalViolation}/>
                    }
                    
                    {
                        violation.copied == -1  &&
                        <IconButton type="delete" text="Remove this violation for all pages" onClick={onDeleteGlobalViolation}/>
                    }
                </div>
            }
            {
                (violation.copied !== null && violation.copied  > 0) &&
                <div style={{marginTop:'20px', color:'#d32f2f', fontWeight:'bold'}}>
                    Common Violation
                </div>
            }
            <div className="edit-report-gl-error">
                {error}
            </div>
            <div>
                {loading&&
                    <LoadingIndicatorTransparent/>
                }
            </div>
 
        </div>
    );
}


export default Box;
