import { useCallback, useState } from "react"
import useHttp from "./useHttp"
import apiConfig from 'js/config/api';

let working = false;
const useReportLevel = ()=>{
    const {isLoading, error, sendRequest} = useHttp()
    const [reportLevel, setReportLevel] = useState({});

    const loadReportLevel = useCallback((sessionId,forceload)=>{
        if(working === false || forceload){
            working = true
            sendRequest({
                    url: `${apiConfig}report/levels?sessionId=${sessionId}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'get',
                },
                (data)=>{
                    let levels = {};
                    data.map((item)=>{
                        levels[item['id']] = item['name']
                    })
                    setReportLevel(levels)
                    working = false
                },
                ()=>{
                    working = false
                }
            )
        }
    },[isLoading])

 
    return {
        isLoadingReportLevel: isLoading,
        isErrorReportLevel: error,
        reportLevel:reportLevel,
        loadReportLevel: loadReportLevel
    }
}

export default useReportLevel;