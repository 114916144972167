/**
 * Define the structure of a report violation in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

import violationDetailPropType from 'js/PropTypes/violationDetail';

const violationPropType = PropTypes.shape({
    description: PropTypes.string,
    details: PropTypes.arrayOf(
        violationDetailPropType,
    ),
    guidelineId: PropTypes.number,
    id: PropTypes.number,
});

export default violationPropType;
