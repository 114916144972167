/**
 * Page Header Company Dropdown.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import ClientCertificateItem from 'js/Components/PageHeader/Navigation/ClientCertificateItem';
import ClientCertificateItem2 from './ClientCertificate2.1';
import Group from 'js/Components/PageHeader/Navigation/Raw/Group';
import Item from 'js/Components/PageHeader/Navigation/Raw/Item';
import ManageClientItem from 'js/Components/PageHeader/Navigation/ManageClientItem';
import ManageUserItem from 'js/Components/PageHeader/Navigation/ManageUserItem';
import UserContext from 'js/Context/UserContext';

/**
 * Page Header Company Dropdown class.
 */
class CompanyDropdown extends Component {
    /**
     * Render page header company dropdown.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <UserContext.Consumer>
                {({personSession}) => {
                    if (personSession && personSession.person && personSession.person.organization) {
                        return (
                          <Group
                            className="company-dropdown"
                            label={personSession.person.organization.name}
                          >
                            <Item
                              href="/company-settings"
                              label="Company settings"
                            />
                            <ManageClientItem />
                            <ManageUserItem />
                            <ClientCertificateItem />
                            <ClientCertificateItem2 version={2} />
                            <ClientCertificateItem2 version={3} />
                            <ClientCertificateItem2 version={4} />
                            <ClientCertificateItem2 version={5} />
                          </Group>
                        );
                    }

                    return null;
                }}
            </UserContext.Consumer>
        );
    }
}

export default CompanyDropdown;