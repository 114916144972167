/**
 * Edit Report principle.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 * @author Ryan Johnston <jason.lu@scalesology.com>
 */

import {useEffect, useState, useMemo,useCallback} from 'react';

import AddGuidelineButton from 'js/Components/EditReport/Guideline/AddGuildlineButton';
import CollapseBox from 'js/Components/EditReport/Raw/CollapseBox';
import GuidelineBox from 'js/Components/EditReport/Guideline/Box';


/**
 * Edit report principle.
 */


const Principle = ({guidelines,principle,page,id})=>{

    const [violation, setViolation] = useState(null);

    const onDeleteViolation = useCallback((index)=>{
        let newViolation = violation.filter((item,i)=>{
            return index !== i;
        })
        setViolation(newViolation);
    },[violation])

    const violationView = useMemo(()=>{
        if(violation !== null)
        {
   
            return violation.map((item,index) => {
                return (
                    <GuidelineBox
                        key={item.hasOwnProperty('id')?item.id:null}
                        guidelines={guidelines}
                        violation={item}
                        id={id}
                        pageId={page.id}
                        index={index}
                        onDeleteViolation={onDeleteViolation}
                    />
                );
           });
        }


    },[violation])

    const onAddViolation = useCallback(()=>{
        setViolation([
            ...violation,
            {
                id:null,
                name:null,
                description:"",
                guidelineId:null,
                violation: 0
            }
        ])
    },[violation])



    useEffect(()=>{
        const validGuidelineIds = guidelines.map((guideline) => {
            return guideline.id;
        });
        
        const validViolations = page.violations.filter((issue) => {
            return validGuidelineIds.includes(issue.guidelineId);
        });
        setViolation(validViolations)

    },[page,guidelines])

    return(
    <CollapseBox
        className="edit-report-principle"
        defaultCollapsed={false}
        label={principle.name}
    >
        {violationView}
        <AddGuidelineButton onClick={onAddViolation}/>
    </CollapseBox>);
}

export default Principle;
