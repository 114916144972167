/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
const language = {
    websiteOverviewGraphs: '**Figure 1** displays the overall proportion of accessibility guidelines that passed versus failed based on severity. A guideline passes when it has a severity of ‘None’; If a guideline or principle was not evaluated, it is denoted ‘NE’. otherwise, the guideline fails. The severity measure considers the number of times the failure occurred within a particular guideline, i.e. the number of violations, as well as the level – Level A or AA – of the guideline as defined by the Web Content Accessibility Guidelines (WCAG). **Figure 2** shows the total count of violations per severity level.',
    principlesGraphs: 'The accessibility guidelines are grouped under four principles – Perceivable, Operable, Understandable and Robust. **Figure 3** summarizes the severity levels of all guidelines within each principle along with the total number of violations to quickly see if the website has issues within a particular principle(s).',
    pagesOverviewTable: 'The pages overview in **Figure 4** displays the highest severity level present within each accessibility principle and the number of failed guidelines per web page. It highlights the web pages with the most issues allowing the prioritization of fixes, if applicable.',
    detailedSummary: 'This section provides a detailed summary of the following per web page:\n\n* Each test criteria that failed within a particular guideline\n* A specific example(s) of the failure with a description\n* A proposed solution, if applicable\n* The number of violations on that page\n* The overall severity score (i.e. the highest score) encountered within the guideline\n\n**Figure 5** provides a visual map of all of the guidelines tested within each principle, the pass/fail status of each guideline, and the severity level by guideline, as well as by web page. Links are provided within the map (denoted by the number) so you can quickly jump to the details of each test result.',
};

export default language;
