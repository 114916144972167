/**
 * Add/edit Person Modal Component.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddEditPersonForm from 'js/Components/Forms/AddEditPersonForm';
import Button from 'js/Components/Forms/Raw/Button';
import Modal from 'js/Components/Modal/Raw/Modal';
import ModalBody from 'js/Components/Modal/Raw/ModalBody';
import ModalFooter from 'js/Components/Modal/Raw/ModalFooter';
import ModalHeader from 'js/Components/Modal/Raw/ModalHeader';
import noop from 'js/noop';
import organizationPropType from 'js/PropTypes/organization';
import personPropType from 'js/PropTypes/person';
import rolePropType from 'js/PropTypes/role';
import utils from 'js/utils';

/**
 * Add/edit User Modal class.
 */
class AddEditUserModal extends Component {
    static propTypes = {
        action: PropTypes.oneOf(['add', 'edit']),
        onClose: PropTypes.func,
        onPersonSaved: PropTypes.func,
        open: PropTypes.bool,
        organizations: PropTypes.arrayOf(organizationPropType),
        person: personPropType,
        roles: PropTypes.arrayOf(rolePropType),
    };

    static defaultProps = {
        action: 'add',
        onClose: noop,
        onPersonSaved: noop,
        open: false,
        organizations: [],
        person: null,
        roles: [],
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            click:false
        };

        // Efficient early binding.
        this.onPersonSaved = this.onPersonSaved.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFailValidation = this.onFailValidation.bind(this);
    }

    /**
     * Handle event after save.
     *
     * @returns {void}
     */
    onPersonSaved(response, request) {
        const { onClose, onPersonSaved, person, roles } = this.props;

        if (onClose) {
            onClose();
        }
        this.setState({click:false});

        if (onPersonSaved) {
            const updatedPerson = Object.assign({}, person, request);

            // Extract id from the response on create person.
            if (typeof response === 'string') {
                const newPersonId = response.match(/\d+$/)[0];

                updatedPerson.id = Number.parseInt(newPersonId, 10);
            }

            // Rebuild roles as they would appear in the list.
            if (request.roleId) {
                updatedPerson.roles = [roles.find(role => role.id === request.roleId)];
            } else {
                updatedPerson.roles = request.roles.map((requestRole) => {
                    return roles.find(role => role.id === requestRole);
                });
            }

            onPersonSaved(updatedPerson);
        }
    }

    onSubmit(e){

        if(this.state.click === true){
            e.preventDefault(); 
            return;
        }
        this.setState({click:true})
    }

    onFailValidation(){
        this.setState({click:false})
    }

    /**
     * Render add/edit person modal.
     *
     * @returns {React.node}
     */
    render() {
        const { action, onClose, open, organizations, person, roles } = this.props;

        return (
            <Modal
                className="add-edit-person-modal"
                onClose={onClose}
                open={open}
            >
                <ModalHeader>
                    {utils.ucFirst(action)} User
                </ModalHeader>
                <ModalBody>
                    <AddEditPersonForm
                        action={action}
                        onPersonSaved={this.onPersonSaved}
                        organizations={organizations}
                        person={person}
                        roles={roles}
                        onFailValidation={this.onFailValidation}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={onClose}
                        title="Cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        form="add-edit-person-form"
                        primary
                        title="Save User"
                        type="submit"
                        onClick={this.onSubmit}
                    >
                        Save User
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default AddEditUserModal;
