/**
 * Application entry point.
 * Responsible for user context and session management.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import moment from 'moment';
import React, { Component } from 'react';

import 'sass/app.scss';
import apiConfig, {sessionKey} from 'js/config/api';
import defaultPersonSession from 'js/config/defaultPersonSession';
import Router from 'js/Components/Router';
import UserContext from 'js/Context/UserContext';
import GlobalStoreContextProvider from './Context/UseGlobalStoreContext';


/**
 * Scalesology ADA App.
 */
class App extends Component {
    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        // Efficient early binding
        this.hasPermission = this.hasPermission.bind(this);
        this.hasRole = this.hasRole.bind(this);
        this.readUserSession = this.readUserSession.bind(this);
        this.refreshUser = this.refreshUser.bind(this);
        this.saveUserSession = this.saveUserSession.bind(this);
        this.signOut = this.signOut.bind(this);
        this.unlockForceChange = this.unlockForceChange.bind(this);

        // State also contains the updater function so it will
        // be passed down into the context provider
        this.localStorageKey = sessionKey;
        this.state = {
            hasPermission: this.hasPermission,
            hasRole: this.hasRole,
            personSession: this.readUserSession(),
            refreshUser: this.refreshUser,
            signOut: this.signOut,
            unlockForceChange:this.unlockForceChange
        };
    }

    /**
     * Save user session to local storage.
     *
     * @param {object} personSession Updated user object.
     *
     * @returns {void}
     */
    saveUserSession(personSession) {
        if (personSession) {
            localStorage.setItem(this.localStorageKey, JSON.stringify(personSession));
        }
    }

    /**
     * Read user session from local storage.
     *
     * @returns {object} personSession Updated user object.
     */
    readUserSession() {
        let sessionUser = localStorage.getItem(this.localStorageKey);

        // If found and not expired, return session user
        if (sessionUser) {
            sessionUser = JSON.parse(sessionUser);

            if (!this.isSessionExpired(sessionUser.session)) {
                return sessionUser;
            } else {
                // Clear the local storage item, it's no longer valid.
                //this.signOut();
                localStorage.removeItem(this.localStorageKey);
            }
        }

        return defaultPersonSession;
    }

    /**
     * Check if the session is expired.
     *
     * @param {object} session Current session.
     *
     * @returns {boolean}
     */
    isSessionExpired(session) {
        return session && session.expiration && moment(session.expiration).isBefore();
    }

    /**
     * Check if the logged in user has the permission.
     *
     * @param {string} permissionToHave permission slug to verify.
     *
     * @returns {boolean}
     */
    hasPermission(permissionToHave) {
        const { personSession } = this.state;

        if (personSession && personSession.person && personSession.person.roles) {
            return personSession.person.roles.some((role) => {
                if (role && role.permissions) {
                    return role.permissions.some((permission) => {
                        return permission.slug === permissionToHave;
                    });
                }

                return false;
            })
        }

        return false;
    }

    /**
     * Check if the logged in user has the role.
     *
     * @param {string} roleToHave Role slug to verify.
     *
     * @returns {boolean}
     */
    hasRole(roleToHave) {
        const { personSession } = this.state;

        if (personSession && personSession.person && personSession.person.roles) {
            return personSession.person.roles.some((role) => {
                return role.slug === roleToHave;
            })
        }

        return false;
    }

    /**
     * Method to update signed in user from child components.
     *
     * @param {object} personSession Updated user object.
     *
     * @returns {void}
     */
    refreshUser(personSession) {
        // Update isAuthenticated flag. This does not come from API.
        if (!this.isSessionExpired(personSession.session)) {
            personSession.session.isAuthenticated = true;
        } else {
            personSession.session.isAuthenticated = false;
        }

        this.saveUserSession(personSession);
        this.setState({
            personSession,
        });
    };

    unlockForceChange(value){
        const {personSession} = this.state;
        personSession.person.forceChange = value
        this.saveUserSession(personSession);
        this.setState({
            personSession:{
                ...personSession
            }

        })
    }

    /**
     * Force sign out by removing session from local storage.
     *
     * @returns {object} personSession Updated person/session object.
     */
    signOut() {
        const { personSession } = this.state;

        fetch(
            `${apiConfig}auth/logout?sessionId=${personSession.session.id}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'post',
            })
            .then(() => {
                localStorage.removeItem(this.localStorageKey);

                this.setState({
                    personSession: defaultPersonSession,
                });
            });
    }

    /**
     * Render Scalesology ADA App.
     *
     * @returns {React.node}
     */
    render() {
        //console.log(this.state.personSession);
        return (
            <GlobalStoreContextProvider>
                <UserContext.Provider value={this.state}>
                    <Router />
                </UserContext.Provider>
            </GlobalStoreContextProvider>
        );
    }
}

export default App;
