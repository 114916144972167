/**
 * Settings block.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import childrenPropType from 'js/PropTypes/children';

/**
 * Settings block class.
 */
class SettingsBlock extends Component {
    static propTypes = {
        children: childrenPropType,
        label: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        label: 'Settings',
    };

    /**
     * Render the Settings block.
     *
     * @returns {React.node}
     */
    render() {
        const { children, label } = this.props;

        return (
            <section className="settings-block">
                <h2>{label}</h2>
                {children}
            </section>
        );
    }
}

export default SettingsBlock;
