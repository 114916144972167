import React,{useCallback,useState} from "react";
import apiConfig from '../../config/api';
import {httpError401} from "js/Components/Api/useHttp";
let timeid = null;

const ImportReportForm = ({reportId,personSession,onClose,setLoading,disableLoading})=>{

    const [file,setFile] = useState(null);
    const [errorMessage,setErrorMessage] = useState(false);
    const [isSubmit,setIsSubmit] = useState(false);

    console.log(personSession);
    //Select File
    const onChange = useCallback((event)=>{
        setErrorMessage(false);
        if(timeid !== null){
            clearTimeout(timeid);
            timeid = null;
        }
        setFile(null);

        const input = event.target;
        if(!input.value.length){
            return;
        }
        const file = input.files[0];
        if(!/json$/i.test(file['type']))
        {
            setErrorMessage("Only accept JSON file!");

        }


        const reader = new FileReader(input.files);
        reader.onload = (readerEvent)=>{
            const content = readerEvent.target.result;
            try{
                const fileContent = JSON.parse(content)
                setFile(fileContent);
            }catch(e){
                setErrorMessage("Something in the file is broken!");
            }

        }
        const blob = new Blob([file], {type:"application/json"});
        reader.readAsText(blob);
    },[]);


    //Submit File
    const onSubmit = useCallback((e)=>{
        e.preventDefault()
        if(isSubmit)
        {
            return
        }

        if(file === null){
            setErrorMessage("No file selected!");
            timeid = setTimeout(()=>{setErrorMessage(false);},1500)
        }else{
            const sessionId = "as";
            setLoading();
            (async()=>{
                try{
                    setIsSubmit(true)
                    const result = await fetch(
                        `${apiConfig}report/${reportId}/import?sessionId=${personSession.session.id}`,
                        {
                            body: JSON.stringify({
                                file:file
                            }),
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            method: 'post',
                        }
                    )
                    const response = await result.json();
                    
                    if(result.status === 401){
                        httpError401();
                    }
    

                    if(response.result === "success" ){
                        onClose();
                    }
                    else{
                        throw Error("Internal Error!");
                    }
                
                }catch(e){
                    setErrorMessage(e.message);
                }finally{
                    setIsSubmit(false)
                    disableLoading();
                }
            })()
        }
    },[file,timeid,isSubmit])

    return(
        <>
            <form   id="request-import-report" onSubmit={onSubmit} style={{display:"flex", justifyContent:"space-between", alignItems:"center",margin:" 15px 0 10px"}}>
                <label htmlFor="import-json-file">Update Json File</label>
                <input onChange={onChange} style={{margin:0}} className="form-control" type="file" id="import-json-file" accept=".json"/>
            </form>
            {
                errorMessage&&
                <p style={{color:"#ee184b",fontSize:"12px",textAlign:"right"}}>
                   {errorMessage}
                </p>
            }

        </>
    );

}
export default ImportReportForm;