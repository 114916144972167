import {useState, useCallback} from "react";
import RunAuditToolModal from "../Modal/RunAuditToolModal";
import IconButton from "../EditReport/Raw/IconButton";

const RunAuditToolButton = ({id,setLoading,disableLoading})=>{
    const [open, setOpen] = useState(false);

    const onClose = useCallback(()=>{
        setOpen(false)
    },[])

    const onOpen = useCallback(()=>{
        setOpen(true)
    },[])

    return(
    <>
        <IconButton
            type="add"
            text="Add Audit URLs"
            onClick={onOpen}
        />
        {
            open&&
            <RunAuditToolModal
                id={id}
                open={open}
                onClose={onClose}
                setLoading={setLoading}
                disableLoading={disableLoading}


            />
        }

    </>
    );
}

export default RunAuditToolButton;