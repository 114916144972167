/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { PureComponent } from 'react';

import MarkdownText from 'js/Components/Utils/MarkdownText';
import reportShape from 'js/PropTypes/reportShape';
import SectionWithHeading from 'js/Components/Utils/SectionWithHeading';

// Work around inability to edit webpack config in CRA with raw-loader
/* eslint-disable import/no-webpack-loader-syntax */
import appendixA from '!raw-loader!markdown/appendix-a.md';
import appendixA2 from '!raw-loader!markdown/appendix-a-2.md';
import appendixA22 from '!raw-loader!markdown/appendix-a-2.2.md';
import level1Diagnostics from '!raw-loader!markdown/level-1-diagnostics.md';
import level2Diagnostics from '!raw-loader!markdown/wcag2-1.md';
import level3Diagnostics from '!raw-loader!markdown/level-3-diagnostics.md';
import level4Diagnostics from '!raw-loader!markdown/level-4-diagnostics.md';
import level5Diagnostics from '!raw-loader!markdown/level-5-diagnostics.md';

// ToDo: Make marked assign ID to section preceding heading
class AppendixA extends PureComponent {
    static propTypes = {
        report: reportShape.isRequired,
    };

    componentDidMount() {
        const headingTags = ['h3', 'h4', 'h5', 'h6'];
        const headings = [...this.section.querySelectorAll(headingTags.join(', '))];

        headings.forEach((heading) => {
            const { id } = heading;

            heading.append(this.createAnchor(id));
        });
    }

    createIcon() {
        const icon = document.createElement('span');
        icon.setAttribute('class', 'fas fa-link');

        return icon;
    }

    createAnchor(id) {
        const anchor = document.createElement('a');
        const icon = this.createIcon();

        Object.entries({
            href: `#${id}`,
            class: 'hoverable-anchor',
            'aria-label': 'Anchor',
        }).forEach(([key, value]) => {
            anchor.setAttribute(key, value);
        });

        anchor.append(icon);

        return anchor;
    }

    getAppendix(){
      const { report } = this.props;

      switch (report.reportLevel) {
        case 1:
          return <MarkdownText text={appendixA} />;
        case 2:  
          return <MarkdownText text={appendixA2} />;
        case 3:
          return <MarkdownText text={appendixA2} />;
        case 4:
          return <MarkdownText text={appendixA22} />;
        case 5:
          return <MarkdownText text={appendixA22} />;
        default:
          return null;
      }
    }

    getDiagnostics(){
      switch (this.props.report.reportLevel) {
        case 1:
          return <MarkdownText text={level1Diagnostics} />;
        case 2:
          return <MarkdownText text={level2Diagnostics} />;
        case 3:
          return <MarkdownText text={level3Diagnostics} />;
        case 4:
          return <MarkdownText text={level4Diagnostics} />;
        case 5:
          return <MarkdownText text={level5Diagnostics} />;
        default:
          return null;
      }
    
    }

    render() {
        const { report } = this.props;

        return (
          <SectionWithHeading
            className="appendix appendix-a-container"
            heading="Appendix A: Audit Criteria"
            htmlRef={(c) => {
              this.section = c;
            }}
            id="appendix-a"
            level={2}
          >
            {this.getAppendix()}
            {this.getDiagnostics()}
          </SectionWithHeading>
        );
    }
}

export default AppendixA;
