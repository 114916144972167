/**
 * Dashboard Report Block.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from "prop-types";
import React, { Component } from "react";
import axios from 'axios'
import Button from "js/Components/Forms/Raw/Button";
import Link from "js/Components/Link";
import reportPropType from "js/PropTypes/report";
import urlConfig from '../../config/api'
import DeleteRequestButton from "../Forms/DeleteRequestButton";
/**
 * Dashboard Report Block class.
 */
class ReportBlock extends Component {
  static propTypes = {
    isReportAuditor: PropTypes.bool,
    isReportViewer: PropTypes.bool,
    report: reportPropType,
  };

  static defaultProps = {
    isReportAuditor: false,
    isReportViewer: false,
    report: {},
  };

  /**
   * constructor
   *
   * @param {object} props Properties
   *
   * @returns {void}
   */
  constructor(props) {
    super(props);

    this.state = {};

    // Efficient early binding.
    this.onReportNavigate = this.onReportNavigate.bind(this);
    this.onReportEdit = this.onReportEdit.bind(this);
    this.onReportView = this.onReportView.bind(this);
    this.onDeleteRequest = this.onDeleteRequest.bind(this);
    this.onArchiveReport = this.onArchiveReport.bind(this);
    this.stopBubble = this.stopBubble.bind(this);
  }

  /**
   * Handle event for navigation to report page.
   *
   * @param {string} url Url of link.
   *
   * @returns {void}
   */
  onReportNavigate(url) {
    const { id } = this.props.report;

    window.location = `/${url}/${id}`;
  }

  /**
   * Handle navigation event for editing a report.
   *
   * @param {Event} event Synthetic React event.
   *
   * @returns {void}
   */
  onReportEdit(event) {
    // Do not allow the event to bubble up to the block.
    event.stopPropagation();
    const { isReportAuditor } = this.props;

    if (isReportAuditor) {
      this.onReportNavigate("edit-report");
    }
  }

  /**
   * Handle navigation event for viewing a report.
   *
   * @param {Event} event Synthetic React event.
   *
   * @returns {void}
   */
  onReportView(event) {
    // Do not allow the event to bubble up to the block.
    event.stopPropagation();
    const { isReportViewer, report } = this.props;

    if (isReportViewer && report.isFinalized === 3) {
      this.onReportNavigate("report");
    }
  }


  onDeleteRequest(event) {
    // event.stopPropagation();
    const { report, sessionId, onReportRefresh } = this.props;
    const deleteRequest = axios
      .delete(`${urlConfig}report/${report.id}?sessionId=${sessionId}`)
      .then((response) => {
        if (response.status === 200){
          onReportRefresh()
        }
      });
    console.log(deleteRequest)
  }

  onArchiveReport(event) {
    
    const { report, sessionId, onReportRefresh } = this.props;
    const deleteRequest = axios
      .delete(`${urlConfig}archive/${report.id}?sessionId=${sessionId}`)
      .then((response) => {
        if (response.status === 200){
          onReportRefresh()
        }
      });
    console.log("Archive Report")
  }

  /**
   * Handle link click event.
   *
   * @param {Event} event Synthetic React event.
   *
   * @returns {void}
   */
  stopBubble(event) {
    // Do not allow the event to bubble up to the block.
    event.stopPropagation();
  }

  /**
   * Render view button.
   *
   * @returns {React.node|null}
   */
  renderEditButton() {
    const { isReportAuditor, report } = this.props;

    if (isReportAuditor) {
      return report.isFinalized !== 3 ? (
        <Button
          className="report-block-audit-button"
          onClick={this.onReportEdit}
        >
          Audit Website
        </Button>
      ) : report.is_archived === true ? null : (
        <Button
          className="report-block-audit-button"
          onClick={this.onReportEdit}
        >
          Edit Report
        </Button>
      );
    }

    return null;
  }

  /**
   * Render view button.
   *
   * @returns {React.node|null}
   */
  renderViewButton() {
    const { isReportViewer, report } = this.props;
    const createdAt = new Date(report.createdAt);
    let  message = (report.isFinalized !== 1 ? "Audit In Progress. ": "Audit requested on " +  createdAt.toLocaleDateString());

    if (isReportViewer && report.isFinalized === 3) {
      return (
        <Button onClick={this.onReportView} primary>
          View Report
        </Button>
      );
    } else if (isReportViewer && report.isFinalized !==3) {
      return <div>{message}</div>;
    }

    return null;
  }


  /**
   * Render Delete button.
   *
   * @returns {React.node|null}
   */
  renderDeleteRequest() {
    const { report } = this.props;
    if (report.isFinalized === 1) {
      return (
        <DeleteRequestButton report={report} onDeleteRequest={this.onDeleteRequest}/>
      );
    } else if (report.isFinalized === 3 && report.is_archived !== true) {
      return (
        <DeleteRequestButton
          report={report}
          onArchiveReport={this.onArchiveReport}
        />
      );
    } else if (report.is_archived === true) {
      return null
    }

    return null;
  }



  /**
   * Render report block.
   *
   * @returns {React.node}
   */
  render() {
    const { levels, report } = this.props;
    const {
      auditedOn,
      guidelines,
      isFinalized,
      organization,
      totalPages,
      reportLevel,
      website,
      coverimage,
      coverimage_url,
    } = report;
    const imageurl =
      coverimage_url
        ? `/${coverimage_url}`: (coverimage ? `data:image/png;base64,${coverimage}` : `/img/mock/ScreenshotError.png`);
    const shortWebsite = website.replace("https://", "").replace("http://", "");
    let auditedState = "Audit requested";
    if (isFinalized === 3) {
      const auditDate = new Date(auditedOn);
      auditedState = "Audited " + auditDate.toLocaleDateString();
    }

    return (
      <div
        className="report-block"
        // onClick={this.onReportView}
        // onKeyPress={this.onReportView}
        role="button"
        tabIndex={0}
      >
        <h2>{organization.name}</h2>
        <div className="report-block-header">
          <Link
            className="report-website"
            external
            href={`//${shortWebsite}`}
            label={website}
            onClick={this.stopBubble}
          />
          <div className="report-guideline">{levels[reportLevel]}</div>
        </div>
        <img
          src={`${imageurl}`}
          alt="website preview banner"
          style={{ maxWidth: "100%" }}
        />
        {report.isFinalized !== 3 ? null : (
          <div className="report-block-footer">
            <div className="report-pages">
              <div className="report-page-count">
                {totalPages} Page{totalPages !== 1 ? "s" : ""}
              </div>
              <div>{auditedState}</div>
            </div>
            <div className="report-guidelines">
              <div className="report-guideline-count">
                {guidelines && guidelines["total"]
                  ? `${guidelines["passed"]}/${guidelines["total"]}`
                  : "N/A"}
              </div>
              <div>Guidelines Passed</div>
            </div>
          </div>
        )}
        <div className="report-block-action">
          {this.renderViewButton()}
          {this.renderEditButton()}
          {this.renderDeleteRequest()}
        </div>
      </div>
    );
  }
}

export default ReportBlock;
