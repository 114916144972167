/**
 * Define the structure of a report page in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

import violationPropType from 'js/PropTypes/violation';

const reportPagePropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    violations: PropTypes.arrayOf(
       violationPropType
    ).isRequired,
});

export default reportPagePropType;
