/**
 * Page Header User Dropdown.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import ButtonItem from 'js/Components/PageHeader/Navigation/ButtonItem';
import Dropdown from 'js/Components/Dropdown/Dropdown';
import Item from 'js/Components/PageHeader/Navigation/Raw/Item';
import UserBadge from 'js/Components/PageHeader/UserBadge';

import UserContext from 'js/Context/UserContext';

/**
 * Page Header User Dropdown class.
 */
class UserDropdown extends Component {
    /**
     * Render page header.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <Dropdown
                className="user-dropdown"
                label={<UserBadge />}
                tabIndex={0}
            >
                <ul>
                    <Item
                        href="/account-settings"
                        label="Account settings"
                    />
                    <UserContext.Consumer>
                        {({signOut}) => (
                            <ButtonItem
                                onClick={signOut}
                                href="/sign-in"
                                label="Sign out"
                            />
                        )}
                    </UserContext.Consumer>
                </ul>
            </Dropdown>
        );
    }
}

export default UserDropdown;
