import React, {useState, useCallback} from 'react';
import IconButton from "../EditReport/Raw/IconButton";
import PickupGuidelinesModal from "../Modal/PickupGuidelinesModal";

const PickupReportGuidelinesButton = ({setLoading, disableLoading}) => {
    const [open, setOpen] = useState(false);
    const onClose = useCallback(()=>{
        setOpen(false)
    },[])

    const onOpen = useCallback(()=>{
        setOpen(true)
    },[])

    return(
        <>
            <IconButton
                type="add"
                text="Edit Guidelines"
                onClick={onOpen}
            />
            {
                open&&
                <PickupGuidelinesModal
                    
                    open={open}
                    onClose={onClose}
                    setLoading={setLoading}
                    disableLoading={disableLoading}

                />
            }
        </>
    );
}

export default PickupReportGuidelinesButton;