/**
 * Page Header Navigation button item.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'js/Components/Forms/Raw/Button';
import noop from 'js/noop';

/**
 * Page Header Navigation button item class.
 */
class ButtonItem extends Component {
    static propTypes = {
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
        onClick: PropTypes.func,
    };

    static defaultProps = {
        label: null,
        onClick: noop,
    };

    /**
     * Render page header navigation button item.
     *
     * @returns {React.node}
     */
    render() {
        const { label, onClick } = this.props;

        return (
            <li>
                <Button
                    link
                    onClick={onClick}
                >
                    {label}
                </Button>
            </li>
        );
    }
}

export default ButtonItem;
