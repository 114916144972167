
import severityColors from 'js/config/severityColors';
const xlsxDetailsByMetrics = (metrics)=>{
    let xlsxData = []
    let header = [
      {
        width: 400,
        value: "Principle",
        fontWeight: 'bold'
      },
      {
        width: 400,
        value: "Guideline",
        fontWeight: 'bold'
      },
      {
        width: 400,
        value: "Pass/Fail",
        fontWeight: 'bold'
      },
      {
        width: 400,
        value: "Overall Severity Score",
        fontWeight: 'bold'
      }
    ]
    let firstItem = true;
    metrics['principles'].map((principle) => {
      principle.guidelines.map((guideline)=>{
        let row = [
          {
            width: 400,
            value: principle['name'],
          },
          {
            width: 400,
            value: guideline['name'],
          },
          {
            width: 400,
            value: guideline['applied'] ? (guideline['severity'] == 0 ? "Pass":"Fail"): 'NE',
          },
          {
            width: 400,
            value: guideline['severityLabel'],
          },
        ];

        guideline.pages.map((page)=>{
          if(firstItem){
            header.push({
              width: 300,
              value: page['name'],
              fontWeight: 'bold'
            })
          }
          row.push({
            width: 300,
            value: page['severity'],
            backgroundColor: guideline['applied'] ?  severityColors[page['severity']] : '#ffffff'
          })
        })

        if(firstItem){
          firstItem=false
          xlsxData.push(header)
        }

        xlsxData.push(row)
      })
    })

    return xlsxData
}

export default xlsxDetailsByMetrics;