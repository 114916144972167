/**
 * Define the structure of a role in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

import permissionPropType from 'js/PropTypes/permission';

const rolePropType = PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    isGlobal: PropTypes.bool,
    name: PropTypes.string,
    permissions: PropTypes.arrayOf(permissionPropType),
    slug: PropTypes.string,
});

export default rolePropType;
