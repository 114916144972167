import { useCallback,useState } from "react"
import useHttp from "./useHttp"
import apiConfig from 'js/config/api';

let working = false;
const useReportItem = ()=>{
    const {isLoading, error, sendRequest} = useHttp()
    const [reportItem, setReportItem] = useState({});


    const loadReportItem= useCallback((sessionId, forceload=false,id,isPdf)=>{
        if(working === false || forceload)
        {
            working = true;
            const pdfStr = isPdf?"&pdf=true":''; 
            sendRequest({
                url: `${apiConfig}report/${id}?sessionId=${sessionId}${pdfStr}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
            },
            (data)=>{
                
                setReportItem(data)
                working = false
            },
            ()=>{
                working = false
            }

        )
        }

    },[isLoading])

    const updateReportItem = useCallback((oldItem,data,id,sessionId,callback)=>{

        if(working === false )
        {
            working = true;

            sendRequest({
                url: `${apiConfig}report/${id}?sessionId=${sessionId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'PATCH',
                body:data
            },
            (result)=>{
                const newItem = {
                    ...oldItem,
                    ...data,
                    ...result
                }
     
                setReportItem(newItem)
                working = false
                callback(true,result);
            },
            (e)=>{
                working = false
                callback(false, e)
            },
            false
        )
        }
    },[])


    const deleteReportPage = useCallback((oldItem,sessionId,id,callback)=>{
  
        if(working === false)
        {
            working = true;

            sendRequest({
                url: `${apiConfig}reportpage/${id}?sessionId=${sessionId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'delete',
            },
            (data)=>{
                let newPages = oldItem.pages.filter((item)=>(item.id !== id))
                setReportItem({
                    ...oldItem,
                    pages: newPages
                })
                callback(true,data);
                working = false
            },
            (e)=>{
                working = false
                callback(false, e)
            },
            false
        )}

    },[])

    const auditUrls = useCallback((oldItem,sessionId,id,urls,callback)=>{
        if(working === false)
        {
            working = true;

            sendRequest({
                //url: `${apiConfig}auditor/master?sessionId=${sessionId}`,
                url: `${apiConfig}audit/add?sessionId=${sessionId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body:{
                    reportId:id,
                    pages:urls
                }
            },
            (data)=>{
                callback(true,data);
                // console.log(data)
                // setReportItem({
                //     ...oldItem,
                //     pages:data.pages
                // })
                working = false
            },
            (e)=>{
                working = false
                callback(false, e)
            },
            false
        )}

    },[])

    const xlsx = useCallback((reportid, callback)=>{
        if(working === false)
        {
            working = true;

            sendRequest({
                url: `${apiConfig}xlsx/${reportid}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get'
            },
            (data)=>{
                callback(true,data);
                working = false
            },
            (e)=>{
                working = false
                callback(false, e)
            },
            false
        )}
    },[])

    const updatePages = useCallback((pages)=>{
        setReportItem({
            ...reportItem,
            pages: pages
        })
    },[reportItem])

    const pickupGuidelines = useCallback((reportid, guidelineids, sessionId, callback)=>{
        if(working === false )
        {
            working = true;

            sendRequest({
                url: `${apiConfig}report/${reportid}/guidelines?sessionId=${sessionId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body:{
                    guidelineids
                }
            },
            (result)=>{
                let guidelines = guidelineids.map((id)=>{
                    return {
                        guideline_id: id
                    }
                });

     
                setReportItem({
                    ...reportItem,
                    guidelines: guidelines
                })
                working = false
                callback(true,result);
            },
            (e)=>{
                working = false
                callback(false, e)
            },
            false
        )
        }
    },[reportItem])

    return {
        isLoadingReportItem: isLoading,
        isErrorReportItem: error,
        reportItem:reportItem,
        pickupGuidelines:pickupGuidelines,
        loadReportItem: loadReportItem,
        updateReportItem:updateReportItem,
        deleteReportPage:deleteReportPage,
        auditUrls:auditUrls,
        updatePages: updatePages,
        xlsx: xlsx
    }
}

export default useReportItem;