/**
 * Edit Report heading.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
 import React, { Component } from 'react';

/**
 * Edit report heading.
 */
class Heading extends Component {
    /**
     * Render the heading.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <section className="edit-report-heading">
                <h2>Edit Report</h2>
            </section>
        );
    }
}

export default Heading;
