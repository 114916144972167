/**
* Add User form.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import { email, minLength, required } from 'js/validations';
import Form from 'js/Components/Forms/Raw/Form';
import noop from 'js/noop';
import organizationPropType from 'js/PropTypes/organization';
import OrganizationSelect from 'js/Components/Forms/OrganizationSelect';
import personPropType from 'js/PropTypes/person';
import rolePropType from 'js/PropTypes/role';
import RoleSelect from 'js/Components/Forms/RoleSelect';
import TextFormGroup from 'js/Components/Forms/Raw/TextFormGroup';
import Checkbox from 'js/Components/Forms/Raw/Checkbox';
import UserContext from 'js/Context/UserContext';
import PhoneTextGroup from './Raw/PhoneTextGroup';

/**
 * Add User form.
 */
class AddUserForm extends Component {
    static propTypes = {
        action: PropTypes.oneOf(['add', 'edit']),
        organizations: PropTypes.arrayOf(organizationPropType),
        onPersonSaved: PropTypes.func,
        person: personPropType,
        roles: PropTypes.arrayOf(rolePropType),
    };

    static defaultProps = {
        action: 'add',
        organizations: [],
        onPersonSaved: noop,
        person: null,
        roles: [],
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
     constructor(props) {
        super(props);

        this.state = {};
        this.validations = [
            {
                name: 'firstName',
                message: 'Please enter your first name. A first name is required.',
                validate: required,
            },
            {
                name: 'lastName',
                message: 'Please enter your last name. A last name is required.',
                validate: required,
            },
            {
                name: 'email',
                message: 'Please enter your email. An email is required.',
                validate: required,
            },
            {
                name: 'email',
                message: 'Please enter a valid email address. An email address is required.',
                validate: email,
            },
            {
                name: 'password',
                message: 'Password must be at least 8 characters long.',
                validate: minLength,
                params: {
                    min: 8,
                },
            },
            {
                name: 'password',
                message: 'Password must be entered.',
                validate: required,
            },
            {
                name: 'roles',
                message: 'A role must be selected',
                validate: required,
            },
            {
                name: 'confirm',
                message: 'Confirm password must be at least 8 characters long.',
                validate: minLength,
                params: {
                    min: 8,
                },
            },
            {
                name: 'confirm',
                message: 'Confirm password and password must be the same.',
                validate: (fields, field) => {
                    return field === fields.password;
                },
            }
        ];

        // Efficient early binding.
        this.onBeforeFormSubmit = this.onBeforeFormSubmit.bind(this);
        this.onPersonSaved = this.onPersonSaved.bind(this);
    }

    /**
     * Do any processing necessary before submitting the form.
     *
     * @param {object} data Form data.
     *
     * @returns {object}
     */
    onBeforeFormSubmit(data) {
        // Remove excluded fields
        delete data['confirm'];
        //console.log(data);
        // Force some fields to numeric for API compatibility
        ['roles', 'organizationId'].forEach((castNumberField) => {
            if (data[castNumberField]) {
                if (Array.isArray(data[castNumberField])) {
                    data[castNumberField] = data[castNumberField].map((fieldValue) => {
                        return Number.parseInt(fieldValue, 10);
                    });
                } else {
                    data[castNumberField] = Number.parseInt(data[castNumberField], 10);
                }
            }
        });
        if(!("forceChange" in data)){
            data['forceChange'] = false
        }

        return data;
    }

    /**
     * Handle event after save.
     *
     * @returns {void}
     */
    onPersonSaved(response, status, requestBody,unlockForceChange,personSession) {
        const { onPersonSaved, action,person,onFailValidation } = this.props;
        //console.log(response,requestBody)
        if (status !== 400 && onPersonSaved) {
            onPersonSaved(response, requestBody);
            if(action !== "add" && personSession.person.id === person.id && !!personSession.person.forceChange !== !!requestBody.forceChange)
            {
                unlockForceChange(!!requestBody.forceChange)
            }
        }else{
            onFailValidation()
        }
    }

    /**
     * Render the Add User form.
     *
     * @returns {React.node}
     */
    render() {
        const { action, organizations, person, roles,onFailValidation } = this.props;
        console.log(person,roles);
        return (
          <UserContext.Consumer>
            {({ personSession, unlockForceChange }) => (
              <Form
                afterSubmit={(response, status, requestBody) => {
                  this.onPersonSaved(
                    response,
                    status,
                    requestBody,
                    unlockForceChange,
                    personSession
                  );
                }}
                beforeSubmit={this.onBeforeFormSubmit}
                buttonLabel={null}
                defaultValues={person}
                id="add-edit-person-form"
                method={action === "add" ? "POST" : "PATCH"}
                url={`${apiConfig}person${
                  person && person.id ? `/${person.id}` : ""
                }?sessionId=${personSession.session.id}`}
                validations={this.validations}
                onFailValidation={onFailValidation}
              >
                <TextFormGroup
                  defaultValue={person ? person.firstName : null}
                  htmlFor="firstName"
                  label="First Name"
                  name="firstName"
                  data-lpignore="true"
                />
                <TextFormGroup
                  defaultValue={person ? person.lastName : null}
                  htmlFor="lastName"
                  label="Last Name"
                  name="lastName"
                  data-lpignore="true"
                />
                <OrganizationSelect
                  defaultValue={person ? person.organizationId : null}
                  label="Company"
                  name="organizationId"
                  organizations={organizations}
                  data-lpignore="true"
                />
                <TextFormGroup
                  defaultValue={person ? person.email : null}
                  htmlFor="email"
                  label="Email"
                  name="email"
                  data-lpignore="true"
                />
                <PhoneTextGroup
                  htmlFor="phone"
                  label="Phone"
                  name="phone"
                  defaultValue={person ? person.phone : null}
                  data-lpignore="true"
                />
                <TextFormGroup
                  htmlFor="password"
                  label="Password"
                  name="password"
                  type="password"
                  data-lpignore="true"
                />
                <TextFormGroup
                  htmlFor="confirm"
                  label="Confirm Password"
                  name="confirm"
                  type="password"
                  data-lpignore="true"
                />
                <Checkbox
                  htmlFor="forceChange"
                  label="Force Change Password"
                  name="forceChange"
                  style={{ minWidth: "auto", margin: "0" }}
                  value={
                    this.props.action === "add" ? true : !!person.forceChange
                  }
                  noChange={this.props.action === "add"}
                  data-lpignore="true"
                />
                <RoleSelect
                  defaultValue={
                    person && person.roles !== null
                      ? person.roles.map((role) => role.id)
                      : null
                  }
                  roles={roles}
                  multiple
                  name="roles"
                  data-lpignore="true"
                />
              </Form>
            )}
          </UserContext.Consumer>
        );
    }
}

export default AddUserForm;
