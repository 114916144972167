/**
* Country select component.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import React, { Component } from 'react';

import Select from 'js/Components/Forms/Raw/Select';
import countryCodeConfig from 'js/config/countryCodes';

/**
 * Country select component class.
 */
class CountrySelect extends Component {
    /**
     * Render the country select.
     *
     * @returns {React.node}
     */
    render() {
        const { ...passThroughProps } = this.props;

        return (
            <Select
                name="countryCode"
                options={countryCodeConfig}
                {...passThroughProps}
            />
        );
    }
}

export default CountrySelect;
