/**
* Organization List API Call.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import childrenPropType from 'js/PropTypes/children';
import LoadingIndicator from 'js/Components/LoadingIndicator';
import NetworkAlert from 'js/Components/Alert/NetworkAlert';
import {httpError401} from "js/Components/Api/useHttp";

/**
 * Organization List API Calling Class
 */
class OrganizationList extends Component {
    static propTypes = {
        children: childrenPropType,
        sessionId: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        sessionId: null,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            loading: true,
            organizations: [],
        };

        // Efficient early binding.
        this.onAddOrganization = this.onAddOrganization.bind(this);
        this.onDeleteOrganization = this.onDeleteOrganization.bind(this);
        this.onUpdateOrganization = this.onUpdateOrganization.bind(this);
    }

    /**
     * Load organizations after the component mounts.
     * This is to avoid warning about calling setState before mounting.
     *
     * @returns {void}
     */
    componentDidMount() {
        // Load organizations from API
        this.loadOrganizations();
    }

    /**
     * Refresh organization list.
     *
     * @returns {void}
     */
    onOrganizationRefresh() {
        this.setState({
            error: false,
            loading: true,
            organizations: [],
        });
        this.loadOrganizations();
    }

    /**
     * Method to add new organization to the collection.
     *
     * @param {object} newOrganization
     *
     * @returns {void}
     */
    onAddOrganization(newOrganization) {
        const { organizations } = this.state;

        organizations.push(newOrganization);

        this.setState({
            organizations,
        });
    }

    /**
     * Update the specified organization in the list of organizations.
     *
     * @param {number|string} id
     * @param {object} updatedOrganization
     *
     * @returns {void}
     */
    onUpdateOrganization(id, updatedOrganization) {
        let { organizations: updatedOrganizations } = this.state;

        // Remove the edited user.
        updatedOrganizations = updatedOrganizations.filter((user) => {
            return user.id !== id;
        });

        // Push the new version of the object
        updatedOrganizations.push(updatedOrganization);

        this.setState({
            organizations: updatedOrganizations,
        });
    }

    /**
     * Delete the organization via API and state.
     *
     * @param {number|string} id
     *
     * @returns {void}
     */
    onDeleteOrganization(id) {
        const { sessionId } = this.props;

        fetch(
            `${apiConfig}organization/${id}?sessionId=${sessionId}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'delete',
            })
            .then((response) => {
                const { status } = response;

                if(status === 401)
                {
                    httpError401();
                }
                else if (status !== 200) {
                    return this.setState({
                        error: true,
                        loading: false,
                    });
                }

                response
                    .json()
                    .then(() => {
                        let { organizations: updatedOrganizations } = this.state;

                        // Remove the deleted organization.
                        updatedOrganizations = updatedOrganizations.filter((organization) => {
                            return organization.id !== id && organization.parent_id !== id;
                        });

                        this.setState({
                            organizations: updatedOrganizations,
                        });
                    });
            });
    }

    /**
     * Load all Organizations.
     *
     * @returns {void}
     */
    loadOrganizations() {
        const { sessionId } = this.props;

        fetch(
            `${apiConfig}organization/list?sessionId=${sessionId}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
            })
            .then((response) => {
                const { status } = response;

                if(status === 401)
                {
                    httpError401();
                }
                else if (status !== 200) {
                    return this.setState({
                        error: true,
                        loading: false,
                    });
                }

                response
                    .json()
                    .then((body) => {
                        const newState = {};

                        newState.organizations = body;
                        newState.error = false;
                        newState.loading = false;
                        return this.setState(newState);
                    });
            });
    }

    /**
     * Render the organization list API call children.
     *
     * @returns {React.node|object}
     */
    render() {
        const { children, ...passThroughProps } = this.props;
        const { error, loading, organizations } = this.state;

        if (error) {
            return (
                <NetworkAlert
                    label="organization list"
                />
            );
        }

        if (loading) {
            return (
                <LoadingIndicator
                    label="Loading companies..."
                />
            );
        }

        return React.Children.map(children, (child) => {
            if (child === null || child === undefined) {
                return null;
            }

            if (typeof child === 'string') {
                return child;
            }

            return React.cloneElement(child, {
                onAddOrganization: this.onAddOrganization,
                onDeleteOrganization: this.onDeleteOrganization,
                onUpdateOrganization: this.onUpdateOrganization,
                organizations,
                ...passThroughProps,
            });
        });
    }
}

export default OrganizationList;
