/**
 * Breadcrumb.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

/**
 * Page Footer class.
 */
class Footer extends Component {
    /**
     * Render page footer.
     *
     * @returns {React.node}
     */
    render() {
        const { description, label } = this.props;

        return (
            <section className="breadcrumb">
                <h1>{label}</h1>
                <p>{description}</p>
            </section>
        );
    }
}

export default Footer;
