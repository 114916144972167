/**
 * TableHeader Component.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import childrenPropType from 'js/PropTypes/children';

/**
 * TableHeader Class
 */
class TableHeader extends Component {
    static propTypes = {
        children: childrenPropType,
    };

    static defaultProps = {
        children: null,
    };

    /**
     * Render the table.
     *
     * @returns {React.node}
     */
    render() {
        const { children } = this.props;

        return (
            <thead>
                {children}
            </thead>
        );
    }
}

export default TableHeader;
