/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { PureComponent } from 'react';
import SeverityLegendTable from 'js/Components/Report/SeverityLegendTable';
import metricsShape from 'js/PropTypes/metricsShape';
import writeXlsxFile from 'write-excel-file'
import xlsxSummaryByMetrics from 'js/calculation/xlsxSummaryByMetrics';

class PagesOverviewTables extends PureComponent {
    static propTypes = {
        metrics: metricsShape.isRequired,
    };

    constructor(props){
      super(props)

      this.handleXlsx = this.handleXlsx.bind(this)
    }

    async handleXlsx(){
      const {metrics} = this.props;
      try{
        let xlsxData = xlsxSummaryByMetrics(metrics)
        
        let resultBlob = await writeXlsxFile(xlsxData, {
          filePath: 'summary.xlsx'
        })
        const url = URL.createObjectURL(resultBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'summary.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }catch(error){
        console.error(error)
      }
    }

    renderPagesOverviewTable() {
        const { metrics: { principles },uid, pdf } = this.props;

        // Fetch the header labels for each evaluated page
        const pageHeaders = principles[0].pages.map(({ slug, name }) => (
            <th key={slug} className="page-heading">{name}</th>
        ));

        // let table = [];
        // let first = true;
        // let pagesArr = [];
        // this.props.metrics.principles.map((principle) => {
        //   if (first) {
        //     let header = [
        //       "Principle",
        //       "Guideline Failed/Total",
        //       "Overall Severity Score",
        //     ];
        //     pagesArr = principle.pages.map((page) => {
        //       return page.name;
        //     });
        //     table.push(header.concat(pagesArr));
        //     first = false;
        //   }
        //   //Add Summary Data
        //   let row = [
        //     principle["name"],
        //     `${principle["total"] - principle["passed"]}/${principle["total"]}`,
        //     principle["severityLabel"],
        //   ];
        //   principle.pages.map((page) => {
        //     row.push(page.severity);
        //   });
        //   //Add Table
        //   table.push(row);
        // });

        const tableRows = principles.map((principle) => {
            const {
                slug,
                name,
                passed,
                total,
                severityLabel,
                pages,
            } = principle;
            const failed = total == 0 ? `NE` : `${total - passed}/${total}`;

            const pageCells = pages.map(({ slug: pageSlug, severity }) => (
                <td key={pageSlug} className={`severity-cell severity-${total == 0 ? 'NE' : severity}`}>
                    {severity > 0 ? (
                        <span aria-hidden="true" className="severity-label">
                            {severity}
                        </span>
                    ) : null}
                    <span className="a11y-sr-only">
                        {`Level ${severity} severity`}
                    </span>
                </td>
            ));

            return (
                <tr key={slug}>
                    <td>{name}</td>
                    <td>{failed}</td>
                    <td>{severityLabel}</td>
                    {pageCells}
                </tr>
            );
        });

        return (
          <div
            className="table-responsive"
            style={{ maxWidth: "100vh + 9rem", overflowX: "auto" }}
          >
            <table className="table table-with-severity table-overview-pages-severity">
              <caption>Figure 4</caption>
              <thead>
                <tr>
                  <th>Principle</th>
                  <th>Guidelines Failed / Total</th>
                  <th>Overall Severity Score</th>
                  {pageHeaders}
                </tr>
              </thead>
              <tbody>{tableRows}</tbody>
            </table>
            {
              pdf ?
              <a href={`${window.location.origin}/xlsx/summary/${uid}`} target="_blank">Download XLSX</a>
              :<a
                onClick={this.handleXlsx}
              >
                Download XLSX
              </a>
            }
          </div>
        );
    }

    render() {
        return (
            <section className="overview-pages-tables">
                <SeverityLegendTable />
                {this.renderPagesOverviewTable()}
            </section>
        );
    }
}

export default PagesOverviewTables;
