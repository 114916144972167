/**
 * Form Select Component.
 *
 * @author Ryan Johnston <ryan.jonhnston@scalesology.com>
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FormGroupError from 'js/Components/Forms/Raw/FormGroupError';
import Label from 'js/Components/Forms/Raw/Label';
import noop from 'js/noop';

/**
 * Select Form Element.
 */
class Select extends Component {
    static propTypes = {
        errorMessage: PropTypes.string,
        icon: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        onChange: PropTypes.func,
        options: PropTypes.object,
        firstEmpty:  PropTypes.bool
    };

    static defaultProps = {
        errorMessage: null,
        icon: null,
        label: '',
        name: null,
        onChange: noop,
        options: {},
        firstEmpty:false
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {};

        // Efficient Early Binding.
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    /**
     * Add name to change method propagated to form.
     *
     * @param {event} e Synthetic React Event.
     *
     * @returns {void}
     */
    onSelectChange(e) {
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(name, e);
        }
    }

    /**
     * Render select options.
     *
     * @returns {React.node|array}
     */
    renderOptions() {
        const { options, selected, firstEmpty, defaultValue } = this.props;
        let list = [];


        if (!options) {
            return list;
        }
  
        list = Object.keys(options).map((val) => {
            return val === defaultValue ? (
              <option key={val} value={val} selected>
                {options[val]}
              </option>
            ) : (
              <option key={val} value={val}>
                {options[val]}
              </option>
            );
        });

        if(firstEmpty === true){
        
            list.unshift(
                <option key={''} value={null}></option>
            )
        }   


        return list;
    }

    /**
     * Render Select Form Group.
     *
     * @returns {React.node}
     */
    render() {
        // Extract errorMessage/onChange/options/label to prevent sending select html element.
        const { errorMessage, htmlFor, icon, label, onChange, options,selected,firstEmpty,defaultValue, ...selectProps } = this.props;
        const groupClasses = classNames({
            'form-group': true,
            'no-label': !label,
        });

        return (
            <div className={groupClasses}>
                <Label
                    htmlFor={htmlFor}
                    label={label}
                />
                <select className="form-control" {...selectProps} onChange={this.onSelectChange} selected={defaultValue}>
                    {this.renderOptions()}
                </select>
                <FormGroupError
                    errorMessage={errorMessage}
                />
            </div>
        );
    }
}

export default Select;
