/**
* Network Alert.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Alert from 'js/Components/Alert/Raw/Alert';
import { FaUnlink } from 'react-icons/fa';

/**
 * Network Alert Class
 */
 class NetworkAlert extends Component {
    static propTypes = {
        label: PropTypes.string,
    };

    static defaultProps = {
        label: null,
    };

    /**
     * Render the network error alert.
     *
     * @returns {React.node}
     */
    render() {
        const { label } = this.props;

        return (
            <Alert
                closable={false}
                icon={<FaUnlink />}
                warning
            >
                Please check your internet connection or try refreshing the page.<br />
                There was an error loading the { label } from the server.<br />
                If the problem persists, please contact support.
            </Alert>
        );
    }
}

export default NetworkAlert;
