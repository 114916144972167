/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import Papa from 'papaparse';

function encodeData(data, type) {
    return `data:${type};charset=utf-8,${encodeURIComponent(data)}`;
}

function encodeJson(data) {
    return JSON.stringify(data, null, 2);
}

function encodeCsv(data) {
    if (!data || data.length === 0) {
        return '';
    }

    const fields = Object.keys(data[0]).map((key) => key);

    return Papa.unparse({ fields, data });
}

const functions = {
    'text/json': encodeJson,
    'text/csv': encodeCsv,
};

function encodeDataString(data, type) {
    const encoder = functions[type];

    if (encoder === undefined) {
        throw new Error(`No encoder available for type '${type}'`);
    }

    return encodeData(encoder(data), type);
}

export default encodeDataString;
