/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Link from "../Link";
import apiConfig from "js/config/api";
import principlePropType from "js/PropTypes/principle";
import reportShape from "js/PropTypes/reportShape";
import { FaFileDownload } from "react-icons/fa";
import {httpError401} from "js/Components/Api/useHttp";

const navigationMap1 = {
  top: {
    name: "Scroll To Top",
  },
  "website-overview": {
    name: "Website Overview",
    children: {
      "wcag-principles": {
        name: "WCAG Principles",
      },
    },
  },
  "pages-overview": {
    name: "Pages Overview",
    children: {
      summary: {
        name: "Summary",
      },
    },
  },
  "detailed-summary-by-web-page": {
    name: "Detailed Summary",
    children: {},
  },
  "appendix-a": {
    name: "Appendix A",
    children: {
      definitions: {
        name: "Definitions",
      },
      "wcag-20-level-aa": {
        name: "WCAG 2.0 Level AA",
      },
    },
  },
};

const navigationMap2 = {
  "top": {
    name: "Scroll To Top",
  },
  "website-overview": {
    name: "Website Overview",
    children: {
      "wcag-principles": {
        name: "WCAG Principles",
      },
    },
  },
  "pages-overview": {
    name: "Pages Overview",
    children: {
      summary: {
        name: "Summary",
      },
    },
  },
  "detailed-summary-by-web-page": {
    name: "Detailed Summary",
    children: {},
  },
  "appendix-a": {
    name: "Appendix A",
    children: {
      definitions: {
        name: "Definitions",
      },
      "wcag-21-level-aa": {
        name: "WCAG 2.1 Level AA",
      },
    },
  },
};

const navigationMap3 = {
  "top": {
    name: "Scroll To Top",
  },
  "website-overview": {
    name: "Website Overview",
    children: {
      "wcag-principles": {
        name: "WCAG Principles",
      },
    },
  },
  "pages-overview": {
    name: "Pages Overview",
    children: {
      summary: {
        name: "Summary",
      },
    },
  },
  "detailed-summary-by-web-page": {
    name: "Detailed Summary",
    children: {},
  },
  "appendix-a": {
    name: "Appendix A",
    children: {
      definitions: {
        name: "Definitions",
      },
      "wcag-21-level-aaa": {
        name: "WCAG 2.1 Level AAA",
      },
    },
  },
};

const navigationMap4 = {
  "top": {
    name: "Scroll To Top",
  },
  "website-overview": {
    name: "Website Overview",
    children: {
      "wcag-principles": {
        name: "WCAG Principles",
      },
    },
  },
  "pages-overview": {
    name: "Pages Overview",
    children: {
      summary: {
        name: "Summary",
      },
    },
  },
  "detailed-summary-by-web-page": {
    name: "Detailed Summary",
    children: {},
  },
  "appendix-a": {
    name: "Appendix A",
    children: {
      definitions: {
        name: "Definitions",
      },
      "wcag-22-level-aa": {
        name: "WCAG 2.2 Level AA",
      },
    },
  },
};

class SideNavigation extends PureComponent {
  static propTypes = {
    principles: PropTypes.arrayOf(principlePropType),
    report: reportShape.isRequired,
    pdf: PropTypes.bool,//Is Generating PDF
    uid: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      prevID: "",
    };

    // Efficient early binding
    this.changeBold = this.changeBold.bind(this);
    this.scrollToTargetAdjusted = this.scrollToTargetAdjusted.bind(this);
    this.handleGetLink = this.handleGetLink.bind(this)
  }

  async handleGetLink(e) {
    const { uid } = this.props;

    e.preventDefault();
    try{
      const response = await fetch(
        `${apiConfig}report/pdf?uid=${uid}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "get",
        }
      );
      let data = await response.json();
      if(response.status === 401)
      {
          httpError401();
      }

      else if (response.status !== 200) {
        throw Error("Something Wrong!");
      }

      const a = document.createElement('a');
      a.href = data.url;
      a.download = 'Scalesology ADA Web Audit.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    } catch (e) {
      console.error(e);
    }
  }

  getNavigationNodes() {
    const {
      principles,
      report: { pages },
      report,
    } = this.props;

    const reportLevel = report.reportLevel;
    let nodes;
    if (reportLevel === 1) {
      nodes = cloneDeep(navigationMap1);
    } else if (reportLevel === 2) {
      nodes = cloneDeep(navigationMap2);
    } else if (reportLevel === 3) {
      nodes = cloneDeep(navigationMap3);
    } else if(reportLevel === 4){
      nodes = cloneDeep(navigationMap4);
    } else if(reportLevel === 5){
      nodes = cloneDeep(navigationMap4);
    }


    // Add page headers
    // ToDo: Add principle nodes under
    pages.forEach(({ slug, name }) => {
      const children = {};
      principles.forEach(({ slug: principleSlug, name: principleName }) => {
        children[`${slug}-${principleSlug}`] = { name: principleName };
      });

      nodes["detailed-summary-by-web-page"].children[`${slug}-detailed`] = {
        name,
        children,
      };
    });

    return nodes;
  }

  scrollToTargetAdjusted(element) {
      var headerOffset = 70;
        var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      
      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });   
  }

  changeBold(id) {
    const { prevID } = this.state;
    if (prevID === "") {
      this.setState({ prevID: `--${id}` });
      const ele = document.getElementById(`--${id}`);
      ele.setAttribute("style", "font-weight:bold");
      this.scrollToTargetAdjusted(ele)
    } else if (id !== prevID) {
      document
        .getElementById(prevID)
        .removeAttribute("style", "font-weight:bold");
      document
        .getElementById(`--${id}`)
        .setAttribute("style", "font-weight:bold");
      this.setState({ prevID: `--${id}` });
      const ele = document.getElementById(`${id}`);
      this.scrollToTargetAdjusted(ele);
    }
  }

  renderNodes(nodes) {
    const {
      report: { reportLevel },
    } = this.props;

    const items = Object.keys(nodes).map((id) => {
      const { name, children } = nodes[id];

      // Skip links to higher diagnostics level
      if (id.includes("level-")) {
        const nodeLevel = parseInt(id.split("level-")[1].charAt(0), 10);

        if (nodeLevel > reportLevel) {
          return null;
        }
      }

      return (
        <li key={id}>
          <p
            className="anchorTags"
            // href={`#${id}`}
            onClick={() => this.changeBold(id)}
            id={`--${id}`}
          >
            {children !== undefined && name !== "Detailed Summary" && name !== 'Website Overview' && name !== 'Pages Overview' && name !== 'Appendix A'
              ? `${name} - Page`
              : name}
            {/* {name} */}
          </p>
          {children !== undefined ? this.renderNodes(children) : null}
        </li>
      );
    });
    return <ol>{items}</ol>;
  }

  render() {
    const { report } = this.props;

    const nodes = this.getNavigationNodes();
    return (
      <nav className="section-nav">
        {report.downloadURL === (null || undefined) ? null : (
          <div className="download-container">
            <div className="download-div-1">
              <Link
                onClick={this.handleGetLink}
                className="downloadLink"
                ref=""
                href=""
                label={
                  <>
                    <p>
                      <u>Download PDF Report</u>
                    </p>
                    <span className="a11y-sr-only">Download Report</span>
                  </>
                }
              />
            </div>
            <div className="download-div-2">
              <Link
                className="downloadLink"
                label={
                  <>
                    <FaFileDownload aria-hidden="true" size={30} />
                    <span className="a11y-sr-only">Download Report</span>
                  </>
                }
                onClick={this.handleGetLink}
                href=""
              />
            </div>
          </div>
        )}
        {this.renderNodes(nodes)}
      </nav>
    );
  }
}

export default SideNavigation;
