/**
 * Request Report Modal Component.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'js/Components/Forms/Raw/Button';
import Modal from 'js/Components/Modal/Raw/Modal';
import ModalBody from 'js/Components/Modal/Raw/ModalBody';
import ModalFooter from 'js/Components/Modal/Raw/ModalFooter';
import ModalHeader from 'js/Components/Modal/Raw/ModalHeader';
import noop from 'js/noop';
import reportLevelListPropType from 'js/PropTypes/reportLevelList';
import RequestReportForm from 'js/Components/Forms/RequestReportForm';
import UserContext from 'js/Context/UserContext';

/**
 * Add User Modal class.
 */
class RequestReportModal extends Component {
    static propTypes = {
        levels: reportLevelListPropType,
        onClose: PropTypes.func,
        open: PropTypes.bool,
    };

    static defaultProps = {
        levels: {},
        onClose: noop,
        open: false,
        click: false
    };

        /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            success: false,
        };
        
        this.onSuccess = this.onSuccess.bind(this);
        this.onClose = this.onClose.bind(this)
        this.onSubmit = this.onSubmit.bind(this);
        this.onFailValidation = this.onFailValidation.bind(this);
    }

    onSuccess(_,code){
        if(code === 200 || code===201){
            this.setState({success:true})
        }
        this.setState({click:false});
    }

    onSubmit(e){

        if(this.state.click === true){
            e.preventDefault(); 
            return;
        }
        this.setState({click:true})
    }

    onClose(e){
        const {onClose} = this.props;

        if(this.state.success)
        {
            onClose(e,true)
        }
        else{
            onClose(e)
        }
    }

    onFailValidation(){
        this.setState({click:false})
    }

    /**
     * Render Request Report Modal.
     *
     * @returns {React.node}
     */
    render() {
        const { levels, open } = this.props;

        return (
            <Modal
                className="request-report-modal"
                onClose={this.onClose}
                open={open}
            >
                <ModalHeader>
                    Request a new audit
                </ModalHeader>
                <ModalBody>
                    <UserContext.Consumer>
                    { ({ personSession,hasPermission}) =>(
                    <RequestReportForm
                        levels={levels}
                        onSuccess={this.onSuccess}
                        personSession={personSession}
                        hasPermission={hasPermission}
                        onFailValidation={this.onFailValidation}
                    />)
                    }
                     </UserContext.Consumer>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={this.onClose}
                        title="Cancel"
                    >
                       {this.state.success?"OK":"Cancel"}
                    </Button>
                    {!this.state.success &&
                    <Button
                        form="request-report-form"
                        primary
                        title="Request Audit"
                        type="submit"
                        onClick={this.onSubmit}
                    >
                        Request Audit
                    </Button>
                        }
                </ModalFooter>
            </Modal>
        );
    }
}

export default RequestReportModal;
