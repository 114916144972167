/**
 * Sign In page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import SignInLogo from 'img/sign-in-logo.png'
import SignInForm from 'js/Components/Forms/SignInForm';
import SignUpBlock from 'js/Components/Blocks/SignUpBlock';
import UserContext from 'js/Context/UserContext';
import Button from "js/Components/Forms/Raw/Button";

/**
 * Sign In page.
 */

function onClick(e) {
  window.location.href = `mailto:ada-support@scalesology.com`;
  e.preventDefault();
}
class SignIn extends Component {
    /**
     * Render the login page.
     *
     * @returns {React.node}
     */
    render() {
        return (
          <div className="signin-container">
            <img src={SignInLogo} alt="Scalesology Logo" />
            <h1>Sign in to Scalesology ADA Website Compliance Portal</h1>
            <UserContext.Consumer>
              {(userContext) => (
                <SignInForm
                  signedInUser={userContext.signedInUser}
                  refreshUser={userContext.refreshUser}
                />
              )}
            </UserContext.Consumer>
            <div style={{margin: "0.5rem"}}></div>
            <Button
              onClick={onClick}
              title="Support"
              aria-label="Support"
              primary
              label="Support"
            >
              Support
            </Button>
            {/* <SignUpBlock /> */}
          </div>
        );
    }
}

export default SignIn;
