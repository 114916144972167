/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { PureComponent } from 'react';

import PagesOverviewTables from 'js/Components/Report/PagesOverview/PagesOverviewTables';
import LanguageText from 'js/Components/Utils/LanguageText';
import MarkdownText from 'js/Components/Utils/MarkdownText';
import SectionWithHeading from 'js/Components/Utils/SectionWithHeading';
import metricsShape from 'js/PropTypes/metricsShape';
import reportShape from 'js/PropTypes/reportShape';
import LegalNote from '../LegalNote';
import PropTypes from 'prop-types';

class PagesOverview extends PureComponent {
    static propTypes = {
        metrics: metricsShape.isRequired,
        report: reportShape.isRequired,
        pdf: PropTypes.bool
    }

    render() {
        const { metrics, report: { summary,uid }, pdf } = this.props;

        return (

            <SectionWithHeading className="overview-pages pages-overview-container" heading="Pages Overview" level={2}>
                <LegalNote/>
                <PagesOverviewTables metrics={metrics} uid={uid} pdf={pdf}/>
                <LanguageText languageKey="pagesOverviewTable" />
                <SectionWithHeading heading="Summary" level={3}>
                    <MarkdownText text={summary} />
                </SectionWithHeading>
            </SectionWithHeading>
        );
    }
}

export default PagesOverview;
