/**
 * Front end Account Settings page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Breadcrumb from 'js/Components/Breadcrumb/Breadcrumb';
import PersonItem from 'js/Components/Api/PersonItem';
import PageWrapper from 'js/Components/Pages/PageWrapper';
import SecuritySettingsForm from 'js/Components/Forms/SecuritySettingsForm';
import SettingsBlock from 'js/Components/Blocks/SettingsBlock';
import UserContext from 'js/Context/UserContext';
import UserInfoForm from 'js/Components/Forms/UserInfoForm';



/**
 * Front end Account Settings page.
 */
class AccountSettings extends Component {
    /**
     * Render Account Settings page.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <UserContext.Consumer>
             { ({ personSession,unlockForceChange }) => {
                return (
                <PageWrapper
                    description="Settings"
                    title="Settings"
                >
                    <Breadcrumb
                        label={personSession.person.forceChange? "Change Password":"Account Settings"}
                        description= {personSession.person.forceChange? "Please reset your password":"View and update your account details, profile, and more."} 
                    />
                    { !personSession.person.forceChange &&
                        <SettingsBlock
                            label="Basic Info"
                        >
                        
                            <PersonItem
                                id={personSession.person.id}
                                sessionId={personSession.session.id}
                            >
                                <UserInfoForm />
                            </PersonItem>
                    
                        </SettingsBlock>
                    }          
                    <SettingsBlock
                        label="Security"
                    >
                        <PersonItem
                            id={personSession.person.id}
                            sessionId={personSession.session.id}
                            isLoad={false}
                        >
                            <SecuritySettingsForm 
                                unlockForceChange={unlockForceChange}
                            />
                        </PersonItem>
                    </SettingsBlock>
                </PageWrapper>
                )}}
             </UserContext.Consumer>
        );
    }
}

export default AccountSettings;
