/**
 * Front end PDF Report page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import AdaReport from 'js/Components/Report/AdaReport';
import matchPropType from 'js/PropTypes/match';
import PageWrapper from 'js/Components/Pages/PageWrapper';
import List from "../Api/List";

/**
 * Front end PDF Report page.
 */
class PdfReport extends Component {
    static propTypes = {
        match: matchPropType,
    };

    static defaultProps = {
        match: {
            params: {},
        },
    };

    /**
     * Render Report page.
     *
     * @returns {React.node}
     */
    render() {
        const { match: { params: { id } } } = this.props;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('sessionId');
        let pdf = true;

        if(urlParams.get('preview')){
            pdf = false;
        }
        console.log(pdf);
        return (
            <PageWrapper
                description="ADA Report"
                title="ADA Report"
            >
                <List
                    id={id}
                    sessionId={sessionId}
                    pdf={pdf}
                    page={"pdfreport"}
                >
                    <AdaReport
                        sessionId={sessionId}
                        pdf={pdf}
                    />
                </List>
            </PageWrapper>
        );
    }
}

export default PdfReport;
