/**
* Organization Item API Call.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import childrenPropType from 'js/PropTypes/children';
import LoadingIndicator from 'js/Components/LoadingIndicator';
import NetworkAlert from 'js/Components/Alert/NetworkAlert';
import {httpError401} from "js/Components/Api/useHttp";

/**
 * Organization Item API Calling Class
 */
class OrganizationItem extends Component {
    static propTypes = {
        children: childrenPropType,
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        sessionId: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        id: null,
        sessionId: null,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            loading: true,
            organization: {},
        };

        // Load organizations from API
        this.loadOrganization(props.id);

        // Efficient early binding
        this.onOrganizationUpdate = this.onOrganizationUpdate.bind(this);
    }

    /**
     * If organization changes, lookup next organization.
     *
     * @param nextProps
     */
    componentWillReceiveProps(nextProps) {
        const { id } = this.props;

        if (id !== nextProps.id) {
            this.loadOrganization(nextProps.id);
        }
    }

    /**
     * Load all Organizations.
     *
     * @returns {void}
     */
    loadOrganization(id) {
        const { sessionId } = this.props;

        if (id === null) { // no-op
            return;
        }

        fetch(
            `${apiConfig}organization/${id}?sessionId=${sessionId}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
            })
            .then((response) => {
                const { status } = response;

                if(status === 401)
                {
                    httpError401();
                }
                else if (status !== 200) {
                    return this.setState({
                        error: true,
                        loading: false,
                    });
                }

                response
                    .json()
                    .then((body) => {
                        const newState = {};

                        newState.organization = body;
                        newState.error = false;
                        newState.loading = false;
                        return this.setState(newState);
                    });
            });
    }

    /**
     * Handle organization update.
     *
     * @param {object} newOrganization Updated organization fields.
     */
    onOrganizationUpdate(newOrganization) {
        const { sessionId } = this.props;

        delete newOrganization.createdAt;
        delete newOrganization.updatedAt;

        fetch(
            `${apiConfig}organization/${newOrganization.id}?sessionId=${sessionId}`,
            {
                body: JSON.stringify(newOrganization),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'PATCH',
            })
            .then((response) => {
                const { status } = response;

                if(status === 401)
                {
                    httpError401();
                }
                else if (status !== 200) {
                    return this.setState({
                        error: true,
                        loading: false,
                    });
                }

                response
                    .json()
                    .then(() => {
                        // Note, the body returned here is an empty object (stringified)
                        const newState = {};

                        newState.organization = newOrganization;
                        newState.error = false;
                        newState.loading = false;
                        return this.setState(newState);
                    });
            });
    }

    /**
     * Render the organization item API call children.
     *
     * @returns {React.node|object}
     */
    render() {
        const { children, ...passThroughProps } = this.props;
        const { error, loading, organization } = this.state;

        if (error) {
            return (
                <NetworkAlert
                    label="organization"
                />
            );
        }

        if (loading) {
            return (
                <LoadingIndicator
                    label="Loading company..."
                />
            );
        }

        return React.Children.map(children, (child) => {
            if (child === null || child === undefined) {
                return null;
            }

            if (typeof child === 'string') {
                return child;
            }

            return React.cloneElement(child, {
                onOrganizationUpdate: this.onOrganizationUpdate,
                organization,
                ...passThroughProps,
            });
        });
    }
}

export default OrganizationItem;
