import react from "react";
import {domainOnly} from "js/config/api";

const LegalNote = ()=>{
    console.log(domainOnly);
    return (
      <div className={"report-legalnote"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#deebff",
            boxSizing: "border-box",
            padding: "1rem",
            borderRadius: "5px",
            margin: "1rem 0",
          }}
        >
          <div
            style={{
              backgroundColor: "#0052cc",
              width: "1.25rem",
              height: "1.25rem",
              color: "white",
              borderRadius: "50%",
              textAlign: "center",
              lineHeight: "1.25rem",
              userSelect: "none",
              margin: "0 1rem 0 0",
              flex: " 0 0 auto",
            }}
          >
            i
          </div>
          <div>
            NOTE: All web pages may not be displayed in this table. To see the
            complete table, please view the report in the{" "}
            <a
              href={`${domainOnly}/dashboard`}
              target="_blank"
              rel="noreferrer"
            >
              Scalesology ADA Website Audit Portal
            </a>
            .
          </div>
        </div>
      </div>
    );
}

export default LegalNote