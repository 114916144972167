/**
 * Edit Report Page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CollapseBox from 'js/Components/EditReport/Raw/CollapseBox';
import guidelinePropType from 'js/PropTypes/guideline';
import reportPagePropType from 'js/PropTypes/reportPage';
import PrincipleList from 'js/Components/EditReport/PrincipleList';
import principlePropType from 'js/PropTypes/principle';
import IconButton from '../Raw/IconButton';

/**
 * Edit report page class.
 */
class Page extends Component {
    static propTypes = {
        guidelines: PropTypes.arrayOf(guidelinePropType),
        page: reportPagePropType,
        principles: PropTypes.arrayOf(principlePropType),
        reportLevel: PropTypes.number,
    };

    static defaultProps = {
        guidelines: [],
        page: {
            name: null,
        },
        principles: [],
        reportLevel: 1,
    };

    /**
     * Render the edit report page.
     *
     * @returns {React.node}
     */
    render() {
        const { guidelines, page, principles, page: { name },id,deleteReportPage,sessionId,reportItem, disableLoading,setLoading} = this.props;
        return (
            <>
                <div style={{display:'flex',justifyContent:"space-between",marginBottom:"16px",alignItems:"center"}}>
                    <div style={{flex:"1 1 100%",width:"100%",paddingRight:"10px"}}>
                        <a href={page.url} target="_blank">{page.url}</a>
                    </div>
                    <div style={{flex:"0 0 auto",width:"auto"}}>
                        <IconButton
                            text="Delete This Page"
                            type="delete"
                            onClick={()=>{
                                setLoading();
                                deleteReportPage(reportItem,sessionId,page.id,()=>{
                                    disableLoading();
                            })}}
                        />
                    </div>
                </div>
                <CollapseBox
                    className="edit-report-page"
                    label={name}
                    actionComponent={<div>acb</div>}
                    actionOnClick={()=>{console.log('abc');}}
                >
    
                    <PrincipleList
                        guidelines={guidelines}
                        page={page}
                        principles={principles}
                        id={id}
                    />
                </CollapseBox>
            </>
        );
    }
}

export default Page;
