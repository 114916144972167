/**
 * Error message alert.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * Conditionally rendered form group error.
 */
class FormGroupError extends Component {
    static propTypes = {
        errorMessage: PropTypes.string,
    };

    static defaultProps = {
        errorMessage: null,
    };

    /**
     * Render error message if provided.
     *
     * @returns {React.node|null}
     */
    render() {
        const { errorMessage } = this.props;
        
        if (errorMessage) {
            return (
                <div className="form-group-error">
                    {errorMessage}
                </div>
            );
        }

        return null;
    }
}

export default FormGroupError;
