import React,{useState, useCallback} from "react";

import useReportList  from "js/Components/Api/useReportList";
import useReportLevel from "js/Components/Api/useReportLevel";
import usePrincipleList from "js/Components/Api/usePrincipleList";
import useGuidelineList from "js/Components/Api/useGuidelineList";
import useReportItem from "js/Components/Api/useReportItem";
import useReportViolation from "js/Components/Api/useReportViolation";



export const GlobalStoreContext = React.createContext({
})



const GlobalStoreContextProvider = (props) =>{
    const reportlist  = useReportList();
    const reportlevel = useReportLevel();
    const principles = usePrincipleList();
    const guidelines = useGuidelineList();
    const reportItem = useReportItem();
    const reportViolation = useReportViolation();

   // console.log(reportlevel);
    return(
        <GlobalStoreContext.Provider value={{
            ...reportlist,
            ...reportlevel,
            ...principles,
            ...guidelines,
            ...reportItem,
            ...reportViolation

        }}>
            {props.children}
        </GlobalStoreContext.Provider>
    )

}

export default GlobalStoreContextProvider;