/**
 * Page Header Navigation Group.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';

import childrenPropTypes from 'js/PropTypes/children';
import Dropdown from 'js/Components/Dropdown/Dropdown';

/**
 * Page Header Navigation Group class.
 */
class Group extends Component {
    static propTypes = {
        children: childrenPropTypes,
        className: PropTypes.string,
        label: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        className: null,
        label: null,
    };

    /**
     * Render page header navigation group.
     *
     * @returns {React.node}
     */
    render() {
        const { children, className, label } = this.props;
        const dropdownClasses = classNames({
            'nav-group': true,
            [className]: className,
        });

        return (
            <li>
                <Dropdown
                    className={dropdownClasses}
                    label={
                        <>
                            {label}
                            <IoChevronDownOutline />
                        </>
                    }
                    tabIndex={0}
                >
                    <ul>
                        {children}
                    </ul>
                </Dropdown>
            </li>
        );
    }
}

export default Group;
