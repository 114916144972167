/**
* Role List API Call.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import childrenPropType from 'js/PropTypes/children';
import LoadingIndicator from 'js/Components/LoadingIndicator';
import NetworkAlert from 'js/Components/Alert/NetworkAlert';
import {httpError401} from "js/Components/Api/useHttp";

/**
 * Role List API Calling Class
 */
class RoleList extends Component {
    static propTypes = {
        children: childrenPropType,
        sessionId: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        sessionId: null,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            loading: true,
            roles: [],
        };
    }

    /**
     * Load roles after the component mounts.
     * This is to avoid warning about calling setState before mounting.
     *
     * @returns {void}
     */
    componentDidMount() {
        // Load roles from API
        this.loadRoles();
    }

    /**
     * Refresh role list.
     *
     * @returns {void}
     */
    onRoleRefresh() {
        this.setState({
            error: false,
            loading: true,
            roles: [],
        });
        this.loadRoles();
    }

    /**
     * Load all Roles.
     *
     * @returns {void}
     */
    loadRoles() {
        const { sessionId } = this.props;

        fetch(
            `${apiConfig}role/list?sessionId=${sessionId}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
            })
            .then((response) => {
                const { status } = response;

                if(status === 401)
                {
                    httpError401();
                }
                else if (status !== 200) {
                    return this.setState({
                        error: true,
                        loading: false,
                    });
                }

                response
                    .json()
                    .then((body) => {
                        const newState = {};

                        newState.roles = body;
                        newState.error = false;
                        newState.loading = false;
                        return this.setState(newState);
                    });
            });
    }

    /**
     * Render the role list API call children.
     *
     * @returns {React.node|object}
     */
    render() {
        const { children, ...passThroughProps } = this.props;
        const { error, loading, roles } = this.state;

        if (error) {
            return (
                <NetworkAlert
                    label="role list"
                />
            );
        }

        if (loading) {
            return (
                <LoadingIndicator
                    label="Loading role list..."
                />
            );
        }

        return React.Children.map(children, (child) => {
            if (child === null || child === undefined) {
                return null;
            }

            if (typeof child === 'string') {
                return child;
            }

            return React.cloneElement(child, {
                roles,
                ...passThroughProps,
            });
        });
    }
}

export default RoleList;
