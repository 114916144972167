/**
 * TableFooter Component.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import childrenPropType from 'js/PropTypes/children';

/**
 * TableFooter Class
 */
class TableFooter extends Component {
    static propTypes = {
        children: childrenPropType,
    };

    static defaultProps = {
        children: null,
    };

    /**
     * Render the table footer row.
     *
     * @returns {React.node}
     */
    render() {
        const { children } = this.props;

        return (
            <tfoot>
                {children}
            </tfoot>
        );
    }
}

export default TableFooter;
