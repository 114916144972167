/**
 * Confirmation Modal.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'js/Components/Forms/Raw/Button';
import Modal from 'js/Components/Modal/Raw/Modal';
import ModalBody from 'js/Components/Modal/Raw/ModalBody';
import ModalHeader from 'js/Components/Modal/Raw/ModalHeader';
import ModalFooter from 'js/Components/Modal/Raw/ModalFooter';
import noop from 'js/noop';

/**
 * Confirmation Modal Class
 */
class ConfirmModal extends Component {
    static propTypes = {
        confirmLabel: PropTypes.string,
        message: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
        onClose: PropTypes.func,
        onConfirm: PropTypes.func,
        open: PropTypes.bool,
    };

    static defaultProps = {
        confirmLabel: 'Confirm',
        message: 'Are you sure?',
        onClose: noop,
        onConfirm: noop,
        open: false,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {};

        // Efficient early binding.
        this.onConfirm = this.onConfirm.bind(this);
    }

    /**
     * Add close functionality to confirm callback.
     *
     * @return {void}
     */
    onConfirm() {
        const { onClose, onConfirm } = this.props;

        if (onClose) {
            onClose();
        }

        if (onConfirm) {
            onConfirm();
        }
    }

    /**
     * Render the confirmation modal.
     *
     * @returns {React.node}
     */
    render() {
        const { confirmLabel, message, onClose, open } = this.props;

        return (
            <Modal
                onClose={onClose}
                open={open}
            >
                <ModalHeader>
                    <h3>Confirmation</h3>
                </ModalHeader>
                <ModalBody>
                    {message}
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={onClose}
                        title="Cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        primary
                        onClick={this.onConfirm}
                        title={confirmLabel}
                    >
                        {confirmLabel}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ConfirmModal;
