/**
 * Page Header Report Dropdown.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Item from 'js/Components/PageHeader/Navigation/Raw/Item';
import Group from 'js/Components/PageHeader/Navigation/Raw/Group';
import Top5Reports from 'js/Components/PageHeader/Navigation/Top5Reports';
import UserContext from 'js/Context/UserContext';

/**
 * Page Header Report Dropdown class.
 */
class CompanyDropdown extends Component {
    /**
     * Render page header report dropdown.
     *
     * @returns {React.node}
     */
    render() {
         return (
            <Group
                className="report-dropdown"
                label="Reports"
            >
                <Item
                    href="/dashboard"
                    label="View all reports"
                />
                <UserContext.Consumer>
                    { ({ hasPermission, personSession }) => {
                        if (hasPermission('view.reports')) {
                            return (
                                <Top5Reports
                                    session={personSession.session.id}
                                />
                            );
                        }

                        return null;
                    }}
                </UserContext.Consumer>
            </Group>
        );
    }
}

export default CompanyDropdown;
