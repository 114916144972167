/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { PureComponent } from 'react';

import SeverityLegendTable from 'js/Components/Report/SeverityLegendTable';
import metricsShape from 'js/PropTypes/metricsShape';
import writeXlsxFile from 'write-excel-file'
import xlsxDetailsByMetrics from 'js/calculation/xlsxDetailsByMetrics';
import PropTypes from 'prop-types';

class SummaryTable extends PureComponent {
    static propTypes = {
        metrics: metricsShape.isRequired,
        pdf: PropTypes.bool
    };

    constructor(props){
      super(props)
      this.handleXlsx = this.handleXlsx.bind(this)
    }

    headerId(...suffixes) {
        return suffixes.map((suffix) => `summary-th-${suffix}`).join(' ');
    }

    async handleXlsx(){
      const {metrics} = this.props;
      try{
        let xlsxData = xlsxDetailsByMetrics(metrics)
        
        let resultBlob = await writeXlsxFile(xlsxData, {
          filePath: 'details.xlsx'
        })
        const url = URL.createObjectURL(resultBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'details.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }catch(error){
        console.error(error)
      }
    }

    renderTableBody({ slug: principleSlug, name: principleName, guidelines }) {
        const principleCell = (
            <th
                headers={this.headerId('categories', 'principle')}
                id={this.headerId(principleSlug)}
                rowSpan={guidelines.length}
            >
                {principleName}
            </th>
        );
        const rows = guidelines.map(({
            slug: guidelineSlug,
            name,
            severity: guidelineSeverity,
            severityLabel: guidelineSeverityLabel,
            pages,
            applied
        }, index) => {
            const pageCells = pages.map(({ slug, severity }) => (
                <td key={slug} className={`severity-cell severity-${applied ? severity : 'NE'}`} headers={this.headerId('pages', slug, principleSlug)}>
                    {severity > 0 ? (
                        <a href={`#${slug}-${guidelineSlug}`}>{severity}</a>
                    ) : null}
                </td>
            ));

            return (
                <tr key={guidelineSlug}>
                    {index === 0 ? principleCell : null}
                    <td headers={this.headerId('categories', 'guideline', principleSlug)}>{name}</td>
                    <td headers={this.headerId('categories', 'pass-fail', principleSlug)}>{applied ? (guidelineSeverity > 0 ? 'Fail' : 'Pass'): 'NE'}</td>
                    <td headers={this.headerId('categories', 'overall-score', principleSlug)}>{guidelineSeverityLabel}</td>
                    {pageCells}
                </tr>
            );
        });

        return (
            <tbody key={principleSlug}>
                {rows}
            </tbody>
        );
    }

    renderTable() {
        const { metrics: { principles },uid, pdf } = this.props;
        const { pages } = principles[0];

        const pageHeaders = pages.map(({ slug, name }) => (
            <th key={slug} className="page-heading" id={this.headerId(slug)}>{name}</th>
        ));
        const principleBodies = principles.map(
            (principle) => this.renderTableBody(principle),
        );
          console.log(this.props);
        // let table = [];
        // let first = true;
        // let pagesArr = [];
        // principles.map((principle) => {
        //   principle["guidelines"].map((guideline) => {
        //     //headers setup
        //     if (first === true) {
        //       let header = [
        //         "Guideline Type",
        //         "Guideline",
        //         "Pass/Fail",
        //         "Overall Severity Score",
        //         "Total Violations",
        //       ];
        //       pagesArr = guideline.pages.map((page) => {
        //         return page.name;
        //       });
        //       table.push(header.concat(pagesArr));
        //       first = false;
        //     }
        //     //Add Summary Data
        //     let row = [
        //       principle["name"],
        //       guideline["name"],
        //       guideline["severity"] === 0 ? "Pass" : "Fail",
        //       guideline["severityLabel"],
        //       guideline["violations"],
        //     ];
        //     //Add Each Page data
        //     guideline.pages.map((page) => {
        //       row.push(page["violations"]);
        //     });
        //     //Add Table
        //     table.push(row);
        //   });
        // });

        

        return (
          <div
            className="table-responsive"
            style={{ maxWidth: "100vh + 9rem", overflowX: "auto" }}
          >
            <table className="table table-with-severity table-detailed-summary" id='detailed-summary-table'>
              <caption>Figure 5</caption>
              <thead>
                <tr>
                  <th colSpan="4" id={this.headerId("categories")}>
                    <span className="a11y-sr-only">Categories</span>
                  </th>
                  <th colSpan={pages.length} id={this.headerId("pages")}>
                    Web Pages
                  </th>
                </tr>
                <tr>
                  <th id={this.headerId("principle")}>
                    <span className="a11y-sr-only">Principle</span>
                  </th>
                  <th id={this.headerId("guideline")}>Guideline</th>
                  <th id={this.headerId("pass-fail")}>Pass/Fail</th>
                  <th id={this.headerId("overall-score")}>
                    Overall Severity Score
                  </th>
                  {pageHeaders}
                </tr>
              </thead>
              {principleBodies}
            </table>
            {
              pdf ?
              <a href={`${window.location.origin}/xlsx/details/${uid}`} target="_blank">Download XLSX</a>
              :<a onClick={this.handleXlsx}>Download XLSX</a>
            }
          </div>
        );
    }

    render() {
        return (
            <section className="detailed-summary tables">
                <SeverityLegendTable />
                {this.renderTable()}
            </section>
        );
    }
}

export default SummaryTable;
