/**
 * Text Form Group.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
 import classNames from 'classnames';
 import PropTypes from 'prop-types';
 import React, { Component } from 'react';
 
 import FormGroupError from 'js/Components/Forms/Raw/FormGroupError';
 import noop from 'js/noop';
 
 /**
  * Checkbox class.
  */
 class Checkbox extends Component {
     static propTypes = {
         append: PropTypes.node,
         errorMessage: PropTypes.string,
         htmlFor: PropTypes.string,
         icon: PropTypes.node,
         label: PropTypes.string,
         name: PropTypes.string,
         onChange: PropTypes.func,
         noChange:PropTypes.bool
     };
 
     static defaultProps = {
         append: null,
         errorMessage: null,
         htmlFor: null,
         icon: null,
         label: null,
         name: null,
         noChange:false,
         onChange: noop,
     };
 
     /**
      * constructor
      *
      * @param {object} props Properties
      *
      * @returns {void}
      */
     constructor(props) {
         super(props);
         this.state = {
             value: false
         }
 
         // Efficient early binding
         this.onInputChange = this.onInputChange.bind(this);
     }

     componentDidMount(){

         if("value" in this.props){
             this.setState({
                 value: this.props.value
             })
         }
     }
 
     /**
      * Add name to change method propagated to form.
      *
      * @param {event} e Synthetic React Event.
      *
      * @returns {void}
      */
     onInputChange(e) {

         const { name, onChange,noChange } = this.props;
         if(noChange)
         {
             e.preventDefault()
             return false;
         }
         
         this.setState({value:!this.state.value})
         if (onChange) {
             onChange(name, e);
         }
     }
 
     /**
      * Render icon if applicable.
      *
      * @returns {React.node|null}
      */
     renderIcon() {
         const { icon } = this.props;
 
 
         if (typeof icon === 'function') {
             return icon();
         }
 
         if (icon) {
             return icon;
         }
 
         return null;
     }
 
     /**
      * Render label if applicable.
      *
      * @returns {React.node|null}
      */
      renderLabel() {
         const { htmlFor, label } = this.props;
 
         if (label) {
             return (
                 <label style={{whiteSpace:"normal"}} htmlFor={htmlFor}>{label}</label>
             );
         }
 
         return null;
     }
 
     /**
      * Render append node if applicable.
      *
      * @returns {React.node|null}
      */
     renderAppendNode() {
         const { append } = this.props;
 
         if (typeof append === 'function') {
             return append();
         }
 
         if (append) {
             return append;
         }
 
         return null;
     }
 
     /**
      * Render the Text Form Group.
      *
      * @returns {React.node}
      */
      render() {
         const { errorMessage, htmlFor, icon, label, append, name,inputRef, style, isDisable,value, ...passThroughProp } = this.props;
         const groupClasses = classNames({
             'form-group': true,
             'has-icon': icon,
             'no-label': !label,
         });
 
         return (
             <div className={groupClasses} style={{display:"flex", alignItems:"center"}}>
                 {this.renderLabel()}
                <input
                    className="form-control"
                    name={name}
                    title={label}
                    type="checkbox"
                    onChange={this.onInputChange}
                    ref={inputRef}
                    style={style}
                    value={this.state.value}
                    checked={this.state.value}
                />
                 {this.renderIcon()}
                 {this.renderAppendNode()}
                 <FormGroupError
                     errorMessage={errorMessage}
                 />
             </div>
         );
     }
 }
 
 export default Checkbox;
 