/**
 * Define the structure of a person in a reusable way.
 * Users were called person to avoid using what might be a reserved word in some contexts.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

const personPropType = PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.number,
    lastName: PropTypes.string,
    organizationId: PropTypes.number,
});

export default personPropType;
