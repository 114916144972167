/**
 * Dashboard Report Block List.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from "prop-types";
import React, { Component } from "react";

import ReportBlock from "js/Components/Dashboard/ReportBlock";
import reportPropType from "js/PropTypes/report";
import RequestReportBlock from "js/Components/Dashboard/RequestReportBlock";
import { GlobalStoreContext } from "js/Context/UseGlobalStoreContext";

/**
 * Dashboard Report Block class.
 */
class ReportBlockList extends Component {
  static propTypes = {
    isReportAuditor: PropTypes.bool,
    isReportViewer: PropTypes.bool,
  };

  static defaultProps = {
    isReportAuditor: false,
    isReportViewer: false,
  };

  constructor(props){
    super(props)
    this.onReportRefresh = this.onReportRefresh.bind(this);
  }

  filterItems = (arr, query1, query2, archived) => {
    if (archived === "true") {
      const result = arr.filter((report) => report.props.report.is_archived === true);
      return result
    } else if ((query1 === "View All" && query2 === '')) {
      return arr.filter((report) => report.props.report.is_archived !== true);
    } else if ((query1 === "View All" && query2 !== '')) {
      return arr.filter(
        (el) =>
          el.props.report.website
            .toLowerCase()
            .indexOf(query2.toLowerCase()) !== -1 && el.props.report.is_archived !== true
      );
    } else if ((query1 !== "View All" && query2 === '')) {
      return arr.filter(
        (el) =>
          el.props.report.organization.name
            .toLowerCase()
            .indexOf(query1.toLowerCase()) !== -1 &&
          el.props.report.is_archived !== true
      );
    } else {
      return arr.filter(
        (el) =>
          el.props.report.organization.name
            .toLowerCase()
            .indexOf(query1.toLowerCase()) !== -1 &&
          el.props.report.website
            .toLowerCase()
            .indexOf(query2.toLowerCase()) !== -1 &&
          el.props.report.is_archived !== true
      );
    }
  };

  onReportRefresh(){
    const {sessionId} = this.props;
    this.context.loadReportList(sessionId,true);
  }

  /**
   * Render page footer.
   *
   * @returns {React.node}
   */
  render() {
    const {
      isReportAuditor,
      isReportViewer,
      sessionId,
    } = this.props;
   
    const {reportlist,reportLevel} = this.context

    const sortedReportList = reportlist
      .sort((r1, r2) => {
        let r1Date, r2Date;
        if(r1.auditedOn && r2.auditedOn){
          r1Date = new Date(r1.auditedOn)
          r2Date = new Date(r2.auditedOn)
        }
        else if(r1.auditedOn){
          return -1
        }else if(r2.auditedOn){
          return 1
        }else{
          r1Date = new Date(r1.createdAt)
          r2Date = new Date(r2.createdAt)
        }
        return r2Date.getTime() - r1Date.getTime()
      })
      .map((report) => {
        return (
          <ReportBlock
            isReportAuditor={isReportAuditor}
            isReportViewer={isReportViewer}
            key={report.id}
            levels={reportLevel}
            report={report}
            sessionId={sessionId}
            reports={reportlist}
            onReportRefresh={this.onReportRefresh}
          />
        );
      });
    const filteredReportList = this.filterItems(
      sortedReportList,
      this.props.search,
      this.props.urlSearch,
      this.props.archived
    );

    return (
      <section className="report-block-list">
        {filteredReportList}
        {this.props.archived === "true" ? null : <RequestReportBlock levels={reportLevel} onReportRefresh={this.onReportRefresh} />} 
      </section>
    );
  }
}

ReportBlockList.contextType = GlobalStoreContext;

export default ReportBlockList;
