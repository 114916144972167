/**
 * Delete labor button.
 *
 * @author Patrick O'Hern <patrick.ohern@scalesology.com>
 */
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";

import Button from "../Forms/Raw/Button";
import ConfirmModal from "../Modal/Raw/ConfirmModal";
import noop from "../../noop";

/**
 * Delete labor button class.
 */
class DeleteRequestButton extends Component {
  static propTypes = {
    onDeleteRequest: PropTypes.func,
  };

  static defaultProps = {
    onDeleteLabor: noop,
  };

  /**
   * constructor
   *
   * @param {object} props Properties
   *
   * @returns {void}
   */
  constructor(props) {
    super(props);

    this.state = {
      confirmOpen: false,
    };

    // Efficient early binding.
    this.onCloseConfirm = this.onCloseConfirm.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onShowConfirm = this.onShowConfirm.bind(this);
    this.onConfirmArchive = this.onConfirmArchive.bind(this);
  }

  /**
   * Event handler for confirmation modal close.
   *
   * @returns {void}
   */
  onCloseConfirm() {
    this.setState({
      confirmOpen: false,
    });
  }

  /**
   * Event handler for delete after confirmation modal.
   *
   * @returns {void}
   */
  onConfirmDelete() {
    const { onDeleteRequest, report} = this.props;

    console.log(report, 'Report')

    if (onDeleteRequest) {
      onDeleteRequest(report.id);
    }

    this.onCloseConfirm();
  }


  onConfirmArchive() {
    const { onArchiveReport, report} = this.props;

    console.log(report, 'Report')

    if (onArchiveReport) {
      onArchiveReport(report.id);
    }

    this.onCloseConfirm();
  }

  /**
   * Event handler for confirmation modal show.
   *
   * @returns {void}
   */
  onShowConfirm(e) {
    this.setState({
      confirmOpen: true,
    });
  }

  /**
   * Render Delete Request button.
   *
   * @returns {React.node}
   */
  render() {
    const { report } = this.props;
    const { confirmOpen } = this.state;


    return report.isFinalized === 1 ? (
      <Fragment>
        <Button onClick={this.onShowConfirm} className="danger">
          Delete Request
        </Button>
        <ConfirmModal
          message={`Are you sure you want to delete this audit?`}
          open={confirmOpen}
          onClose={this.onCloseConfirm}
          onConfirm={this.onConfirmDelete}
        />
      </Fragment>
      )
    : (
      <Fragment>
        <Button onClick={this.onShowConfirm} className="danger">
          Archive Report
        </Button>
        <ConfirmModal
          message={`Are you sure you want to archive this report?`}
          open={confirmOpen}
          onClose={this.onCloseConfirm}
          onConfirm={this.onConfirmArchive}
        />
      </Fragment>
    );
  }
}

export default DeleteRequestButton;
