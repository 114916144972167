import {useMemo} from "react";
import { FaSave,FaMinusCircle,FaPlusCircle } from "react-icons/fa";

const IconButton = ({type, text, ...props})=>{

    const icon = useMemo(()=>{
        switch(type){
            case "save":
                return <FaSave size={16}/>
            case "delete":
                return <FaMinusCircle size={16} />
            case "add":
                return <FaPlusCircle size={16} />
            default:
                return ""
        }

    },[type])

    const btnColor = useMemo(()=>{
        switch(type){
            case "save":
                return "#4285f4"
            case "delete":
                return "#cf3e43"
            case "add":
                return "#4285f4"
            default:
                return ""
        }

    },[type])

    return(
        <button style={{display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:btnColor,color:"white",border:'none',height:"43px"}} {...props}>
            <span style={{marginRight:"10px"}}>{text}</span>
            {icon}
        </button>
    );
}

export default IconButton