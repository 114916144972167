/**
 * Define the structure of a report principle in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

import guidelinePropType from 'js/PropTypes/guideline';

const principlePropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    guidelines: PropTypes.objectOf(
        guidelinePropType
    )
});

export default principlePropType;
