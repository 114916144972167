/**
 * Define severity colors in a reusable file that can be imported like other files.
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
const severityColors = [
    '#76923c',
    '#508bce',
    '#f8f045',
    '#f89645',
    '#d66b4a',
    '#d55257',
    '#9995a1'
];

export default severityColors;
