/**
 * Importable default person.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
const defaultPersonSession = {
    person: {
        email: 'unauthenticated@example.com',
        firstName: 'Unauthenticated',
        initials: 'UU',
        lastName: 'User',
        organizationId: 0,
    },
    session: {
        id: 'backend-session', // This would be insecure without the backend session ID
        isAuthenticated: false,
        expiration: '2021-03-01 00:00:00',
    }
};

export default defaultPersonSession;
