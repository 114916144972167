/**
 * Define the structure of a permission in a reusable way.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';

const permissionPropType = PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
});

export default permissionPropType;
