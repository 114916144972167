/**
 * An environment config file that can be imported like the other js resources.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */

const domain = window.location.hostname;

function resolveStage() {
    if (
        domain.indexOf('d3b7icfvz6qzdl') !== -1 ||
        domain.indexOf('dev.scalesology-ada-website-audit-portal') !== -1
    ) {
        return 'dev';
    }

    if (domain.indexOf('test.scalesology-ada-website-audit-portal') !== -1) {
        return 'test';
    }

    if (domain.indexOf('scalesology-ada-website-audit-portal') !== -1) {
        return 'dev';
        //return 'prod';
    }

    // Default to local
    return 'local';
}

const stage = resolveStage();

export default stage;
