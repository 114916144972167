/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DetailedDataDownload from 'js/Components/Report/DetailedSummary/DetailedDataDownload';
import DetailedPages from 'js/Components/Report/DetailedSummary/DetailedPages';
import LanguageText from 'js/Components/Utils/LanguageText';
import SectionWithHeading from 'js/Components/Utils/SectionWithHeading';
import SummaryTable from 'js/Components/Report/DetailedSummary/SummaryTable';
import metricsShape from 'js/PropTypes/metricsShape';
import reportShape from 'js/PropTypes/reportShape';
import LegalNote from '../LegalNote';

class DetailedSummary extends PureComponent {
    static propTypes = {
        metrics: metricsShape.isRequired,
        report: reportShape.isRequired,
        pdf: PropTypes.bool
    };

    render() {
        const { metrics, report, pdf } = this.props;

        return (
            <SectionWithHeading className="detailed detailed-summary-container" heading="Detailed Summary by Web Page" level={2}>
                <LanguageText languageKey="detailedSummary" />
                <DetailedDataDownload report={report} />
                <LegalNote/>
                <SummaryTable metrics={metrics} uid={report.uid} pdf={pdf}/>
                <DetailedPages metrics={metrics} report={report} />
            </SectionWithHeading>
        );
    }
}

export default DetailedSummary;
