/**
* Company Info form.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Alert from "js/Components/Alert/Raw/Alert";
import { FaRegCheckCircle } from "react-icons/fa";
import CountrySelect from 'js/Components/Forms/CountrySelect';
import Form from 'js/Components/Forms/Raw/Form';
import organizationPropType from 'js/PropTypes/organization';
import { required } from 'js/validations';
import StateSelect from 'js/Components/Forms/StateSelect';
import TextFormGroup from 'js/Components/Forms/Raw/TextFormGroup';
import noop from 'js/noop';

/**
 * Company Info form.
 */
class CompanyInfoForm extends Component {
  static propTypes = {
    organization: organizationPropType,
    onOrganizationUpdate: PropTypes.func,
  };

  static defaultProps = {
    organization: {},
    onOrganizationUpdate: noop,
  };

  /**
   * constructor
   *
   * @param {object} props Properties
   *
   * @returns {void}
   */
  constructor(props) {
    super(props);

    const defaultCountry =
      props.organization && props.organization.countryCode
        ? props.organization.countryCode
        : "US";

    this.state = {
      countryCode: defaultCountry,
      successMessage: false,
    };
    this.timer = null;
    this.validations = [
      {
        name: "name",
        message: "Please enter your company name. A company name is required.",
        validate: required,
      },
      {
        name: "countryCode",
        message:
          "Please enter country for address. Company address is required.",
        validate: required,
      },
      {
        name: "streetAddress",
        message:
          "Please enter your company address. Company address is required.",
        validate: required,
      },
      {
        name: "city",
        message: "Please enter city for address. Company address is required.",
        validate: required,
      },
      {
        name: "stateCode",
        message: "Please enter state for address. Company address is required.",
        validate: required,
      },
      {
        name: "zipCode",
        message:
          "Please enter a postal code for address. Company address is required.",
        validate: required,
      },
    ];

    // Efficient early binding.
    this.onCountryChange = this.onCountryChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onCloseSuccess = this.onCloseSuccess.bind(this);
  }

  /**
   * Handle country change event.
   *
   * @returns {void}
   */
  onCountryChange(field, countryCode) {
    if (field === "countryCode") {
      this.setState({
        countryCode,
      });
    }
  }

  /**
   * Event handler for success message close.
   *
   * @returns {void}
   */
  onCloseSuccess() {
    this.setState({
      successMessage: false,
    });
  }

  /**
   * Handle event for updating organization information.
   *
   * @param {object} fields
   *
   * @returns {void}
   */
  onFormSubmit(fields) {
    const { organization, onOrganizationUpdate } = this.props;

    //Assumes success.
    this.setState({
      successMessage: true,
    });
    // Auto clear success after 10 seconds.
    this.timer = setTimeout(this.onCloseSuccess, 5000);
    if (onOrganizationUpdate) {
      onOrganizationUpdate(fields);
    }
  }

  renderMessage() {
    const { organization } = this.props;
    const { successMessage } = this.state;

    if (successMessage) {
      return (
        <Alert closeable={false} icon={<FaRegCheckCircle />} success>
          {organization.name} has been saved successfully.
        </Alert>
      );
    }

    return null;
  }

  /**
   * Render the Company Info form.
   *
   * @returns {React.node}
   */
  render() {
    const { organization } = this.props;
    const { countryCode } = this.state;

    return (
      <>
        {/* {this.state.message ? (
          <p>
            <u>{this.state.message}</u>
          </p>
        ) : null} */}
        <Form
          buttonLabel="Update Profile"
          className="company-info-form"
          defaultValues={organization}
          onChildChange={this.onCountryChange}
          onSubmit={this.onFormSubmit}
          validations={this.validations}
        >
          <TextFormGroup
            defaultValue={organization ? organization.name : null}
            htmlFor="name"
            label="Company Name"
            maxLength={255}
            name="name"
          />
          <CountrySelect
            defaultValue={organization ? organization.countryCode : null}
            htmlFor="countryCode"
            label="Country"
            name="countryCode"
          />
          <TextFormGroup
            defaultValue={organization ? organization.streetAddress : null}
            htmlFor="streetAddress"
            label="Address"
            name="streetAddress"
          />
          <TextFormGroup
            defaultValue={organization ? organization.city : null}
            htmlFor="city"
            label="City"
            maxLength={255}
            name="city"
          />
          <StateSelect
            countryCode={countryCode}
            defaultValue={organization ? organization.stateCode : null}
            htmlFor="stateCode"
            label="State/Province"
            name="stateCode"
          />
          <TextFormGroup
            defaultValue={organization ? organization.zipCode : null}
            htmlFor="zipCode"
            label="Zip/Postal Code"
            maxLength={10}
            name="zipCode"
          />
          {this.renderMessage()}
        </Form>
      </>
    );
  }
}

export default CompanyInfoForm;
