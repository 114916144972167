/**
* Report Level select component.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import React, { Component } from 'react';

import reportLevelListPropType from 'js/PropTypes/reportLevel';
import Select from 'js/Components/Forms/Raw/Select';

/**
 * Report Level select component. class.
 */
class ReportLevelSelect extends Component {
    static propTypes = {
        levels: reportLevelListPropType,
    };

    static defaultProps = {
        levels: {},
    };

    /**
     * Render the report level select.
     *
     * @returns {React.node}
     */
    render() {
        const {levels, ...passThroughProps } = this.props;

        return (
            <Select
                name="reportLevel"
                options={levels}
                { ...passThroughProps }
            />
        );
    }
}

export default ReportLevelSelect;
