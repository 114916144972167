import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useCallback } from "react/cjs/react.production.min";
import FormGroupError from "./FormGroupError";

const PhoneTextGroup = ({label,htmlFor,name,errorMessage,onChange=(()=>{}),onPaste,...passThrough})=>{

    const onChangeAdjust = (value,country,e)=>{
        e.currentTarget = e.target;
        onChange(name,e)

    }

    return(
        <div className="form-group">
            <label>{label}</label>
            <div style={{flex:"0 1 314px",width:"314px",margin:"0 0 5px"}}>
                <PhoneInput
                    htmlFor={htmlFor}
                    label={label}
                    name={name}
                    country={"us"}
                    className="form-control"
                    inputProps={{style:{borderColor: "rgb(118, 118, 118)"},name:name,...passThrough}}
                    onChange={onChangeAdjust}
                    value={"15053015464"}
                />
            </div>
            <div >
                <FormGroupError
                    errorMessage={errorMessage}
                />
            </div>
      </div>
    )
}

export default PhoneTextGroup;