import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useContext } from "react";
import { GlobalStoreContext } from "js/Context/UseGlobalStoreContext";

const columns = [
  { field: 'guideline', headerName: 'Guideline', flex: 1},
  { field: 'principle', headerName: 'Principle',  flex: 1},
  { field: 'wcag', headerName: 'WCAG', flex: 1 },
];


const GuidelinesTable = ({selected, handleSelection})=>{
    const {guidelines, reportItem, principles} = useContext(GlobalStoreContext);
    const [list, setList] = useState([]);

    useEffect(()=>{
        const level = reportItem.reportLevel;
        const list = [];
        const principlesIndex = {};
        principles.forEach((item)=>{
            principlesIndex[item.id] = item.name
        });

        guidelines.forEach((item)=>{
            if(item.diagnosticLevel <= level){
                list.push({
                    id: item.id,
                    guideline: item.name,
                    principle: principlesIndex[item.principleId],
                    wcag: item.wcagIds.join(', ')
                });
            }
        });

        setList(list);

    },[reportItem, guidelines, principles])



    return (
        <div style={{ height: 400, width: '100%' }}>
        <DataGrid
            rows={list}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: { page: 0, pageSize: 5 },
            },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableColumnFilter
            disableColumnMenu 
            disableSorting
            onRowSelectionModelChange={handleSelection}
            rowSelectionModel={Object.keys(selected)}
        />
        </div>
    );
}

export default GuidelinesTable