/**
 * Generic Alert Object.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import classNames from 'classnames';
import { IoIosClose } from 'react-icons/io';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'js/Components/Forms/Raw/Button';
import childrenPropType from 'js/PropTypes/children';

/**
 * Alert Class
 */
class Alert extends Component {
    static propTypes = {
        children: childrenPropType,
        className: PropTypes.string,
        closeable: PropTypes.bool,
        icon: PropTypes.node,
    };

    static defaultProps = {
        children: null,
        className: null,
        closeable: true,
        icon: null,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            close: false,
        };

        // Efficient early binding.
        this.onClose = this.onClose.bind(this);
    }

    /**
     * Handle close button.
     *
     * @param {Event} e Synthetic React Event
     *
     * @returns {void}
     */
    onClose(e) {
        e.stopPropagation();

        this.setState({
            close: true,
        });
    }

    /**
     * Render appropriate icon for this alert.
     *
     * @returns {React.node|null}
     */
    renderIcon() {
        const { icon } = this.props;

        if (icon) {
            return icon;
        }

        return null;
    }

    /**
     * Render close button for this alert.
     *
     * @returns {React.node|null}
     */
    renderClose() {
        const { closeable } = this.props;

        if (!closeable) {
            return null;
        }

        return (
            <Button
                className="alert-close"
                title="Close"
                aria-label="Close"
                onClick={this.onClose}
            >
                <IoIosClose />
            </Button>
        );
    }

    /**
     * Render the alert group.
     *
     * @returns {React.node|null}
     */
    render() {
        const { close } = this.state;
        const { children, className } = this.props;
        const alertClasses = classNames({
            alert: true,
            close,
            [className]: className,
        });
        const alertIcon = this.renderIcon();
        const closeIcon = this.renderClose();

        return (
            <div
                aria-hidden={close ? 'true' : null}
                className={alertClasses}
            >
                {alertIcon}
                {closeIcon}
                {children}
            </div>
        );
    }
}

export default Alert;
