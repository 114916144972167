/**
 * Form label.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * Form label.
 */
class Label extends Component {
    static propTypes = {
        htmlFor: PropTypes.string,
        label: PropTypes.string,
    };

    static defaultProps = {
        htmlFor: null,
        label: null,
    };

    /**
    * Render label if applicable.
    *
    * @returns {React.node|null}
    */
    render() {
        const { htmlFor, label } = this.props;

        if (label) {
            return (
                <label htmlFor={htmlFor}>{label}</label>
            );
        }

        return null;
    }
}

export default Label;
