/**
 * Add guideline button.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';
import { FaPlus } from 'react-icons/fa';

import Button from 'js/Components/Forms/Raw/Button';

/**
 * Add guideline button class.
 */
class AddGuidelineButton extends Component {
    /**
     * Render the add guideline button.
     *
     * @returns {React.node}
     */
    render() {

        return (
            <Button {...this.props}>
                Add Guideline Violation <FaPlus />
            </Button>
        );
    }
}

export default AddGuidelineButton;
