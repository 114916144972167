/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import language from 'js/config/language';
import MarkdownText from 'js/Components/Utils/MarkdownText';

class LanguageText extends PureComponent {
    static propTypes = {
        languageKey: PropTypes.string.isRequired,
    };

    render() {
        const { languageKey, ...otherProps } = this.props;

        // eslint-disable-next-line react/jsx-props-no-spreading
        return <MarkdownText text={language[languageKey]} {...otherProps} />;
    }
}

export default LanguageText;
