/**
 * HTML Form.
 * Include support for validation.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IoIosWarning } from 'react-icons/io';
import React, { Component } from 'react';
import serialize from 'form-serialize';
import {httpError401} from "js/Components/Api/useHttp";

import Alert from 'js/Components/Alert/Raw/Alert';
import Button from 'js/Components/Forms/Raw/Button';
import childrenPropType from 'js/PropTypes/children';
import noop from 'js/noop';

/* eslint-disable react/forbid-prop-types */
/**
 * React Form that supports validation.
 */
class Form extends Component {
    static propTypes = {
        afterSubmit: PropTypes.func,
        beforeSubmit: PropTypes.func,
        buttonLabel: PropTypes.string,
        children: childrenPropType,
        className: PropTypes.string,
        defaultValues: PropTypes.object,
        fetchSubmit: PropTypes.bool,
        id: PropTypes.string,
        method: PropTypes.string,
        onChildChange: PropTypes.func,
        onSubmit: PropTypes.func,
        url: PropTypes.string,
        validations: PropTypes.array,
        onFailValidation:PropTypes.func,
    };

    static defaultProps = {
        afterSubmit: noop,
        beforeSubmit: null,
        buttonLabel: 'Submit',
        children: null,
        className: null,
        defaultValues: {},
        fetchSubmit: true,
        id: null,
        method: 'post',
        onChildChange: noop,
        onSubmit: noop,
        url: null,
        validations: [],
        onFailValidation:noop,

    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            loading: false,
            message: false,
            success: false,
            valid: true,
            values: props.defaultValues ? Object.assign({}, props.defaultValues) : {},
        };
        this.validationErrors = [];
        this.form = null;

        // Efficient Early Binding.
        this.doFetchSubmit = this.doFetchSubmit.bind(this);
        this.onChildChange = this.onChildChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * Maintain state of child form inputs for validation.
     *
     * @param {string} name Child input name.
     * @param {Event} e Synthetic react event.
     *
     * @returns {void}
     */
    onChildChange(name, e) {
        e.stopPropagation();

        const { onChildChange } = this.props;
        const { values } = this.state;
        let newValue = e.currentTarget.value;

        if (e.currentTarget && e.currentTarget.type === 'checkbox') {
            newValue = e.currentTarget.checked;
        }

        values[name] = newValue;

        this.setState({
            values,
        });

        if (onChildChange) {
            onChildChange(name, newValue);
        }
    }

    /**
     * Serialize report for submission to server.
     *
     * @param {Event} e Synthetic react event.
     *
     * @returns {void}
     */
    onSubmit(e) {
        const { onSubmit, url, fetchSubmit, organization,onFailValidation } = this.props;
        const { values } = this.state;

        if (this.validate(values) === false) {
            e.preventDefault();
            if(onFailValidation){
                onFailValidation();
            }
            return;
        }

        if (fetchSubmit && url && this.form) {
            e.preventDefault();
            this.doFetchSubmit();
        }

        if (onSubmit) {
            console.log('asd');
            e.preventDefault();
            onSubmit(values, e);
        }

        // Allow default submit by not preventing it
    }

    /**
     * Submit the form via fetch request.
     *
     * @returns {void}
     */
    doFetchSubmit() {
        const { afterSubmit, beforeSubmit, method, url, organization } = this.props;
        let requestBody = serialize(this.form, {
            hash: true,
        });

        if (beforeSubmit) {
            requestBody = beforeSubmit(requestBody);
        }

        this.setState({
            loading: true,
        });

        fetch(
            url,
            {
                body: JSON.stringify(requestBody),
                headers: {
                    'Content-Type': 'application/json'
                },
                method
            })
            .then((response) => {
                const { status } = response;
                if(status === 401)
                {
                    httpError401();
                    return ;
                }

                response
                    .json()
                    .then((body) => {
                        if (afterSubmit) {
                            afterSubmit(body, status, requestBody);
                        }

                        if (status === 400 ) {
                            if (body && body.fields) {
                                this.validationErrors = Object.keys(body.fields).map((field) => {
                                    return {
                                        name: field,
                                        message: body.fields[field],
                                    };
                                });
                            }

                            this.setState({
                                error: true,
                                loading: false,
                                success: false,
                                message: (body ? body.message : 'Error: Bad Request'),
                            });

                            return;
                        }

                        if (body && body.message) {
                            return this.setState({
                                error: false,
                                loading: false,
                                success: true,
                                message: body.message,
                            });
                        }

                        if (body) {
                            this.setState({
                                error: false,
                                loading: false,
                                success: true,
                                message: organization ? `${organization.name} saved succesfully!` : "Success!"
                            });
                        }
                    });
            })
            .catch((error) => {
                this.setState({
                    error: true,
                    loading: false,
                    success: false,
                    message: 'Unexpected server communication error',
                });

                if (console) {
                    console.log(error); // eslint-disable-line no-console
                }
            });
    }

    /**
     * Validate the report before submission.
     *
     * @param {object} form Form Data.
     *
     * @returns {boolean}
     */
    validate(form) {
        const { validations } = this.props;

        this.validationErrors = validations.filter((control) => {
            return control.validate(form, form[control.name], control.name, control.params) === false;
        });

        const isValid = this.validationErrors.length === 0;
        this.setState({
            valid: isValid,
        });

        return isValid;
    }

    /**
     * Render form level error messages and success notifications.
     *
     * @returns {React.node}
     */
    renderFlashMessage() {
        const { error, message } = this.state;

        if (message) {
            return (
                <Alert
                    closeable={false}
                    icon={error ? <IoIosWarning className="alert-icon" size={48} /> : null}
                >
                    {message}
                </Alert>
            );
        }

        return null;
    }

    /**
     * Render submit button.
     *
     * @returns {React.node|null}
     */
    renderButton() {
        const { buttonLabel } = this.props;
        const { loading, success } = this.state;
        let loadingIndicator = null;

        if (buttonLabel === null) {
            return null;
        }

        if (success) {
            return null;
        }

        if (loading) {
            loadingIndicator = (
                <span
                    className="button-loader fa fa-spinner fa-spin"
                    title="loading"
                />
            );
        }

        return (
            <Button
                disabled={loading}
                primary
                title={buttonLabel}
                type="submit"
            >
                {loadingIndicator}
                {buttonLabel}
            </Button>
        );
    }

    /**
     * Render button.
     *
     * @returns {React.node}
     */
    render() {
        const { children, className, id, method, url } = this.props;
        const { valid, success } = this.state;
        const formClasses = classNames({
            error: !valid,
            [className]: className,
        });

        let trackedChildren = null;

        if (!success) {
            trackedChildren = React.Children.map(children, (child) => {
                if (child === null) {
                    return null;
                }

                const validationErrors = this.validationErrors.filter((validationError) => {
                    if (!child) {
                        return false;
                    }

                    return validationError.name === child.props.name;
                });

                let errorMessage = null;

                if (validationErrors.length > 0) {
                    errorMessage = validationErrors[0].message;
                }

                if (child.props.errorMessage) {
                    errorMessage = child.props.errorMessage;
                    console.log(errorMessage, "Error Message");
                }

                const normalChildTypes = ['div', 'p', 'hr', 'label', 'input', 'select', 'textarea'];

                if (normalChildTypes.indexOf(child.type) !== -1) {
                    return React.cloneElement(child);
                }

                return React.cloneElement(child, {
                    errorMessage,
                    onChange: this.onChildChange,
                    onCut: this.onChildChange,
                    onPaste: this.onChildChange,
                });
            });
        }

        return (
            <form
                action={url}
                className={formClasses}
                id={id}
                method={method}
                onSubmit={this.onSubmit}
                ref={(ref) => { this.form = ref; }}
                autoComplete="off"
            >
                {this.renderFlashMessage()}
                {trackedChildren}
                {this.renderButton()}
            </form>
        );
    }
}

export default Form;
