/**
* Add Organization form.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import CountrySelect from 'js/Components/Forms/CountrySelect';
import Form from 'js/Components/Forms/Raw/Form';
import noop from 'js/noop';
import organizationPropType from 'js/PropTypes/organization';
import { required } from 'js/validations';
import StateSelect from 'js/Components/Forms/StateSelect';
import TextFormGroup from 'js/Components/Forms/Raw/TextFormGroup';
import Select from 'js/Components/Forms/Raw/Select';

/**
 * Add Organization form.
 */
class AddOrganizationForm extends Component {
    static propTypes = {
        action: PropTypes.oneOf(['add', 'edit']),
        onOrganizationSaved: PropTypes.func,
        organization: organizationPropType,
    };

    static defaultProps = {
        action: 'add',
        onOrganizationSaved: noop,
        organization: null,
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
     constructor(props) {
        super(props);

        const defaultCountry =  props.organization && props.organization.countryCode ? props.organization.countryCode : 'US';

        this.state = {
            countryCode: defaultCountry,
            showOrganization:false
        };
        this.validations = [
            {
                name: 'name',
                message: 'Please enter a name. A a name is required.',
                validate: required,
            },
            {
                name: 'streetAddress',
                message: 'Please enter an address. Address is required.',
                validate: required,
            },
            {
                name: 'city',
                message: 'Please enter a city. City is required.',
                validate: required,
            },
            {
                name: 'zipCode',
                message: 'Please enter a zip/postal code. It is required.',
                validate: required,
            },
        ];

        // Efficient early binding.
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onOrganizationSaved = this.onOrganizationSaved.bind(this);
    }

    /**
     * Handle country change event.
     *
     * @returns {void}
     */
    onCountryChange(field, countryCode) {
        if (field === 'countryCode') {
            this.setState({
                countryCode,
            });
        }else if(field === "type")
        {
            if(countryCode==="client"){
                this.setState({
                    showOrganization:true
                })
            }else{
                this.setState({
                    showOrganization:false
                })
            }
        }

    }

    /**
     * Handle event after save.
     *
     * @returns {void}
     */
    onOrganizationSaved(response, status, requestBody) {
        const { onOrganizationSaved,onFailValidation } = this.props;

        if (status !== 400 && onOrganizationSaved) {
            onOrganizationSaved(response, requestBody);
        }else{
          onFailValidation()
        }
    }

    getOrganizations(){
        const list = {};
        this.props.organizations.map((item)=>{
            list[item['id']] = item['name']
        })
        return list;
    }

    /**
     * Render the Add Organization form.
     *
     * @returns {React.node}
     */
    render() {
        const { action, organization,hasPermission,personSession,hasRole,onFailValidation} = this.props;
        const { countryCode } = this.state;
        //console.log(this.props);
        return (
          <Form
            afterSubmit={this.onOrganizationSaved}
            buttonLabel={null}
            className="add-edit-organization-form"
            defaultValues={organization}
            id="add-edit-organization-form"
            method={action === "add" ? "POST" : "PATCH"}
            onChildChange={this.onCountryChange}
            url={`${apiConfig}organization${
              organization && organization.id ? `/${organization.id}` : ""
            }?sessionId=${personSession.session.id}`}
            validations={this.validations}
            onFailValidation={onFailValidation}
          >
            <TextFormGroup
              defaultValue={organization ? organization.name : null}
              htmlFor="name"
              label="Name"
              name="name"
              data-lpignore="true"
            />
            <TextFormGroup
              defaultValue={organization ? organization.streetAddress : null}
              htmlFor="streetAddress"
              label="Address"
              name="streetAddress"
              data-lpignore="true"
            />
            <TextFormGroup
              defaultValue={organization ? organization.city : null}
              htmlFor="city"
              label="City"
              name="city"
              data-lpignore="true"
            />
            {this.props.hasRole("global.admin") && (
              <Select
                name="type"
                options={{ partner: "Partner", client: "Client" }}
                htmlFor="type"
                label="Type"
              />
            )}
            {this.props.hasRole("global.admin") &&
              this.state.showOrganization && (
                <Select
                  name="parentId"
                  options={this.getOrganizations()}
                  htmlFor="Parent Organization"
                  label="Parent Organization"
                />
              )}
            <StateSelect
              countryCode={countryCode}
              defaultValue={organization ? organization.stateCode : null}
              htmlFor="stateCode"
              label="State/Province"
              name="stateCode"
              data-lpignore="true"
            />
            <TextFormGroup
              defaultValue={organization ? organization.zipCode : null}
              htmlFor="zipCode"
              label="Zip/Postal Code"
              name="zipCode"
              data-lpignore="true"
            />
            <CountrySelect
              defaultValue={countryCode}
              htmlFor="countryCode"
              label="Country"
              name="countryCode"
              data-lpignore="true"
            />
          </Form>
        );
    }
}

export default AddOrganizationForm;
