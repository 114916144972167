/**
* Security Settings form.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import React, { Component } from 'react';
import { FaRegCheckCircle } from 'react-icons/fa'
import {FaExclamationCircle} from 'react-icons/fa'
import TextFormGroup from 'js/Components/Forms/Raw/TextFormGroup';
import Form from 'js/Components/Forms/Raw/Form';
import { required,minLength } from 'js/validations';
import Alert from 'js/Components/Alert/Raw/Alert';
import { withRouter } from "react-router";

/**
 * Security Settings form.
 */
class SecuritySettingsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            successMessage: false,
            error:false,
            click:false
        };
        this.validations = [
            {
                name: 'oldPassword',
                message: 'Please enter your old password.',
                validate: required,
            },
            {
                name: 'password',
                message: 'Please enter your new password.',
                validate: required,
            },
            {
                name: 'password',
                message: 'Please enter at least 8 characters.',
                validate: minLength,
                params: {
                    min: 8,
                },
            },
            {
                name: 'password',
                message: 'Please enter a differnet password. New password is same to old one.',
                validate: (values) => {
                    return values.oldPassword !== values.password;
                }
            },
            {
                name: 'confirm',
                message: 'Please confirm your new password.',
                validate: required,
            },
            {
                name: 'confirm',
                message: 'Please enter at least 8 characters.',
                validate: minLength,
                params: {
                    min: 8,
                },
            },
            {
                name: 'confirm',
                message: 'Please verify that you entered the same password twice. Confirm password must match your new password.',
                validate: (values) => {
                    return values.confirm === values.password;
                }
            }
            
        ];
        this.timer = null;
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onCloseSuccess = this.onCloseSuccess.bind(this);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    /**
     * Event handler for success message close.
     *
     * @returns {void}
     */
    onCloseSuccess() {
        this.setState({
            successMessage: false,
            error:false
        });
    }

    onFormSubmit(fields) {
        if(this.state.click){
            return;
        }
        this.setState({click:true})
        const { onProfileUpdate } = this.props;
        if (this.timer) {
            clearTimeout(this.timer);
            this.setState({
                successMessage: false,
                error:false
            });
        }

        if (onProfileUpdate) {
            onProfileUpdate(fields,(res)=>{
                this.setState({click:false})
                if(`${res.status}` === '200')
                {
                    // Assumes success.
                    this.setState({
                        successMessage: "Your profile has been saved successfully.",
                        error:false
                    });
             
                    if(this.props.location.search !== "")
                    {
                        this.props.unlockForceChange(false);
                        const location = this.props.location.search.replace(/^\?redirect\=/i,"")
                        if(location)
                        {
                            this.props.history.push(location);
                        }
                    }
                }else{
                    this.setState({
                        successMessage: "body" in res && "message" in res.body? res.body.message:"Unexpected server communication error",
                        error:true
                    });
                }

                // Auto clear success after 10 seconds.
                this.timer = setTimeout(this.onCloseSuccess, 10000);
    
            });
        }
    }
    
    /**
     * Render the profile save success message.
     *
     * @returns {React.node|null}
     */
    renderMessage() {
        const { successMessage,error } = this.state;

        if (successMessage) {
            return (
                <Alert
                    closeable={false}
                    icon={error?<FaExclamationCircle/>:<FaRegCheckCircle />}
                    success
                >
                    {successMessage}
                </Alert>
            );
        }

        return null;
    }

    /**
     * Render the Security Settings form.
     *
     * @returns {React.node}
     */
    render() {

        return (
            <Form
            buttonLabel={"Update Password"}
            className="user-secure-form"
            onSubmit={this.onFormSubmit}
            validations={this.validations}
        >
                <TextFormGroup
                    htmlFor="oldPassword"
                    label="Old Password"
                    name="oldPassword"
                    type="password"
                />
                <TextFormGroup
                    htmlFor="new"
                    label="New Password"
                    name="password"
                    type="password"
                />
                <TextFormGroup
                    htmlFor="confirm"
                    label="Confirm Password"
                    name="confirm"
                    type="password"
                />
                {this.renderMessage()}
            </Form>
        );
    }
}

export default withRouter(SecuritySettingsForm);
