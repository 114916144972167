/**
 * Define severity levels in a reusable file that can be imported like other files.
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
const severityLevels = [
    'None',
    'Low',
    'Low-Med',
    'Med',
    'Med-High',
    'High',
    'NE'
];

export default severityLevels;
