/**
* Person Item API Call.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import apiConfig from 'js/config/api';
import childrenPropType from 'js/PropTypes/children';
import LoadingIndicator from 'js/Components/LoadingIndicator';
import NetworkAlert from 'js/Components/Alert/NetworkAlert';
import {httpError401} from "js/Components/Api/useHttp";

/**
 * Person Item API Calling Class
 */
class PersonItem extends Component {
    static propTypes = {
        children: childrenPropType,
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        sessionId: PropTypes.string,
        isLoad:  PropTypes.bool,
    };

    static defaultProps = {
        children: null,
        id: null,
        sessionId: null,
        isLoad:true
    };

    /**
     * constructor
     *
     * @param {object} props Properties
     *
     * @returns {void}
     */
    constructor(props) {
        super(props);



        // Load persons from API
        if(this.props.isLoad)
        {
            this.state = {
                error: false,
                loading: true,
                person: {},
            };
            this.loadPerson(props.id);
        }else{
            this.state = {
                error: false,
                loading: false,
                person: {},
            };
        }


        // Efficient early binding
        this.onPersonUpdate = this.onPersonUpdate.bind(this);
        this.onProfileUpdate = this.onProfileUpdate.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
    }

    /**
     * If person changes, lookup next person.
     *
     * @param nextProps
     */
    componentWillReceiveProps(nextProps) {
        const { id,isLoad } = this.props;

        if (id !== nextProps.id && isLoad) {
            this.loadPerson(nextProps.id);
        }
    }

    /**
     * Load all Persons.
     *
     * @returns {void}
     */
    loadPerson(id) {
        const { sessionId } = this.props;

        if (id === null) { // no-op
            return;
        }

        fetch(
            `${apiConfig}profile?sessionId=${sessionId}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
            })
            .then((response) => {
                const { status } = response;

                if(status === 401)
                {
                    httpError401();
                }
                else if (status !== 200) {
                    return this.setState({
                        error: true,
                        loading: false,
                    });
                }

                response
                    .json()
                    .then((body) => {
                        const newState = {};

                        newState.person = body;
                        newState.error = false;
                        newState.loading = false;
                        return this.setState(newState);
                    });
            });
    }

    /**
     * Handle person update.
     *
     * @param {object} newPerson Updated person fields.
     */
    onUpdate(url, newPerson,callback) {
        delete newPerson.createdAt;
        delete newPerson.updatedAt;

        fetch(
            url,
            {
                body: JSON.stringify(newPerson),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'PATCH',
            })
            .then((response) => {
                const { status } = response;

                if(status === 401)
                {
                    httpError401();
                }
                else if (status !== 200) {
                    if(!callback)
                    {
                        return this.setState({
                            error: true,
                            loading: false,
                        });
                    }
                }

                response
                    .json()
                    .then((res) => {
                        // Note, the body returned here is an empty object (stringified)
                        const newState = {};
                        newState.person = newPerson;
                        newState.error = false;
                        newState.loading = false;
                        if(callback){
                            callback({
                                status,
                                body:res
                            })
                        }
                        return this.setState(newState);
                    });


            });
    }

    /**
     * Handle person update.
     *
     * @param {object} newPerson Updated person fields.
     */
    onPersonUpdate(newPerson) {
        const { sessionId } = this.props;

        this.onUpdate(`${apiConfig}person/${newPerson.id}?sessionId=${sessionId}`, newPerson);
    }

    /**
     * Handle person update.
     *
     * @param {object} newPerson Updated person fields.
     */
    onProfileUpdate(newPerson,callback) {
        const { sessionId } = this.props;

        this.onUpdate(`${apiConfig}profile?sessionId=${sessionId}`, newPerson,callback);
    }

    /**
     * Render the person item API call children.
     *
     * @returns {React.node|object}
     */
    render() {
        const { children, ...passThroughProps } = this.props;
        const { error, loading, person } = this.state;

        if (error) {
            return (
                <NetworkAlert
                    label="person"
                />
            );
        }

        if (loading) {
            return (
                <LoadingIndicator
                    label="Loading user info..."
                />
            );
        }

        return React.Children.map(children, (child) => {
            if (child === null || child === undefined) {
                return null;
            }

            if (typeof child === 'string') {
                return child;
            }

            return React.cloneElement(child, {
                onPersonUpdate: this.onPersonUpdate,
                onProfileUpdate: this.onProfileUpdate,
                person,
                ...passThroughProps,
            });
        });
    }
}

export default PersonItem;
