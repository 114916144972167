/**
 * Unauthenticated Route.
 *
 * A route that checks if a user is not authenticated
 * and redirects to specific url after login.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React from 'react';
import { Route, Redirect } from 'react-router';

import UserContext from 'js/Context/UserContext';

function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const UnauthenticatedRoute = ({ component: C, props: cProps, ...passThroughProps }) => {
    const redirect = querystring('redirect');

    return (
        <Route
            {...passThroughProps}
            render={props => {
                return (
                    <UserContext.Consumer>
                        {({personSession}) => (
                            !personSession || !personSession.session || !personSession.session.isAuthenticated
                            ? <C {...props} {...cProps} />
                            : <Redirect
                                to={{
                                    pathname: redirect === '' || redirect === null ? '/dashboard' : redirect,
                                    search: '',
                                }}
                            />
                        )}
                    </UserContext.Consumer>
                );
            }}
        />
    );
};

export default UnauthenticatedRoute;