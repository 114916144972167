/**
 * HTML button.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import childrenPropType from 'js/PropTypes/children';

/**
 * HTML button
 */
class Button extends Component {
    static propTypes = {
        children: childrenPropType,
        className: PropTypes.string,
        link: PropTypes.bool,
        primary: PropTypes.bool,
    };

    static defaultProps = {
        children: null,
        className: null,
        link: false,
        primary: false,
    };

    /**
     * Render the button.
     *
     * @returns {React.node}
     */
    render() {
        const { children, className, link, primary, title, ...passThroughProps} = this.props;
        const buttonClasses = classNames({
            'button-link': link,
            [className]: className,
            primary,
        });
        const validHtmlButtonProps = [
            'aria-label', 'autoFocus', 'disabled', 'form', 'formAction', 'formEncType',
            'formMethod', 'formNoValidate', 'formTarget', 'name', 'onClick',
            'onKeyPress', 'tabIndex', 'title', 'type', 'value',
         ];

        // Delete properties not in the whitelist
        Object.keys(passThroughProps)
            .filter(key => !validHtmlButtonProps.includes(key))
            .forEach(key => delete passThroughProps[key]);

        return (
            <button type="button" className={buttonClasses} {...passThroughProps}>
                { children}
            </button>
        );
    }
}

export default Button;
