/**
 * File migrated from earlier version of the project in a previous repository.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import AppendixA from 'js/Components/Report/Appendices/AppendixA';
import calculateMetricsFromReport from 'js/calculation/calculateMetricsFromReport';
import DetailedSummary from 'js/Components/Report/DetailedSummary/DetailedSummary';
import guidelinePropType from 'js/PropTypes/guideline';
import Header from 'js/Components/Report/Header';
import MainInfo from 'js/Components/Report/MainInfo';
import PagesOverview from 'js/Components/Report/PagesOverview/PagesOverview';
import principlePropType from 'js/PropTypes/principle';
import reportLevelListPropType from 'js/PropTypes/reportLevelList';
import reportShape from 'js/PropTypes/reportShape';
import WebsiteOverview from 'js/Components/Report/WebsiteOverview/WebsiteOverview';

class Container extends PureComponent {
    static propTypes = {
        guidelines: PropTypes.arrayOf(guidelinePropType),
        levels: reportLevelListPropType,
        principles: PropTypes.arrayOf(principlePropType),
        report: reportShape.isRequired,
        pdf: PropTypes.bool
    };

    static defaultProps = {
        guidelines: [],
        principles: [],
        levels: [],
        pdf: false
    };

    render() {
        const { guidelines, levels, principles, report, pdf } = this.props;
        const { auditedOn, reportLevel, website,isFinalized } = report;
        const metrics = calculateMetricsFromReport(principles, guidelines, report);
        const { totalGuidelines } = metrics;

        return (
            <main className="container main" id="main">
                <div id="top"></div>
                <Header
                    levels={levels}
                    reportLevel={reportLevel}
                />
                <MainInfo
                    auditedOn={auditedOn}
                    guidelines={totalGuidelines}
                    website={website}
                    isFinalized={isFinalized}
                    report={report}
                />
                <WebsiteOverview
                    metrics={metrics}
                    report={report}
                />
                <PagesOverview
                    metrics={metrics}
                    report={report}
                    pdf={pdf}
                />
                <DetailedSummary
                    metrics={metrics}
                    report={report}
                    pdf={pdf}
                />
                <AppendixA
                    report={report}
                />
            </main>
        );
    }
}

export default Container;
