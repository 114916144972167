/**
* Single Page React Router.
*
* @author Ryan Johnston <ryan.johnston@scalesology.com>
*/
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import AccountSettings from 'js/Components/Pages/AccountSettings';
import AuthenticatedRoute from 'js/Components/Routes/AuthenticatedRoute';
import CompanySettings from 'js/Components/Pages/CompanySettings';
import Dashboard from 'js/Components/Pages/Dashboard';
import EditReport from 'js/Components/Pages/EditReport';
import ManageOrganizations from 'js/Components/Pages/ManageOrganizations';
import ManageUsers from 'js/Components/Pages/ManageUsers';
import NotFound from 'js/Components/Pages/NotFound';
import PdfReport from 'js/Components/Pages/PdfReport';
import XlsxReport from 'js/Components/Pages/XlsxReport';
import PdfDownloader from '../Pages/PdfDownloader';
import Report from 'js/Components/Pages/Report';
import ResetPassword from 'js/Components/Pages/ResetPassword';
import ResetPasswordToken from 'js/Components/Pages/ResetPasswordToken';
import SignIn from 'js/Components/Pages/SignIn';
import SignUp from 'js/Components/Pages/SignUp';
import UnauthenticatedRoute from 'js/Components/Routes/UnauthenticatedRoute';
import BothauthenticatedRoute from "./BothauthenticatedRoute";

/**
 * Single page application React router.
 *
 * @type {React.node}
 */
class RouteSwitch extends Component {
    /**
     * Render app pane.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <Switch>
                <AuthenticatedRoute path="/dashboard" component={Dashboard} />
                <AuthenticatedRoute path="/report/:id" component={Report} />
                <AuthenticatedRoute path="/edit-report/:id" component={EditReport} />
                <UnauthenticatedRoute path="/sign-in" component={SignIn} />
                {/* <UnauthenticatedRoute path="/sign-up" component={SignUp} /> */}
                <AuthenticatedRoute path="/account-settings" component={AccountSettings} />
                <AuthenticatedRoute path="/company-settings" component={CompanySettings} />
                <AuthenticatedRoute path="/manage-organizations" component={ManageOrganizations} />
                <AuthenticatedRoute path="/manage-users" component={ManageUsers} />
                <UnauthenticatedRoute path="/password-reset" exact component={ResetPassword} />
                <UnauthenticatedRoute path="/password-reset/:token" exact component={ResetPasswordToken} />
                <UnauthenticatedRoute path="/pdf-report/:id" component={PdfReport} />
                <BothauthenticatedRoute path="/xlsx/:type/:id" component={XlsxReport} />
                <AuthenticatedRoute path="/preview/:id" component={PdfReport} />
                {/* <BothauthenticatedRoute path="/download-pdf/:id/:token" exact component={PdfDownloader} /> */}
                <Redirect exact from="/" to="/dashboard" />
                <Route key="404" component={NotFound} />
            </Switch>
        );
    }
}

export default RouteSwitch;
