/**
 * Authenticated Route.
 *
 * A route that checks if a user is authenticated
 * and redirects to login in not authenticated.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React from 'react';
import { Route, Redirect } from 'react-router';

import UserContext from 'js/Context/UserContext';

/**
 * Authenticated Route Component.
 */
const AuthenticatedRoute = ({ component: C, props: cProps, ...passThroughProps }) =>{
   
return(
    <Route
        {...passThroughProps}
        render={props => {
            return (
                <UserContext.Consumer>
                    {({personSession}) => (
                        personSession && personSession.session && personSession.session.isAuthenticated
                        ? (personSession.person.forceChange && passThroughProps.path !== "/account-settings" ?
                            <Redirect
                                to={{
                                    pathname: '/account-settings',
                                    search:`?redirect=dashboard`
                                    //search:`?redirect=${props.location.pathname}`
                                }}
                            />: <C personSession={personSession} {...props} {...cProps} /> ) 
                        : <Redirect
                            to={{
                                pathname: '/sign-in',
                                search:`?redirect=dashboard`
                                //search: `?redirect= ${props.location.pathname}`
                            }}
                        />
                    )}
                </UserContext.Consumer>
            );
        }}
    />);
}

export default AuthenticatedRoute;
