/**
 * Markdown Text Utility.
 *
 * @author Jake Zatecky <jake.zatecky@scalesology.com>
 */
import marked from 'marked';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import RawHtml from 'js/Components/Utils/RawHtml';

class MarkdownText extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        text: PropTypes.string.isRequired,
    };

    static defaultProps = {
        className: 'markdown-wrapper',
    };

    /**
     * Render Markdown Text utility.
     *
     * @returns {React.node}
     */
    render() {
        const { className, text } = this.props;
        // if(text === null){
        //     return <div>hehe</div>
        // }
        return <RawHtml className={className} html={marked(text)} />;
    }
}

export default MarkdownText;
