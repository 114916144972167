/**
 * Front end Manage Users page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Breadcrumb from 'js/Components/Breadcrumb/Breadcrumb';
import PagePermissionAlert from 'js/Components/Alert/PagePermissionAlert';
import PageWrapper from 'js/Components/Pages/PageWrapper';
import UserContext from 'js/Context/UserContext';
import UserManageBlock from 'js/Components/Blocks/UserManageBlock';

/**
 * Front end Manage Users page.
 */
class ManageUsers extends Component {
    /**
     * Render Manage Users page.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <PageWrapper
                description="Manage Users"
                title="Manage Users"
            >
                <Breadcrumb
                    label="Manage Users"
                    description="Add, edit, or delete users."
                />
                    <UserContext.Consumer>
                        { ({ hasPermission }) => {
                            if (!hasPermission('manage.people')) {
                                return (
                                    <PagePermissionAlert />
                                );
                            }

                            return (
                                <UserManageBlock />
                            );
                        }}
                    </UserContext.Consumer>
            </PageWrapper>
        );
    }
}

export default ManageUsers;
