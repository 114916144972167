/**
 * Front end Dashboard page.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

import Breadcrumb from 'js/Components/Breadcrumb/Breadcrumb';
import CollapseBox from '../EditReport/Raw/CollapseBox';
import PageWrapper from 'js/Components/Pages/PageWrapper';
import ReportBlockList from 'js/Components/Dashboard/ReportBlockList';
import UserContext from 'js/Context/UserContext';
import DropDownFilter from '../Dropdown/DropwDownFilter'
import URLSearch from '../Forms/ReportURLSearch';
import List from '../Api/List';

/**
 * Front end Dashboard page.
 */
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
        search: "View All",
        urlSearch: ""
    };

    // Efficient early binding.
    this.search = this.search.bind(this);
    this.searchURL = this.searchURL.bind(this);
  }

  search(term){
    this.setState({search: `${term}`})
  }

  searchURL(term){
    this.setState({urlSearch: `${term}`})
  }
  /**
   * Render Dashboard page.
   *
   * @returns {React.node}
   */
  render() {
    const { personSession } = this.props;
    
    return (
      <PageWrapper
        description="Dashboard"
        title="Dashboard"
        personSession={personSession}
      >
        <div id='top'></div>
        <Breadcrumb
          label="My Websites"
          description="Select a website to view its report or request a new audit"
        />

        <UserContext.Consumer>
          {({ personSession, hasPermission }) => (
            <List page={"dashboard"} sessionId={personSession.session.id}>
              <div className="report-block-wrapper" style={{maxWidth:"960px"}}>
                <a href="#archived"> Archived Reports</a>
              </div>
              <URLSearch
                searchURL={this.searchURL}
                urlSearch={this.state.urlSearch}
              />
              <DropDownFilter search={this.search} />
              <ReportBlockList
                isReportAuditor={hasPermission("audit.reports")}
                isReportViewer={hasPermission("view.reports")}
                search={this.state.search}
                urlSearch={this.state.urlSearch}
                sessionId={personSession.session.id}
              />
              <CollapseBox
                label="Archived Reports"
                className="archived-report-block-wrapper"
                defaultCollapsed
                id="archived"
              >
                <ReportBlockList
                  isReportAuditor={hasPermission("audit.reports")}
                  isReportViewer={hasPermission("view.reports")}
                  search={this.state.search}
                  urlSearch={this.state.urlSearch}
                  archived="true"
                  sessionId={personSession.session.id}
                />
              </CollapseBox>
              <div className="report-block-wrapper" style={{maxWidth:"960px"}}>
                <a href="#top">
                  Back To Top
                </a>
              </div>
            </List>
          )}
        </UserContext.Consumer>
      </PageWrapper>
    );
  }
}

export default Dashboard;
