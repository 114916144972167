/**
 * Page Header Brand section.
 *
 * @author Ryan Johnston <ryan.johnston@scalesology.com>
 */
import React, { Component } from 'react';

/**
 * Page Header Brand class.
 */
class Brand extends Component {
    /**
     * Render page header.
     *
     * @returns {React.node}
     */
    render() {
        return (
            <a
                className="brand"
                href="/dashboard"
                title="Dashboard"
            >
                <img src="/img/scalesology-ada-portal.svg" alt="Scalesology" />
                <span className="a11y-sr-only">Scalesology</span>
            </a>
        );
    }
}

export default Brand;
