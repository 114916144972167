import {useState,useContext,useCallback,useMemo,useRef} from "react";
import Button from 'js/Components/Forms/Raw/Button';
import Modal from 'js/Components/Modal/Raw/Modal';
import ModalBody from 'js/Components/Modal/Raw/ModalBody';
import ModalFooter from 'js/Components/Modal/Raw/ModalFooter';
import ModalHeader from 'js/Components/Modal/Raw/ModalHeader';
import IconButton from '../EditReport/Raw/IconButton';
import TextFormGroup from "../Forms/Raw/TextFormGroup";
import { GlobalStoreContext } from 'js/Context/UseGlobalStoreContext';
import UserContext from "js/Context/UserContext";


let timeoutId = null
const RunAuditToolModal = ({onClose,open,id,setLoading,disableLoading})=>{
    const [list, setList] = useState({})
    const [url, setURL] = useState(null)
    const [error, setError] = useState(false)
    const inputRef = useRef();
    const {auditUrls,reportItem} = useContext(GlobalStoreContext)
    const {personSession} = useContext(UserContext);

    const [startAudit, setStartAudit] =  useState(false);


    const validation = useCallback((url)=>{
        const urlReg = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
        if(url === "" || url=== undefined || url === null)
        {   
            return "Please enter an url!"
        }
        else if(!urlReg.test(url)){
            return "Please enter valid url, which starts with http/https!"
        }
        return true;
    },[])

    const onAdditem = useCallback(()=>{
        if(timeoutId !== null)
        {
            clearTimeout(timeoutId)
        }
        setError(false)
        const valid = validation(url)
        if(valid !== true)
        {
            setError(valid)
            timeoutId = setTimeout(()=>{

               setError(false)
            },5000)
        }else{
            const urlOb = new URL(url);
            const path = urlOb.pathname
            if(list.hasOwnProperty(url))
            {
                setError("This path has been added!")
                timeoutId = setTimeout(()=>{
                    setError(false)
                },5000)
            }
            else{
                setList({
                    ...list,
                    [url]: path.replace(/^\//,"",path)
                })
            }
            inputRef.current.value=""

        }

       
    },[list,url,inputRef,personSession])

    const onDeleteitem = useCallback((url)=>{
        if(list.hasOwnProperty(url)){
            delete list[url]
            setList({
                ...list
            })
        }
    },[list])

    const onChangeUrl = useCallback((name,e)=>{
        setURL(e.target.value)
    },[])

    const onAudit = useCallback(()=>{
        if(Object.keys(list).length >  0)
        {
            setLoading()
            auditUrls(reportItem,personSession.session.id,id,Object.values(list),(isSuccess,data)=>{
                disableLoading();
                //onClose()
                setStartAudit(true);
            })
        }

    },[list,auditUrls,reportItem,personSession,id])


    const listContainer = useMemo(()=>{
        if(Object.keys(list).length === 0)
        {
            return (<div style={{marginTop:"10px"}}>No URLs</div>)
        }
        let items = [];

        Object.keys(list).map((item,index)=>{
 
            items.push(
                <div key={item} style={{display:"flex",justifyContent:"space-between",padding:"8px 0",alignItems:"center"}}>
                    <span style={{paddingRight:"8px", wordBreak:"break-all"}}>{item}</span>
                    <IconButton 
                        type="delete"
                        text="Delete"
                        onClick={()=>{onDeleteitem(item)}}
                    />
                </div>
            )
        })
        return(<div style={{maxHeight:"430px",overflowY:"auto",paddingRight:"10px"}}>
            {items}
        </div>)
    },[list])

    return(            
        <Modal
            className="request-urls-audit-modal"
            onClose={onClose}
            open={open}
        >
            <ModalHeader>
                Request URLs for audit
            </ModalHeader>
            <ModalBody>
                {
                    startAudit===true?
                <div>
                    Automatic audit gets starting. You will receive a notification email after audit is complete!
                </div>
                :<div>
                    <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                        <TextFormGroup
                            htmlFor="URL"
                            label="URL"
                            name="url"
                            onChange={onChangeUrl}
                            inputRef={inputRef}
                        />
                        <IconButton
                            type="add"
                            text="Add"
                            onClick={onAdditem}
                        />
                    </div>
                    <div>
                        {error !== false&&<span style={{color:"red",fontSize:"14px"}}>{error}</span> }
                    </div>
                    <div style={{marginTop:"20px"}}>
                        <div>
                            <strong>Added URLs:</strong>
                        </div>
                        {listContainer}
                    </div>
                </div>
                }
    
            </ModalBody>
            <ModalFooter>
                
                <Button
                    onClick={onClose}
                    title="Cancel"
                >
                {startAudit!==true?"Cancel":"Okay"}
                </Button>
                {
                startAudit!==true &&
                <Button
                    primary
                    title="Audit URLs"
                    onClick={onAudit}
                >
                    Audit
                </Button>
                }   
            </ModalFooter>
        </Modal>)
}
export default RunAuditToolModal;